import Ajax from "./request";

const Apis = {
  // 授权登录
  login: {
    /**
      * 授权令牌登录
      * @param {*} params
      * @returns
      * @see http://192.168.0.126:9006/doc.html#/default/%E7%99%BB%E5%BD%95%E6%9C%8D%E5%8A%A1/loginTokenUsingGET
      */
    loginToken(params) {
      return Ajax.ajaxs("/user/loginToken", params, "GET");
    }
  },
  // 红包管理
  redEnvelopes: {
    /**
      * 创建红包接口
      * @param {*} params
      * @returns
      * @see http://192.168.0.126:9006/doc.html#/default/%E7%BA%A2%E5%8C%85%E7%AE%A1%E7%90%86%E6%8E%A5%E5%8F%A3/addRedEnvelopeUsingPOST
      */
    addRedEnvelope(params) {
      return Ajax.ajaxs("/redEnvelope/addRedEnvelope", params, 'POST', '/home-live-redenvelope-management/redenvelopes');
    },


    /**
      * 查询红包列表
      * @param {*} params
      * @returns
      * @see http://192.168.0.126:9006/doc.html#/default/%E7%BA%A2%E5%8C%85%E7%AE%A1%E7%90%86%E6%8E%A5%E5%8F%A3/queryRedEnvelopeListUsingGET
      */
    queryRedEnvelopeList(params) {
      return Ajax.ajaxs("/redEnvelope/queryRedEnvelopeList", params, "GET");
    },
    /**
      * 投放红包
      * @param {*} params
      * @returns
      * @see http://192.168.0.126:9006/doc.html#/default/%E7%BA%A2%E5%8C%85%E7%AE%A1%E7%90%86%E6%8E%A5%E5%8F%A3/launchRedEnvelopeUsingPUT
      */
    launchRedEnvelope(params) {
      return Ajax.ajaxs("/redEnvelope/launchRedEnvelope", params, "PUT");
    },
    /**
      * 作废红包
      * @param {*} params
      * @returns
      * @see http://192.168.0.126:9006/doc.html#/default/%E7%BA%A2%E5%8C%85%E7%AE%A1%E7%90%86%E6%8E%A5%E5%8F%A3/deleteRedEnvelopeUsingDELETE
      */
    deleteRedEnvelope(params) {
      return Ajax.ajaxs("/redEnvelope/deleteRedEnvelope", params, "DELETE");
    },
  },
  // 直播券管理
  liveCoupon: {
    /**
      * 新增直播券
      * @param {*} params
      * @returns
      * @see http://192.168.0.126:9006/doc.html#/default/live-coupon-controller/addLiveCouponUsingPUT
      */
    addLiveCoupon(params) {
      return Ajax.ajaxs("/liveCoupon/addLiveCoupon", params, "PUT", '/home-live-redenvelope-management/anchorTicket');
    },
    /**
      * 直播券列表
      * @param {*} params
      * @returns
      * @see http://192.168.0.126:9006/doc.html#/default/live-coupon-controller/getAllLiveCouponUsingGET
      */
    getAllLiveCoupon(params) {
      return Ajax.ajaxs("/liveCoupon/getAllLiveCoupon", params, "GET");
    },
    /**
      * 删除直播券
      * @param {*} params
      * @returns
      * @see http://192.168.0.126:9006/doc.html#/default/live-coupon-controller/deleteLiveCouponUsingDELETE
      */
    deleteLiveCoupon(params) {
      return Ajax.ajaxs("/liveCoupon/deleteLiveCoupon", params, "DELETE");
    },

    requestPermissions(params) {
      return Ajax.ajaxs("/liveCoupon/requestPermissions", params, "PUT");
    },
    invalidLiveCoupon(params) {
      return Ajax.ajaxs("/liveCoupon/invalidLiveCoupon", params, "DELETE");
    },
  },
  // 账号违规信息管理
  abnormalAccountInfo: {
    /**
      * 违规信息列表
      * @param {*} params
      * @returns
      * @see http://192.168.0.126:9006/doc.html#/default/%E8%B4%A6%E5%8F%B7%E8%BF%9D%E8%A7%84%E4%BF%A1%E6%81%AF%E7%AE%A1%E7%90%86%E6%8E%A5%E5%8F%A3/queryAllByLimitUsingGET
      */
    queryAllByLimit(params) {
      return Ajax.ajaxs("/abnormalAccountInfo/queryAllByLimit", params, "GET");
    },
    /**
      * 违规申述
      * @param {*} params
      * @returns
      * @see http://192.168.0.126:9006/doc.html#/default/%E8%B4%A6%E5%8F%B7%E8%BF%9D%E8%A7%84%E4%BF%A1%E6%81%AF%E7%AE%A1%E7%90%86%E6%8E%A5%E5%8F%A3/addAppealInfoUsingPUT
      */
    addAppealInfo(params) {
      return Ajax.ajaxs("/abnormalAccountInfo/addAppealInfo", params, "PUT");
    },
  },
  // 橱窗管理
  displayWindow: {
    /**
      * 创建红包
      * @param {*} params
      * @returns
      * @see http://192.168.0.126:9006/doc.html#/default/%E7%BA%A2%E5%8C%85%E7%AE%A1%E7%90%86%E6%8E%A5%E5%8F%A3/addRedEnvelopeUsingPOST
      */

    // 获取推广信息
    getPromotionInfo(params) {
      return Ajax.ajaxs("/shopWindow/getPromotionInfo", params, "GET");
    },
    // 橱窗商品列表
    getShopWindowGoodsList(params) {
      return Ajax.ajaxs("/shopWindow/getShopWindowGoodsList", params, "GET");
    },
    // 添加橱窗商品
    addGoods(params) {
      return Ajax.ajaxs("/shopWindow/addGoods", params, "PUT");
    },
    // 批量删除橱窗商品
    deleteShopWindowGoods(params) {
      return Ajax.ajaxs("/shopWindow/deleteShopWindowGoods", params, "DELETE");
    },
    // 根据商品编号查询商品信息
    getGoodsInfo(params) {
      return Ajax.ajaxs("/shopWindow/getGoodsInfo", params, "GET");
    },
    // 批量隐藏-显示橱窗商品
    hideShopWindowGoods(params) {
      return Ajax.ajaxs("/shopWindow/hideShopWindowGoods", params, "PUT");
    },
    // 添加推广信息
    updatePromotionInfo(params) {
      return Ajax.ajaxs("/shopWindow/updatePromotionInfo", params, "PUT");
    },
    // 橱窗添加搜索
    getGoodsDetail(params) {
      return Ajax.ajaxs("goodsBasicsInfo/getGoodsDetail", params, "GET");
    },

  },
  homeLive: {
    // 查询全部直播间商品
    queryAllByLimit(params) {
      return Ajax.ajaxs("/liveRoomGoods/queryAllByLimit", params, "GET");
    },
    // 直播间查询我的店铺内商品
    getStoreGoods(params) {
      return Ajax.ajaxs("/liveRoomGoods/getStoreGoods", params, "GET");
    },
    // 直播间查询定向商品
    getSpecialGoods(params) {
      return Ajax.ajaxs("/liveRoomGoods/getSpecialGoods", params, "GET");
    },
    // 添加直播间商品
    addLiveRoomGoods(params) {
      return Ajax.ajaxs("/liveRoomGoods/addLiveRoomGoods", params, "PUT", '/home-live-commodityManagement');
    },
    // 删除直播间内商品
    deleteLiveGoods(params) {
      return Ajax.ajaxs("/liveRoomGoods/deleteLiveGoods", params, "DELETE");
    },
    // 设置卖点
    addGoodsSellPoint(params) {
      return Ajax.ajaxs("/liveRoomGoods/addGoodsSellPoint", params, "PUT");
    },
    // 查询全部裂变券
    fissionCouponQueryAllByLimit(params) {
      return Ajax.ajaxs("/fissionCoupon/queryAllByLimit", params, "GET");
    },
    // 投放-关闭活动
    launchFissionCoupon(params) {
      return Ajax.ajaxs("/fissionCoupon/launchFissionCoupon", params, "PUT");
    },
    // 添加秒杀
    addSeckill(params) {
      return Ajax.ajaxs("/seckill/addSeckill", params, "PUT");
    },
    // 停止秒杀
    stopSeckill(params) {
      return Ajax.ajaxs("/seckill/stopSeckill", params, "PUT");
    },
    // 个人信息
    getLiveRoomInfo(params) {
      return Ajax.ajaxs("/liveRoomInfo/getLiveRoomInfo", params, "GET");
    },
  },
  liveRoomCoupon: {
    // 直播间查询全部优惠券
    getAllCouponList(params) {
      return Ajax.ajaxs("/liveRoomCoupon/getAllCouponList", params, "GET");
    },
    // 直播间添加优惠券
    addLiveRoomCoupon(params) {
      return Ajax.ajaxs("/liveRoomCoupon/addLiveRoomCoupon", params, "PUT");
    },
    // 直播间删除优惠券
    deleteLiveRoomCoupon(params) {
      return Ajax.ajaxs("/liveRoomCoupon/deleteLiveRoomCoupon", params, "DELETE");
    },
    // 直播间下发优惠券
    launchCoupon(params) {
      return Ajax.ajaxs("/liveRoomCoupon/launchCoupon", params, "PUT");
    },
  },
  liveSet: {
    // 查询直播间设置
    getLiveSetting(params) {
      return Ajax.ajaxs("/liveSetting/getLiveSetting", params, "GET");
    },
    // 修改-添加直播间设置
    updateLiveSetting(params) {
      return Ajax.ajaxs("/liveSetting/updateLiveSetting", params, "PUT");
    },
  },
  goodsBasicsInfo: {
    // 根据id查询商品sku
    getGoodsAttrsInfo(params) {
      return Ajax.ajaxs("/goodsBasicsInfo/getGoodsAttrsInfo", params, "GET");
    },
    // 根据id查询商品信息
    getGoodsDetail(params) {
      return Ajax.ajaxs("/goodsBasicsInfo/getGoodsDetail", params, "GET");
    },
  },
  //上传文件
  file: {
    // 上传单个文件
    upload(props) {
      return Ajax.uploadFile(`/file/upload`, props);
    },
  },
  // 打分
  score: {
    // 用户操作步骤
    addUserStepsRecord(params) {
      return Ajax.ajaxs("/operationSteps/addUserStepsRecord", params, "POST");
    },
  }
};

export default Apis;
