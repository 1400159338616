import React from 'react';
import { ShopOutlined, ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Input, Select, Table, Typography } from 'antd';
import "./index.css";
import SetBuyingModal from "components/SetBuyingModal";
import AddCommodity from "./AddCommodity";
import EditCommodity from "./EditCommodity";
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import AJAX from "api/apis";
import { getStepsLocalStorage, goodsSource } from 'utils/utils'
import DyModal from "components/DyModal";
import DyImage from 'components/DyImage';
const { Option } = Select;
const { Text, Paragraph } = Typography;

class DisplayWindow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            windowGoodsList: [],
            windowGoodstable: {
                current: 1,
                pageSize: 10,
                total: 20
            },
            setBuying: false,
            deleteVisible: false,
            selectList: [],
            gooodstatus: false,
            tableLoading: false,
            promotionInfoId: '',
            formSourceValue: "",
            goodsnameValue: '',
            path: "DisplayWindow",
        };
    }
    componentDidMount() {
        if (getStepsLocalStorage(this.state.path)) {
            this.getShopWindowGoodsList()
            this.setReactours()
        } else {
            this.setState({
                windowGoodsList: [
                    { "id": 16, "goodsId": 39, "goodsName": "花西子护发精油", "storeName": "化妆品直营店", "goodsNumber": "574365701747", "goodsSource": "2", "goodsSourceName": "小店", "imgUrl": "", "classifyName": "头发护理", "goodsPrice": "20.00", "goodsCommission": "30.00", "goodsCommissionRate": "10.00", "liveStatus": true, "authorizeStatus": null }
                ],
                closeQueryAllByLimit: true
            })
            setTimeout(() => {
                this.setReactours()
            }, 500);

        }
        // console.log(this.refs)
    }
    componentDidUpdate(nextProps) {
        // console.log(this.props.isTourOpen, this.props.isTourOpen === false, getStepsLocalStorage(this.state.path), this.state.closeQueryAllByLimit)
        if (this.props.isTourOpen === false && getStepsLocalStorage(this.state.path) && this.state.closeQueryAllByLimit) {
            this.getShopWindowGoodsList()
            this.setState({ closeQueryAllByLimit: false })
        }
    }
    /** ====   接口-    ========== */
    getShopWindowGoodsList = async (param = {}) => {
        const { formSourceValue, goodsnameValue } = this.state
        this.setState({ tableLoading: true })
        let query = {
            goodsSource: formSourceValue,
            goodsName: goodsnameValue,
            ...param
        }
        await AJAX.displayWindow.getShopWindowGoodsList(query).then(res => {
            // console.log(res)
            this.setState({
                windowGoodsList: res.data.list,
                windowGoodstable: {
                    current: res.data.pageNum,
                    pageSize: res.data.pageSize,
                    total: res.data.total
                }
            })
        })
        this.setState({ tableLoading: false })
    }
    deleteShopWindowGoods = (id) => {
        AJAX.displayWindow.deleteShopWindowGoods({ shopWindowGoodsIds: id }).then(res => {
            // console.log(res)
            this.getShopWindowGoodsList({
                pageNum: this.state.windowGoodstable.current,
                pageSize: this.state.windowGoodstable.pageSize,
            })
            this.setState({
                selectList: []
            })
        })
    }
    hideShopWindowGoods = (status, row) => {
        let hideIds = this.state.selectList.map(item => item.id).join(",")
        if (row) {
            hideIds = String(row.id)
        }
        let query = {
            "hideIds": hideIds,
            "liveStatus": status
        }
        // console.log(query)
        AJAX.displayWindow.hideShopWindowGoods(query).then(res => {
            // console.log(res)
            this.getShopWindowGoodsList({
                pageNum: this.state.windowGoodstable.current,
                pageSize: this.state.windowGoodstable.pageSize,
            })
        })
    }
    /** ====   接口-end    ========== */
    setReactours = () => {
        this.props.reactours({
            isTourOpen: true,
            isOpen: true,
            path: "DisplayWindow",
            steps: [
                {
                    selector: '.commodityManagement-table .ant-btn-primary',
                    content: (
                        <div>
                            <div>添加商品</div>
                            <p>可以快速添加店铺、定向等更多商品</p>
                        </div>
                    ),
                },
                {
                    selector: '.commodityManagement-table .commodityManagement-table-edit',
                    content: (
                        <div>
                            <div>编辑商品</div>
                            <p>可以编辑商品信息，文案，图片等</p>
                        </div>
                    ),
                },
            ]
        })
    }
    columns = () => {
        return [
            {
                title: '',
                dataIndex: 'liveStatus',
                render: (text, row, index) => {
                    return (
                        <div>
                            {
                                !text ? <EyeOutlined onClick={() => { this.hideShopWindowGoods(true, row) }} style={{ color: "#6647ff", fontSize: 16 }} />
                                    : <img onClick={() => { this.hideShopWindowGoods(false, row) }} src={require(`@assets/images/homeLive/display-window-table-hide.svg`).default}></img>
                            }
                        </div>
                    )
                }
            },
            {
                title: '商品信息',
                dataIndex: 'goodsName',
                render: (text, row, index) => {
                    return (
                        <div className="commodityManagement-table-name">
                            <DyImage style={{ width: 62, height: 62 }} src={row.imgUrl}></DyImage>
                            <div className="commodityManagement-table-name-center">
                                <div>{row.goodsName}</div>
                                <div>ID：{row.goodsNumber}</div>
                                <div><ExclamationCircleOutlined />新手店铺</div>
                            </div>
                        </div>
                    )
                },
            },
            {
                title: '商品来源',
                dataIndex: 'goodsSource',
                render: (text) => {
                    return goodsSource(text)
                }
            },
            {
                title: '类目',
                dataIndex: 'classifyName',
            },
            {
                title: '售价',
                dataIndex: 'goodsPrice',
                render: (text) => "￥" + text
            },
            {
                title: '佣金',
                dataIndex: 'goodsCommission',
            },
            {
                title: '佣金率',
                dataIndex: 'goodsCommissionRate',
                render: (text) => text ? text + "%" : 0
            },
            {
                title: '操作',
                dataIndex: 'address',
                render: (text, row, index) => {
                    return (
                        <div>
                            <Button type="link" danger onClick={() => { this.deleteModalOpen(row) }}>删除</Button>
                            <Button type="link" className="commodityManagement-table-edit" onClick={() => { this.EditCommodity.showDrawer(); this.EditCommodity.getPromotionInfo(row) }} danger>编辑</Button>
                        </div>
                    )
                }
            },
        ]
    }
    setBuyingOpen = () => {
        this.setState({ setBuying: true })
    }
    setBuyingOk = () => {
        this.setState({ setBuying: false })
    }
    setBuyingCancle = () => {
        this.setState({ setBuying: false })
    }
    deleteModalOpen = (row) => {
        // console.log(row)
        if (row) {
            this.setState({
                deleteVisible: true,
                selectList: [row]
            })
            this.refs.delGoodlist.showModal()
        } else {
            this.setState({
                deleteVisible: true,
            })
            this.refs.delGoodlist.showModal()
        }
    }
    deleteModalOk = () => {
        this.delGoodsList()
        this.setState({
            deleteVisible: false,
        })
    }
    deleteModalCancel = () => {
        this.setState({
            deleteVisible: false,
            selectList: []
        })
    }
    delGoodsList = () => {
        let id = this.state.selectList.map(item => item.id).join(",")
        // console.log(id)
        this.deleteShopWindowGoods(id)

    }
    onGoodSource = (e) => {
        this.setState({ formSourceValue: e })
    }
    onGoodsname = (e) => {
        this.setState({ goodsnameValue: e.target.value })
    }
    formReset = () => {
        this.setState({ goodsnameValue: "", formSourceValue: "" }, () => {
            this.getShopWindowGoodsList()
        })
    }
    tableOnchange = (e) => {
        // console.log(e)
        this.getShopWindowGoodsList({
            pageNum: e.current,
            pageSize: e.pageSize,
        })
        // this.setState({ selectList: [] })
    }
    render() {
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                this.setState({
                    selectList: selectedRows
                })
            },
            getCheckboxProps: (record) => ({
                disabled: record.name === 'Disabled User',
                // Column configuration not to be checked
                name: record.name,
            }),
        };
        const { windowGoodsList, setBuying, formSourceValue, selectList, tableLoading, promotionInfoId, goodsnameValue, windowGoodstable } = this.state
        return (
            <div className="commodityManagement displayWindow">
                <div className="commodityManagement-header">

                    <div className="commodityManagement-header-form">
                        <div className="commodityManagement-header-form-right">
                            <span>商品来源</span>
                            <Select onChange={this.onGoodSource} value={formSourceValue} defaultValue="all" style={{ width: 220 }}>
                                <Option value="">全部</Option>
                                <Option value="1">淘宝</Option>
                                <Option value="2">小店</Option>
                                <Option value="3">京东</Option>
                                <Option value="4">考拉海购</Option>
                                <Option value="5">唯品会</Option>
                                <Option value="6">苏宁易购</Option>
                                <Option value="7">洋码头</Option>
                            </Select>
                            <span>商品名称</span>
                            <Input placeholder="请输入商品名称" value={goodsnameValue} onChange={this.onGoodsname} style={{ width: 220 }} />
                        </div>
                        <div>
                            <Button type="primary" onClick={() => { this.getShopWindowGoodsList() }}>查询</Button>
                            <Button style={{ marginLeft: 20 }} onClick={this.formReset} >重置</Button>
                        </div>
                    </div>
                </div>
                <div className="commodityManagement-table">
                    <div className="commodityManagement-table-header">
                        <div className="commodityManagement-table-header-right">
                            <strong>橱窗商品列表</strong>
                            <span>查看自卖商品</span>
                        </div>
                        <div>
                            <Button disabled={selectList.length <= 0} onClick={() => { this.hideShopWindowGoods(false, '') }} >批量显示</Button>
                            <Button disabled={selectList.length <= 0} onClick={() => { this.hideShopWindowGoods(true, '') }}>批量隐藏</Button>
                            <Button disabled={selectList.length <= 0}>批量置顶</Button>
                            <Button disabled={selectList.length <= 0}>批量更新</Button>
                            <Button disabled={selectList.length <= 0} onClick={() => { this.deleteModalOpen('') }}>批量删除</Button>
                            <Button type="primary" onClick={() => {
                                this.AddCommodity.showDrawer(0)
                            }} >添加商品</Button>
                        </div>
                    </div>
                    <Table
                        rowSelection={{
                            type: "checkbox",
                            ...rowSelection,
                        }}
                        onChange={this.tableOnchange}
                        columns={this.columns()}
                        dataSource={windowGoodsList}
                        loading={tableLoading}
                        rowKey="id"
                        pagination={{
                            total: windowGoodstable.total,
                            current: windowGoodstable.pageNum,
                            pageSize: windowGoodstable.pageSize,
                            pageSizeOptions: [10, 20, 30],
                            showSizeChanger: true,
                            showTotal: total => `共 ${total} 条数据`
                        }}
                    />
                </div>
                {/* <SetBuyingModal visible={setBuying} setBuyingOk={this.setBuyingOk} setBuyingCancle={this.setBuyingCancle} /> */}
                <DyModal handleOk={this.deleteModalOk} showIcon={false} ref="delGoodlist" title="删除橱窗商品" message="确认从橱窗中删除所选商品，删除后将不在您个人橱窗中展示" />

                {/* 添加商品 */}
                <AddCommodity setReactours={this.setReactours} getShopWindowGoodsList={this.getShopWindowGoodsList} onRef={(ref) => { this.AddCommodity = ref }} />
                {/* 编辑商品 */}
                <EditCommodity setReactours={this.setReactours} promotionInfoId={promotionInfoId} onRef={(ref) => { this.EditCommodity = ref }} />
            </div>
        )
    }
}
// reducers中的数据 ,然后更新组件
const mapStateToProps = (state) => ({
    isTourOpen: state.reactours.isTourOpen,
    steps: state.reactours.steps,
    isOpen: state.reactours.isOpen,
    reactours: state.reactours.reactours,
    data: state
})
export default connect(mapStateToProps, { reactours: (value) => reactours(value) })(DisplayWindow)