import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Login from "view/login";
import ScanCode from "view/login/ScanCode/ScanCode"; // 扫码
import Register from "view/register";
import HomeLive from "view/HomeLive"; // 直播中控台
import LiveSet from "view/LiveSet"; // 直播设置
import LiveSendCoupons from "view/LiveSendCoupons"; // 直播间发券
import CommodityManagement from "view/CommodityManagement"; // 直播间商品管理
import ViolationManagement from "view/ViolationManagement"; //账号违规管理
import RedEnvelopes from "view/RedEnvelopeManagement/RedEnvelopes";  //红包管理
import AnchorTicket from "view/RedEnvelopeManagement/anchorTicket";  //主播券管理
import FundDetails from "view/RedEnvelopeManagement/fundDetails";  //资金明细
import DisplayWindow from 'view/DisplayWindow'; //橱窗商品管理
import DataLive from "view/DataLive"; //直播数据
import DataLiveDetail from 'view/DataLive/DataLiveDetail/DataLiveDetail'; //直播数据详情
import InletMarketing from '@view/Inlet/InletMarketing/index'; //首页营销中心
import InletPage from '@view/Inlet/InletPage/index'; //首页


export default () => {
  return (
    <Switch>
      <PrivateRoute exact path={"/login"} component={Login} />
      <PrivateRoute exact path={"/register"} component={Register} />
      {/* 进入控制台前引导页 === inlet-开头 */}
      <PrivateRoute exact path={"/inlet-marketing"} component={InletMarketing} />
      <PrivateRoute exact path={"/inlet-page"} component={InletPage} />
      {/* 直播管理页面===home-live开头 */}
      <PrivateRoute exact path={"/home-live"} component={HomeLive} />
      <PrivateRoute exact path={"/home-live-set"} component={LiveSet} />
      <PrivateRoute exact path={"/home-live-SendCoupons"} component={LiveSendCoupons} />
      <PrivateRoute exact path={"/home-live-commodityManagement"} component={CommodityManagement} />
      <PrivateRoute exact path={"/home-live-displayWindow"} component={DisplayWindow} />
      <PrivateRoute exact path={"/home-live-violation-management"} component={ViolationManagement} />
      <PrivateRoute exact path={"/home-live-redenvelope-management/redenvelopes"} component={RedEnvelopes} />
      <PrivateRoute exact path={"/home-live-redenvelope-management/anchorTicket"} component={AnchorTicket} />
      <PrivateRoute exact path={"/home-live-redenvelope-management/fundDetails"} component={FundDetails} />

      {/* 数据参谋页面===live-data开头 */}
      <PrivateRoute exact path={"/live-data"} component={DataLive} />
      <PrivateRoute exact path={"/live-data-detail"} component={DataLiveDetail} />

      <PrivateRoute exact path={"/login/scancode"} component={ScanCode} />
      <Redirect from="/*" to="/inlet-page" />
    </Switch>
  );
};
