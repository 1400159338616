import React from 'react';
import "./index.css"
import { Menu } from 'antd'
import container from './container'
const { SubMenu } = Menu;

class SideNav extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      current: '',
      keyPath: 'sub1',
      pathname: window.location.pathname,
    };
  }
  componentDidMount() {
    this.setState({ current: window.location.pathname }, () => { this.subkey() })
  }
  componentDidUpdate(nextProps, nextState) {
    if (window.location.pathname != this.state.pathname) {
      this.props.navAction()
      this.setState({
        pathname: window.location.pathname,
      }, () => {
        this.subkey()
      })
    }
  }
  subkey = () => {
    let keyPath = 'sub1'
    const { pagekey } = this.props
    const list = this.props[pagekey]
    list.map(item => {
      let ele = item.data.find(a => a.key == window.location.pathname)
      if (ele) keyPath = ele.key
    })
    this.setState({
      keyPath,
      current: keyPath,
    })
  }
  subIcon = (keyPath, key) => {
    let row = {
      sub1: (<svg t="1629424255303" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4570" width="16" height="16"><path d="M707.4 800.1H132.5c-29.7 0-53.9-24.2-53.9-53.9V279.1c0-29.7 24.2-53.9 53.9-53.9h574.9c29.7 0 53.9 24.2 53.9 53.9v467.1c0 29.8-24.2 53.9-53.9 53.9z m-574.9-539c-9.9 0-18 8.1-18 18v467.1c0 9.9 8.1 18 18 18h574.9c9.9 0 18-8.1 18-18V279.1c0-9.9-8.1-18-18-18H132.5z"
        p-id="4571" fill={keyPath == key ? "#6647ff" : "#12141a"}></path><path d="M905 730c-7.2 0-14.3-2.2-20.3-6.4L733.2 619.2c-4.9-3.4-7.8-8.9-7.8-14.8V420.9c0-5.9 2.9-11.4 7.8-14.8l151.5-104.4c6.1-4.2 13.1-6.4 20.3-6.4 19.8 0 36 16.1 36 36v362.8c0 19.8-16.1 35.9-36 35.9zM761.3 595L905 694l0.1-362.7-143.7 99.1V595zM348 656.4c-21 0-38-17.4-38-38.7v-210c0-21.4 17.1-38.8 38-38.8 6.7 0 13.4 1.9 19.2 5.4l175.5 105c11.7 7 18.8 19.5 18.8 33.4 0 13.9-7 26.4-18.8 33.4L367.2 651c-5.9 3.5-12.5 5.4-19.2 5.4z m0.2-251.3c-1.1 0-2.3 0.7-2.3 2.6v210c0 2.3 1.7 2.8 2.1 2.8 0.1 0 0.3 0 0.8-0.3l175.5-105c1.1-0.6 1.3-1.7 1.3-2.6 0-0.8-0.2-1.9-1.3-2.5l-175.6-105h-0.5z"
          p-id="4572" fill={keyPath == key ? "#6647ff" : "#12141a"}></path></svg>),
      sub2: (<svg t="1629425253063" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5751" width="16" height="16"><path d="M798.72 907.776H286.72c-53.76 0-97.28-43.52-97.28-97.28V208.384c0-53.76 43.52-97.28 97.28-97.28h512c53.76 0 97.28 43.52 97.28 97.28v602.112c0 53.76-43.52 97.28-97.28 97.28zM286.72 150.528c-32.256 0-58.368 26.112-58.368 58.368v602.112c0 32.256 26.112 58.368 58.368 58.368h512c32.256 0 58.368-26.112 58.368-58.368V208.384c0-32.256-26.112-58.368-58.368-58.368H286.72z"
        fill={keyPath == key ? "#6647ff" : "#12141a"} p-id="5752"></path><path d="M537.6 437.248c-115.2 0-208.896-93.696-208.896-208.896 0-10.752 8.704-19.456 19.456-19.456 10.752 0 19.456 8.704 19.456 19.456 0 93.696 76.288 169.984 169.984 169.984 93.696 0 169.984-76.288 169.984-169.984 0-10.752 8.704-19.456 19.456-19.456s19.456 8.704 19.456 19.456c0 115.2-93.696 208.896-208.896 208.896z"
          fill={keyPath == key ? "#6647ff" : "#12141a"} p-id="5753"></path></svg>),
      sub3: (<svg t="1629426161846" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="21329" width="16" height="16"><path d="M512 938.666667C276.362667 938.666667 85.333333 747.637333 85.333333 512S276.362667 85.333333 512 85.333333s426.666667 191.029333 426.666667 426.666667-191.029333 426.666667-426.666667 426.666667z m0-64c200.298667 0 362.666667-162.368 362.666667-362.666667S712.298667 149.333333 512 149.333333 149.333333 311.701333 149.333333 512s162.368 362.666667 362.666667 362.666667z m8.213333-265.674667l-81.429333-88.704-95.754667 99.264a32 32 0 1 1-46.058666-44.437333l119.36-123.733334a32 32 0 0 1 46.613333 0.576l81.792 89.109334 136.608-136.992a32 32 0 1 1 45.312 45.184l-160.213333 160.682666a32 32 0 0 1-46.24-0.96z" p-id="21330"
        fill={keyPath == key ? "#6647ff" : "#12141a"}></path></svg>),
      sub4: (<svg t="1629426678549" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="42647" width="16" height="16"><path d="M337.92 614.4a61.44 61.44 0 0 0-61.44 61.44v40.96a61.44 61.44 0 0 0 61.44 63.488A65.536 65.536 0 0 0 404.48 716.8v-40.96A66.048 66.048 0 0 0 337.92 614.4z m189.44-169.984a65.024 65.024 0 0 0-61.44 61.952V716.8a65.024 65.024 0 0 0 61.44 63.488A65.536 65.536 0 0 0 593.92 716.8V506.368a66.048 66.048 0 0 0-66.56-64z m194.56-169.984a65.536 65.536 0 0 0-66.56 63.488V716.8a65.536 65.536 0 0 0 66.56 63.488A61.44 61.44 0 0 0 783.36 716.8V336.384a61.44 61.44 0 0 0-61.44-64zM829.44 102.4H230.4A130.56 130.56 0 0 0 102.4 229.888v594.944a130.56 130.56 0 0 0 128 127.488h599.04a125.952 125.952 0 0 0 122.88-127.488V229.888A125.952 125.952 0 0 0 829.44 102.4z m40.96 722.432a43.008 43.008 0 0 1-40.96 42.496H230.4a43.008 43.008 0 0 1-40.96-42.496V229.888a43.008 43.008 0 0 1 40.96-42.496h599.04a43.008 43.008 0 0 1 40.96 42.496z"
        p-id="42648" fill={keyPath == key ? "#6647ff" : "#12141a"}></path></svg>),
      sub5: (<svg t="1629427035818" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="58651" width="16" height="16"><path d="M793.6 192c3.5 0 6.4 2.9 6.4 6.4v627.2c0 3.5-2.9 6.4-6.4 6.4H230.4c-3.5 0-6.4-2.9-6.4-6.4V198.4c0-3.5 2.9-6.4 6.4-6.4h563.2m0-96H230.4C173.8 96 128 141.8 128 198.4v627.2c0 56.6 45.8 102.4 102.4 102.4h563.2c56.6 0 102.4-45.8 102.4-102.4V198.4C896 141.8 850.2 96 793.6 96z" fill="#131414" p-id="58652"></path><path d="M512 390.3H320c-21.2 0-38.4-17.2-38.4-38.4s17.2-38.4 38.4-38.4h192c21.2 0 38.4 17.2 38.4 38.4s-17.2 38.4-38.4 38.4zM704 550.3H320c-21.2 0-38.4-17.2-38.4-38.4s17.2-38.4 38.4-38.4h384c21.2 0 38.4 17.2 38.4 38.4s-17.2 38.4-38.4 38.4zM640 710.3H320c-21.2 0-38.4-17.2-38.4-38.4s17.2-38.4 38.4-38.4h320c21.2 0 38.4 17.2 38.4 38.4s-17.2 38.4-38.4 38.4z"
        fill={keyPath == key ? "#6647ff" : "#12141a"} p-id="58653"></path></svg>)
    }
    return row[key]
  }
  handleClick = e => {
    // console.log('click ', e);
    if (e.key.includes("link")) {
      // window.open("https://qianchuan.jinritemai.com/?aavid=1696753450405901&enter_from=buyin")
      window.open("https://qianchuan.jinritemai.com/")
    } else {
      if (!e.key.includes('/')) return
      this.setState({
        current: e.key,
        keyPath: e.keyPath[1]
      })
      // window.location.href = window.location.origin + e.key
      this.props.history.push(e.key)
    }
  };
  render() {
    const { keyPath } = this.state
    const { pagekey } = this.props
    const list = this.props[pagekey]
    return (
      <div className={pagekey != 'inletPage'?'sidenav':'sidenav sidenav246'} >
        <Menu
          selectedKeys={this.state.current}
          defaultOpenKeys={['sub1', "sub2", "sub3", "sub4", "sub5"]}
          mode="inline"
          expandIcon={pagekey == 'inletPage'}
          onClick={this.handleClick}
        >
          {
            pagekey != 'inletPage' &&list.map(a => {
              if (a.data.length) {
                return (
                  <SubMenu key={a.key} icon={this.subIcon(keyPath, a.key)} title={a.title}>
                    {
                      a.data.map((b, ind) => {
                        return <Menu.Item key={b.key || ind}>{b.name}</Menu.Item>
                      })
                    }
                  </SubMenu>
                )
              } else {
                return <Menu.Item icon={this.subIcon(keyPath, a.key)} key={a.key}>{a.title}</Menu.Item>
              }

            })
          }
          {
            pagekey == 'inletPage' &&list.map(a => {
              if (a.data.length) {
                return (
                  <SubMenu key={a.key} icon={this.subIcon(keyPath, a.key)} title={a.title}>
                    {
                      a.data.map((b, ind) => {
                        return <Menu.Item key={b || ind}>{b}</Menu.Item>
                      })
                    }
                  </SubMenu>
                )
              } else {
                return <Menu.Item icon={this.subIcon(keyPath, a.key)} key={a.key}>{a.title}</Menu.Item>
              }

            })
          }
        </Menu>
      </div >
    )
  }
}

export default container(SideNav)