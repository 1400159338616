
const data = {
    reactours: {

        isTourOpen: true,
        steps: [
            {
                selector: '.app-main',
                content: '开始创建',
            },
        ]
    }
}
export default (state = data, action) => {

    if (action.type == "REACT-TOUR") {
        let reactours = action.reactours
        let stepList = localStorage.getItem('stepList') || [];
        // console.log(action.reactours)
        if (action.reactours.isOpen) {
            if (JSON.stringify(stepList) != "[]") {
                stepList = JSON.parse(stepList)
                if (!stepList.includes(action.reactours.path)) {
                    reactours.isTourOpen = true
                    action.reactours.path && stepList.push(action.reactours.path)
                    localStorage.setItem('stepList', JSON.stringify(stepList));
                    // console.log(stepList, '12')
                } else {
                    reactours.isTourOpen = false
                }
                // action.reactours.path = 
            } else {
                reactours.isTourOpen = true
                action.reactours.path && stepList.push(action.reactours.path)
                localStorage.setItem('stepList', JSON.stringify(stepList));
                // console.log(stepList, '12')
            }
        }


        return {
            ...state,
            ...reactours
        }
    }
    return state;
}