import React from 'react';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { Link } from 'react-router-dom'
import AJAX from "@api/apis";
import moment from 'moment';
import './index.css'
import AddAnchorTicket from './AddAnchorTicket'
import RechargeFund from 'components/rechargeFund'
import WithdrawalFunds from 'components/withdrawalFunds'
import DyModal from "components/DyModal"
import { Table, Button, Form, Row, Col, Input, Select, DatePicker, message } from 'antd';
import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
const { Option } = Select
const { RangePicker } = DatePicker;


class AnchorTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      couponData: [],
      page: {
        pageSize: 10,
        pageNum: 1,
        total: 10
      },
      liveCouponId: null,
      outMedel: false,
      deleteLoading: false,
    }
    this.paginationProps = {
      showSizeChanger: true,
      position: ['bottomCenter'],
      pageSizeOptions: [5, 10, 20, 50, 100],
      onShowSizeChange: (current, pageSize) => {
        let page = this.state.page;
        page.pageSize = pageSize;
        this.setState({
          page: page,
          loading: true
        }, () => {
          this.getAllLiveCoupon()
        })
      },
      onChange: (current) => {
        let page = this.state.page;
        page.pageNum = current;
        this.setState({
          page: page,
          loading: true
        }, () => {
          this.getAllLiveCoupon()
        })
      },
    }
  }
  formRef = e => {
    this.form = e
  }
  componentDidMount() {
    this.setReactours();
    this.getAllLiveCoupon()
  }
  getAllLiveCoupon = () => {
    this.form.validateFields().then(data => {
      console.log(data, "******")
      let { couponName, couponStatus, type, use_time } = data;
      let obj = {
        pageNum: this.state.page.pageNum,
        pageSize: this.state.page.pageSize,
        couponStatus: couponStatus
      };
      if (couponName) obj.couponName = couponName
      if (type) obj.type = type
      if (use_time) {
        obj.startTime = moment(use_time[0]).format('YYYY-MM-DD')
        obj.endTime = moment(use_time[1]).format('YYYY-MM-DD')
      }


      AJAX.liveCoupon.getAllLiveCoupon(obj).then(res => {
        if (res.code === 1) {
          this.setState({
            couponData: res.data.list,
            page: {
              ...this.state.page,
              pageSize: res.data.pageSize,
              pageNum: res.data.pageNum,
              total: res.data.total
            }
          })
        }
      })
    })

  }
  reset = () => {
    this.form.setFieldsValue({
      couponName: '',
      couponStatus: 0,
      type: 0,
      use_time: ''
    })
    this.getAllLiveCoupon()
  }

  delRed = id => {
    this.setState({
      liveCouponId: id,
      outMedel: true
    })
  }
  determine = () => {
    let { liveCouponId, deleteLoading } = this.state
    if (deleteLoading) return
    this.setState({ deleteLoading: true })
    AJAX.liveCoupon.invalidLiveCoupon({ liveCouponId }).then(res => {
      if (res.code == 1) {
        message.success(res.msg)
        this.setState({
          outMedel: false
        })
        this.getAllLiveCoupon();
      }
    }).catch(() => {
      return false
    })
    setTimeout(() => {
      this.setState({ deleteLoading: false })
    }, 2000);
  }

  setReactours = () => {
    this.props.reactours({
      isTourOpen: true,
      isOpen: true,
      path: "/home-live-redenvelope-management/anchorTicket_list",
      steps: [
        {
          selector: ".tours_1",
          content: '进入新建主播券窗口'
        },
      ],
    })
  }
  AddAnchorTicket = () => {
    this.AddAnchorTickets.showDrawer()
  }
  locale = () => {
    return {
      emptyText: (
        <div className="ShareCoupons-emptyText RedEnvelopes-emptyText">
          <span>暂无数据</span>
        </div>
      )
    }
  }
  rechargeFund = () => {
    // this.RechargeFunds.showDrawer()
  }
  withdrawalFunds = () => {
    // this.WithdrawalFundss.showDrawer()
  }
  render() {
    let { couponData, outMedel } = this.state
    let { pageNum, pageSize, total } = this.state.page
    const columns = [
      {
        title: '主播券信息',
        dataIndex: 'couponName',
        fixed: 'left',
        width: 250
      },
      {
        title: '类型',
        dataIndex: 'type',
        width: 150,
        render: (type) => {
          return <span>{type == 1 ? '商品满减券' : '商品直减券'}</span>
        }
      },
      {
        title: '使用期限',
        dataIndex: 'useDate',
        width: 150,

      },
      {
        title: '领取时间',
        dataIndex: 'receiveDate',
        width: 150,
      },
      {
        title: '领取/创建数',
        dataIndex: 'receiveCount',
        width: 150,
        render: (receiveCount, item) => {
          return <span>{receiveCount} / {item.totalIssued}</span>
        }
      },
      {
        title: '使用数',
        width: 150,
        dataIndex: 'useCount',
      },
      {
        title: '用户已用券额',
        width: 150,
        dataIndex: 'usedCouponMoney',
      },
      {
        title: '预估佣金',
        width: 150,
        dataIndex: 'reckonCommission',
      },
      {
        title: '状态',
        width: 100,
        fixed: 'right',
        dataIndex: 'couponStatus',
        render: (couponStatus) => {
          return <span>{couponStatus == 0 ? '未生效' : couponStatus == 1 ? '生效中' : couponStatus == 2 ? '已作废' : couponStatus == 3 ? '已过期' : '--'}</span>
        }
      },
      {
        title: '操作',
        width: 100,
        fixed: 'right',
        dataIndex: 'id',
        render: (id, item) => {
          return <div className="df jc-sb">
            <span onClick={() => { this.LaunchSet(item) }} className="curs" style={{ color: '#6647ff' }}>详细</span>
            {item.couponStatus == 1 ? <span onClick={() => { this.delRed(id) }} className="curs" style={{ color: '#6647ff' }}>作废</span> : null}
          </div>
        }

      },
    ];
    return (
      <div className="anchorTicket">
        <div className="anchorTicket-title">
          <div className="df jc-sb">
            <p className="fs-16">主播券</p>
            <div>
              <span className="mr-12" style={{ cursor: "pointer" }} onClick={() => { window.open("https://school.jinritemai.com/doudian/web/article/106184") }}>操作指南</span>
              <Button onClick={this.AddAnchorTicket} className='tours_1' style={{ marginRight: 8 }} type="primary">新建主播券</Button>
            </div>
          </div>
          <div className="df jc-sb ai-c red-sum">
            <div className="available">
              <p>可用红包资金 <QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></p>
              <p className="fs-20 bolds mt-6 mb-6" style={{ color: '#b7c6d7' }}>暂未充值资金</p>
              <Link to="/home-live-redenvelope-management/fundDetails" className="mt-6 curs">资金明细<RightOutlined style={{ color: '#c7bdff' }} /></Link>
            </div>
            <div>
              <Button onClick={this.withdrawalFunds} style={{ marginRight: 8 }}>提现</Button>
              <Button onClick={this.rechargeFund} style={{ marginRight: 8 }} type="primary">充值</Button>
            </div>
          </div>
        </div>
        <div className="create-record">
          <p className="fs-16">创建记录</p>
          <div className="df ai-c money-details">
            <div>
              <p df ai-c>领取数量<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></p>
              <p>--</p>
            </div>
            <div>
              <p df ai-c>使用数量<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></p>
              <p>--</p>
            </div>
            <div>
              <p df ai-c>用户已用红包金额<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></p>
              <p>--</p>
            </div>
            <div>
              <p df ai-c>预估佣金收入<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></p>
              <p>--</p>
            </div>
          </div>
          <div className="red-tables df jc-sb ai-c">
            <Form
              name="validate_other"
              ref={this.formRef}
              initialValues={{
                couponStatus: ''
              }}
            >
              <Row>
                <Col span={7}>
                  <Form.Item
                    name='couponName'
                    label='主播券信息'
                  >
                    <Input placeholder="请输入主播券名称/ID" />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    name='type'
                    label='主播券类型'
                  >
                    <Select defaultValue={0} placeholder="">
                      <Option value={0}>全部</Option>
                      <Option value={1}>商品满减券</Option>
                      <Option value={2}>商品直减券</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    name='couponStatus'
                    label='主播券状态'
                  >
                    <Select placeholder="">
                      <Option value={''}>全部</Option>
                      <Option value={0}>未生效</Option>
                      <Option value={1}>生效中</Option>
                      <Option value={2}>已作废</Option>
                      <Option value={3}>已过期</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}></Col>
                <Col span={12}>
                  <Form.Item
                    name='use_time'
                    label='选择日期'
                  >
                    <RangePicker locale={locale} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="text-center">
              <Button onClick={this.getAllLiveCoupon} style={{ marginBottom: 10 }} type="primary">
                查询
              </Button>
              <Button onClick={this.reset}>
                重置
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            locale={this.locale()}
            dataSource={couponData}
            scroll={{ x: 780 }}
            pagination={{
              ...this.paginationProps,
              style: { marginTop: 36, marginBottom: 24 },
              pageNum, pageSize, total
            }} />
          <DyModal handleOk={this.determine} handleCancel={() => { this.setState({ outMedel: false }) }} showIcon={true} modalVisible={outMedel} message="确定永久废除该主播券信息？" />
          <AddAnchorTicket getAllLiveCoupon={this.getAllLiveCoupon} setReactours={this.setReactours} onRef={(ref) => { this.AddAnchorTickets = ref }} />
          <RechargeFund setReactours={this.setReactours} onRef={(ref) => { this.RechargeFunds = ref }} />
          <WithdrawalFunds setReactours={this.setReactours} onRef={(ref) => { this.WithdrawalFundss = ref }} />
        </div>
      </div>
    )
  }
}
export default connect((state) => state, { reactours: (value) => reactours(value) })(AnchorTicket)