import React from 'react';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import './index.css'
import AJAX from "@api/apis";
import moment from 'moment';
import { Link } from 'react-router-dom'
import AddRedEnvelope from './AddRedEnvelope'
import LaunchSet from './launchSet'
import RechargeFund from 'components/rechargeFund'
import WithdrawalFunds from 'components/withdrawalFunds'
import DyModal from "components/DyModal"
import { Table, Button, Form, Row, Col, Input, Select, DatePicker, Progress, message } from 'antd';
import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
const { Option } = Select
const { RangePicker } = DatePicker;


class RedEnvelopes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      redInfo: {},
      redEnvelopeId: null,
      outMedel: false,
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 10
      }
    }
    this.paginationProps = {
      showSizeChanger: true,
      position: ['bottomCenter'],
      pageSizeOptions: [5, 10, 20, 50, 100],
      onShowSizeChange: (current, pageSize) => {
        let page = this.state.page;
        page.pageSize = pageSize;
        this.setState({
          page: page,
          loading: true
        }, () => {
          this.queryRedEnvelopeList()
        })
      },
      onChange: (current) => {
        let page = this.state.page;
        page.pageNum = current;
        this.setState({
          page: page,
          loading: true
        }, () => {
          this.queryRedEnvelopeList()
        })
      },
    }
  }
  formRef = e => {
    this.form = e
  }
  componentDidMount() {
    this.queryRedEnvelopeList();
    this.setReactours()
  }

  queryRedEnvelopeList = () => {
    this.form.validateFields().then(data => {
      let obj = {
        redEnvelopeInfo: '',
        redEnvelopeStatus: '',
        useStartTime: '',
        useEndTime: '',
        launchStartTime: '',
        launchEndTime: '',
        pageNum: this.state.page.pageNum,
        pageSize: this.state.page.pageSize
      }
      if (data.redEnvelopeInfo) obj.redEnvelopeInfo = data.redEnvelopeInfo;
      if (data.redEnvelopeStatus) obj.redEnvelopeStatus = data.redEnvelopeStatus;
      if (data.use_time) {
        obj.useStartTime = moment(data.use_time[0]).format('YYYY-MM-DD')
        obj.useEndTime = moment(data.use_time[1]).format('YYYY-MM-DD')
      }
      if (data.launch_time) {
        obj.launchStartTime = moment(data.launch_time[0]).format('YYYY-MM-DD')
        obj.launchEndTime = moment(data.launch_time[1]).format('YYYY-MM-DD')
      }
      AJAX.redEnvelopes.queryRedEnvelopeList(obj).then(res => {
        if (res.code === 1) {
          this.setState({
            tableData: res.data.list,
            page: {
              ...this.state.page,
              pageSize: res.data.pageSize,
              pageNum: res.data.pageNum,
              total: res.data.total
            }
          })
        }
      })
    })
  }
  reset = () => {
    this.form.setFieldsValue({
      redEnvelopeInfo: '',
      redEnvelopeStatus: 0,
      launch_time: 0,
      use_time: ''
    })
    this.queryRedEnvelopeList()
  }
  setReactours = () => {
    this.props.reactours({
      isTourOpen: true,
      isOpen: true,
      path: "/redenvelopes_list",
      steps: [
        {
          selector: ".tours_1",
          content: '进入新建红包窗口'
        },
      ],
    })
  }
  AddRedEnvelope = () => {
    this.AddRedEnvelopes.showDrawer()
  }
  LaunchSet = (item) => {
    this.setState({
      redInfo: item
    }, () => { this.LaunchSets.showDrawer() })
  }
  locale = () => {
    return {
      emptyText: (
        <div className="ShareCoupons-emptyText RedEnvelopes-emptyText">
          <span>暂无数据</span>
        </div>
      )
    }
  }
  rechargeFund = () => {
    // this.RechargeFunds.showDrawer()
  }
  withdrawalFunds = () => {
    // this.WithdrawalFundss.showDrawer()
  }
  delRed = redEnvelopeId => {
    this.setState({
      redEnvelopeId,
      outMedel: true
    })
  }
  determine = () => {
    let { redEnvelopeId } = this.state
    AJAX.redEnvelopes.deleteRedEnvelope({ redEnvelopeId }).then(res => {
      if (res.code == 1) {
        message.success(res.msg)
        this.setState({
          outMedel: false
        })
        this.queryRedEnvelopeList();
      }
    }).catch(() => {
      return false
    })
  }
  render() {
    let { tableData, redInfo, outMedel } = this.state
    let { pageNum, pageSize, total } = this.state.page

    const columns = [
      {
        title: '红包信息',
        dataIndex: 'name',
        width: 250,
        fixed: 'left',
        render: (name, item) => {
          return <div>
            <p>{name}</p>
            <p>ID：{item.redEnvelopeNum}</p>
          </div>
        }
      },
      {
        title: <div>面额/分配<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></div>,
        width: 150,
        dataIndex: 'denominationTypeName',
        render: (denominationTypeName, item) => {
          return <div>
            <p>￥{item.singleDenomination}</p>
            <p>{denominationTypeName}</p>
          </div>
        }
      },
      {
        title: '总金额/总数',
        width: 150,
        dataIndex: 'totalAmount',
        render: (totalAmount, item) => {
          return <span>￥{totalAmount} / {item.totalIssued}个</span>
        }
      },
      {
        title: <div>红包金额<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></div>,
        width: 150,
        dataIndex: 'useAmount',
        render: (useAmount, item) => {
          return <div>
            <p>领取：￥{useAmount}</p>
            <p>使用：￥{item.receiveAmount}</p>
            <Progress showInfo={false} percent={(item.receiveAmount / useAmount) * 100} size="small" />
          </div>
        }
        // 使用，领取
      },
      {
        title: <div>红包数<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></div>,
        width: 150,
        dataIndex: 'useRedEnvelopeNum',
        render: (useRedEnvelopeNum, item) => {
          return <div>
            <p>领取：{useRedEnvelopeNum}</p>
            <p>使用：{item.receiveRedEnvelopeNum}</p>
          </div>
        }
        // 使用，领取
      },
      {
        title: <div>投放信息<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></div>,
        width: 200,
        dataIndex: 'launchStatusName',
        render: (launchStatusName, item) => {
          return <div className="launch_info">
            <p className="launchStatus">{launchStatusName}</p>
            <p><span className="yu">预</span>{!item.preheatTime ? '--' : item.preheatTime}</p>
            <p><span className="qiang">抢</span>{!item.preheatTime ? '--' : item.receiveStartTime}</p>
          </div>
        }
      },
      {
        title: '使用有效时间',
        width: 200,
        dataIndex: 'useEffectiveTimeNum',
        render: (useEffectiveTimeNum, item) => {
          return <div>
            {useEffectiveTimeNum ? <span>领取后{useEffectiveTimeNum}小时内有效</span> : <div>
              <p>{item.startTime}至</p>
              <p>{item.endTime}</p>
            </div>}
          </div>
        }
      },
      {
        title: '预计佣金',
        width: 150,
        dataIndex: 'estimateCommission',
        render: (estimateCommission, item) => {
          return estimateCommission ? <span>￥{estimateCommission}</span> : '--'
        }

      },
      {
        title: '状态',
        fixed: 'right',
        width: 100,
        dataIndex: 'redEnvelopeStatus',
        render: (redEnvelopeStatus, item) => {
          return <span>{redEnvelopeStatus == 1 ? '未生效' : redEnvelopeStatus == 2 ? '生效中' : redEnvelopeStatus == 3 ? '已作废' : '已过期'}</span>
        }
      },
      {
        title: '操作',
        width: 100,
        fixed: 'right',
        dataIndex: 'redEnvelopeId',
        render: (redEnvelopeId, item) => {
          return <div>
            {item.redEnvelopeStatus == 1 ? <span onClick={() => { this.LaunchSet(item) }} className="curs" style={{ color: '#6647ff' }}>投放</span> : null}
            {item.redEnvelopeStatus == 2 ? <span onClick={() => { this.delRed(redEnvelopeId) }} className="curs" style={{ color: '#6647ff' }}>作废</span> : null}
          </div>
        }

      },
    ];
    return (
      <div className="RedEnvelopes">
        <div className="RedEnvelopes-title">
          <div className="df jc-sb">
            <p className="fs-16">红包</p>
            <div>
              <span className="mr-12">操作指南</span>
              <Button onClick={this.AddRedEnvelope} style={{ marginRight: 8 }} type="primary" className='tours_1'>新建红包</Button>
            </div>
          </div>
          <div className="df jc-sb ai-c red-sum">
            <div className="available">
              <p>可用红包资金 <QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></p>
              <p className="fs-20 bolds mt-6 mb-6" style={{ color: '#b7c6d7' }}>暂未充值资金</p>
              <Link to="/home-live-redenvelope-management/fundDetails" className="mt-6 curs">资金明细<RightOutlined style={{ color: '#c7bdff' }} /></Link>
            </div>
            <div>
              <Button onClick={this.withdrawalFunds} style={{ marginRight: 8 }}>提现</Button>
              <Button onClick={this.rechargeFund} style={{ marginRight: 8 }} type="primary">充值</Button>
            </div>
          </div>
        </div>
        <div className="create-record">
          <p className="fs-16">创建记录</p>
          <div className="df ai-c money-details">
            <div>
              <p df ai-c>领取数量<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></p>
              <p>--</p>
            </div>
            <div>
              <p df ai-c>使用数量<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></p>
              <p>--</p>
            </div>
            <div>
              <p df ai-c>用户已用红包金额<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></p>
              <p>--</p>
            </div>
            <div>
              <p df ai-c>预估佣金收入<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></p>
              <p>--</p>
            </div>
          </div>
          <div className="red-tables df jc-sb ai-c">
            <Form
              name="validate_other"
              ref={this.formRef}
            >
              <Row>
                <Col span={12}>
                  <Form.Item
                    name='redEnvelopeInfo'
                    label='红包信息'
                  >
                    <Input placeholder="请输入红包名称/ID" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='redEnvelopeStatus'
                    label='红包状态'
                  >
                    <Select placeholder="">
                      <Option value={1}>未生效</Option>
                      <Option value={2}>生效中</Option>
                      <Option value={3}>已作废</Option>
                      <Option value={4}>已过期</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='use_time'
                    label='使用时间'
                  >
                    <RangePicker locale={locale} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name='launch_time'
                    label='投放时间'
                  >
                    <RangePicker locale={locale} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <div className="text-center">
              <Button onClick={this.queryRedEnvelopeList} style={{ marginBottom: 10 }} type="primary">
                查询
              </Button>
              <Button onClick={this.reset}>
                重置
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            locale={this.locale()}
            dataSource={tableData}
            scroll={{ x: 780 }}
            pagination={{
              ...this.paginationProps,
              style: { marginTop: 36, marginBottom: 24 },
              pageNum, pageSize, total
            }} />
          <DyModal handleOk={this.determine} handleCancel={() => { this.setState({ outMedel: false }) }} showIcon={true} modalVisible={outMedel} message="确定永久删除该红包信息？" />
          <AddRedEnvelope queryRedEnvelopeList={this.queryRedEnvelopeList} setReactours={this.setReactours} onRef={(ref) => { this.AddRedEnvelopes = ref }} />
          <LaunchSet queryRedEnvelopeList={this.queryRedEnvelopeList} setReactours={this.setReactours} onRef={(ref) => { this.LaunchSets = ref }} redInfo={redInfo} />
          <RechargeFund setReactours={this.setReactours} onRef={(ref) => { this.RechargeFunds = ref }} />
          <WithdrawalFunds setReactours={this.setReactours} onRef={(ref) => { this.WithdrawalFundss = ref }} />
        </div>
      </div>
    )
  }
}
export default connect((state) => state, { reactours: (value) => reactours(value) })(RedEnvelopes)