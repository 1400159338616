import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from "utils/scrollToTop";
import store from "store";
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import ErrorBoundary from "./utils/ErrorBoundary";

import "./common.css";


ReactDOM.render(
  <ErrorBoundary>
    {/* <React.StrictMode> */}

    <BrowserRouter>
      <Provider store={store}>
        <ScrollToTop />
        <ConfigProvider locale={zhCN} >
          <App />
        </ConfigProvider>
      </Provider>
    </BrowserRouter>
    {/* </React.StrictMode> */}
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
