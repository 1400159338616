
export default (state = {}, action) => {
    if (action.type === 'SET_USER_INFO') {
        let userInfo = action.userInfo
        return {
            ...state,
            ...userInfo
        }
    }
    return state
    
}