import React from 'react';
import { Button, Form, Input, Radio, Select, Table, Modal, InputNumber } from 'antd';
import DyDrawer from 'components/DyDrawer';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import AJAX from "api/apis";

const { Option } = Select;

class SetSeckill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      SKUlist: [],
      SKUvisible: false,
      SKUvalue: [],
      SKUListvalue: [],
      batchForm: {
        activityInventory: null,
        limitNumber: 1,
        seckillPrice: null
      }
    };
    this.form = null
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  /** ====  接口APi ======= */
  getGoodsAttrsInfo = () => {

    AJAX.goodsBasicsInfo.getGoodsAttrsInfo({ goodsId: this.props.goodsId }).then(res => {
      let list = res.data
      list.map(item => {
        item.activityInventory = null
        item.limitNumber = 1
        item.seckillPrice = null
        item.seckillSku = item.goodsSku
      })
      this.setState({
        SKUlist: list
      })
    })
  }
  addSeckill = (values) => {
    let query = {
      activityTime: values.activityTime,
      goodsId: this.props.goodsId,
      orderCancel: values.orderCancel,
      seckillName: this.props.goodsValue.goodsName,
      seckillRange: 2,
      type: 1,
      seckillGoods: []
    }
    let seckillGoods = this.state.SKUvalue.map(item => {
      let val = {}
      val.activityInventory = item.activityInventory
      val.limitNumber = item.limitNumber
      val.seckillPrice = item.seckillPrice
      val.seckillSku = item.seckillSku
      return val
    })
    query.seckillGoods = seckillGoods
    AJAX.homeLive.addSeckill(query).then(res => {
      this.onClose()
      this.props.queryAllByLimit()
    })
  }
  /** ====  接口APi--end ======= */

  showDrawer = () => {
    this.setState({
      visible: true,
      batchForm: {
        activityInventory: null,
        limitNumber: 1,
        seckillPrice: null
      }
    });
    this.getGoodsAttrsInfo()
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        steps: [
          {
            selector: '.commodity-lately',
            content: '选择商品',
          },
        ],
        isOpen: false,
        path: "setSeckill"
      })
    }, 500);
  };

  onClose = () => {
    this.setState({
      visible: false,
      SKUvalue: [],
      SKUListvalue: [],
    });
    this.props.setReactours()
  };
  onConfirm = () => {
    if (this.checkTbale()) return
    this.refs.form.validateFields().then(values => {
      // console.log(values)
      this.addSeckill(values)
    })
  };
  checkTbale = () => {
    let SKUvalue = JSON.parse(JSON.stringify(this.state.SKUvalue))
    let is = false
    SKUvalue.forEach(item => {
      if (!item.activityInventory) {
        item.activityInventoryErr = "活动库存不能为空"

        is = true
      } else {
        if (item.activityInventory > item.inventory) {
          item.activityInventoryErr = "活动库存不能大于当前库存"
          is = true
        } else {
          item.activityInventoryErr = ""
        }
      }
      if (!item.seckillPrice) {
        item.seckillPriceErr = "秒杀价不能为空"
        is = true
      } else {
        if (item.seckillPrice > item.price) {
          item.seckillPriceErr = "秒杀价不能大于当前价格"
          is = true
        } else {
          item.seckillPriceErr = ""
        }
      }
    })

    this.setState({
      SKUvalue
    })
    return is
  }
  onSeckillPrice = (e, index) => {
    let list = this.state.SKUvalue
    let val = e.target.value
    // console.log(val, isNaN(val))
    if (val) {
      if (!isNaN(val)) {
        list[index].seckillPrice = val
        this.setState({
          list
        })
      }
    } else {
      list[index].seckillPrice = val
      this.setState({
        SKUvalue: list
      })
    }
  }
  onActivityInventory = (e, index) => {
    let list = this.state.SKUvalue
    let val = e.target.value
    // console.log(val, isNaN(val))
    if (val) {
      if (parseInt(val)) {
        list[index].activityInventory = parseInt(val)
        this.setState({
          list
        })
      }
    } else {
      list[index].activityInventory = val
      this.setState({
        SKUvalue: list
      })
    }
  }
  onLimitNumber = (e, index) => {
    let list = this.state.SKUvalue
    let val = e
    list[index].limitNumber = parseInt(val)
    this.setState({
      SKUvalue: list
    })
  }
  delSKUList = (index) => {
    let list = JSON.parse(JSON.stringify(this.state.SKUvalue))
    list.splice(index, 1)
    // console.log(index, list)
    this.setState({
      SKUvalue: list
    })
  }
  columns = () => {
    return [
      {
        title: 'SKU',
        dataIndex: 'goodsAttrsValue',

        render: (text, row, index) => {
          return (
            <div style={{ display: 'flex', fontSize: 12 }} >
              <img style={{ width: 38, height: 38, marginRight: 10 }} src={row.imgUrl}></img>
              <div>
                {text}<br />{row.tip}
              </div>
            </div>
          )
        }
      },
      {
        title: '当前售价',
        dataIndex: 'price',
        with: "10%",
      },
      {
        title: '秒杀价',
        dataIndex: 'seckillPrice',
        render: (text, row, index) => {
          return <div style={{ position: "relative" }}>
            <Input value={text} onChange={(e) => {
              this.onSeckillPrice(e, index)
            }} prefix="￥" placeholder="需要小于原价" className={row.seckillPriceErr && "table_err_input"} />
            {/* {row.seckillPriceErr && <span className="table_err">{row.seckillPriceErr}</span>} */}
          </div>
        }
      },
      {
        title: '当前库存',
        dataIndex: 'inventory',
        with: 200
      },
      {
        title: '活动库存',
        dataIndex: 'activityInventory',
        render: (text, row, index) => {
          return (
            <div style={{ position: "relative" }}>
              <Input value={text} onChange={e => {
                this.onActivityInventory(e, index)
              }} placeholder="勿大于当前库存" className={row.activityInventoryErr && "table_err_input"} />
              {/* {row.activityInventoryErr && <span className="table_err">{row.activityInventoryErr}</span>} */}
            </div>
          )

        }
      },
      {
        title: '每人限购',
        dataIndex: 'limitNumber',
        render: (text, row, index) => {
          return (
            <Select value={text} onChange={e => {
              this.onLimitNumber(e, index)
            }} defaultValue="1" style={{ width: 50 }}>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          )
        }
      },
      {
        title: '操作',
        dataIndex: 'quantity',
        render: (text, row, index) => {
          return <Button onClick={() => { this.delSKUList(index) }}>删除</Button>
        }
      },
    ]
  }
  SKUcolumns = () => {
    return [
      {
        title: 'SKU',
        dataIndex: 'name',
        render: (text, row, index) => {
          return (
            <div style={{ display: 'flex', fontSize: 12 }} >
              <img style={{ width: 38, height: 38, marginRight: 10 }} src={row.imgUrl}></img>
              <div>
                {row.goodsAttrsValue && row.goodsAttrsValue.join(",")}<br />{row.tip}
              </div>
            </div>
          )
        }
      },
      {
        title: '当前售价',
        dataIndex: 'price',
      },
      {
        title: '当前库存',
        dataIndex: 'inventory',
      },
    ]
  }
  locale = (type) => {
    if (type) {
      return {
        emptyText: (
          <div>
            <img src={require("@assets/images/homeLive/send-coupons-bg.png").default} />
            <div style={{ color: "#111", marginBottom: 20 }} >暂无可添加的SKU</div>
          </div>
        )
      }
    } else {
      return {
        emptyText: (
          <div>
            <img src={require("@assets/images/homeLive/send-coupons-bg.png").default} />
            <div style={{ color: "#111", marginBottom: 20 }} >尚未开播，可提前添加优惠券</div>
            <p>直播间可以同时发多批次优惠券啦，上限10个批次，每次发券默认每用户发1张</p>
          </div>
        )
      }
    }
  }
  handleOk = () => {
    this.setState({ SKUvisible: false, SKUvalue: this.state.SKUListvalue })
  }
  onCancel = () => {
    this.setState({ SKUvisible: false, SKUvalue: this.state.SKUvalue })
  }
  onBatch = () => {
    let list = this.state.SKUvalue
    let values = this.state.batchForm
    list = list.map(item => {
      item.activityInventory = values.activityInventory
      item.limitNumber = values.limitNumber
      item.seckillPrice = values.seckillPrice
      return item
    })
    this.setState({
      SKUvalue: list
    })
  }
  onbatchprice = (e) => {
    let batchForm = this.state.batchForm
    let val = e.target.value
    if (val) {
      if (!isNaN(val)) {
        batchForm.seckillPrice = val
        this.setState({
          batchForm
        })
      }
    } else {
      batchForm.seckillPrice = val
      this.setState({
        batchForm
      })
    }
  }
  onbatchInventory = (e) => {
    let batchForm = this.state.batchForm
    let val = e.target.value
    if (val) {
      if (parseInt(val)) {
        batchForm.activityInventory = parseInt(val)
        this.setState({
          batchForm
        })
      }
    } else {
      batchForm.activityInventory = val
      this.setState({
        batchForm
      })
    }
  }
  onbatcLimitNumber = (e) => {
    let list = this.state.batchForm
    let val = e
    list.limitNumber = parseInt(val)
    this.setState({
      batchForm: list
    })
  }
  render() {
    const { visible, SKUlist, SKUvisible, SKUvalue, batchForm, SKUListvalue } = this.state
    const { goodsValue } = this.props
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({ SKUListvalue: selectedRows })
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    return (
      <DyDrawer
        title="设置秒杀"
        placement="right"
        visible={visible}
        showFooter={true}
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        className="setSeckill"
        confirmText="开始秒杀"
        width="990px"
        destroyOnClose={true}
      >
        <div className="setSeckill-header">
          <img src={goodsValue?.imgUrl}></img>
          <div>
            <div>{goodsValue?.goodsName}</div>
            <p>ID：{goodsValue?.goodsNumber}</p>
          </div>
        </div>
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 20 }}
          ref="form"
        >
          <Form.Item label="活动类型" name="type" initialValue="1">
            <Radio.Group size="large">
              <Radio.Button value="1">
                <div className="setSeckill-Radio" >
                  <div>限时限量</div>
                  <p>在规定时间内对一定数星的商品进行降价销售，限定数星的商品售完或活动时间结束都会恢复原价</p>
                </div>
              </Radio.Button>
              <Radio.Button disabled value="2">
                <div className="setSeckill-Radio">
                  <div>只限时</div>
                  <p>在规定时间内对商品进行降价销售,活动可售库存的上限为该商品的总库存，活动时间结束后恢复原价</p>
                </div>
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <div>

            <Form.Item label="活动时间" initialValue="10">
              <span className="ant-form-text"> 活动持续</span>
              <Form.Item name="activityTime" initialValue="10" noStyle>
                <Select defaultValue="10" style={{ width: 100, margin: "0 10px" }}>
                  <Option value="5">5分钟</Option>
                  <Option value="10">10分钟</Option>
                </Select>
              </Form.Item>
              <span className="ant-form-text"> 后结束(创建成功后，活动立即生效)</span>
            </Form.Item>

          </div>

          <Form.Item label="订单取消时间" name="orderCancel" initialValue="5">
            <Select defaultValue="5" style={{ width: 200, margin: "0 10px" }}>
              <Option value="5">5分钟（推荐）</Option>
              <Option value="10">10分钟</Option>
            </Select>
          </Form.Item>
        </Form>
        <div className="setSeckill-table" >
          <div className="setSeckill-table-header" >
            <div>
              <span style={{ marginRight: 20 }} >配置范围：</span>
              <Radio.Group defaultValue="b" size="large">
                <Radio value="a" disabled>商品</Radio>
                <Radio value="b">SKU</Radio>
              </Radio.Group>
            </div>
            <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
              <div>
                <Input onChange={this.onbatchprice} value={batchForm.seckillPrice} style={{ width: 250 }} prefix="￥" placeholder="批量输入秒杀价,需小于原价" />
                <Input onChange={this.onbatchInventory} value={batchForm.activityInventory} style={{ width: 250, margin: "0 10px" }} placeholder="批量输入活动库存，勿大于当前库存" />
                <Select onChange={this.onbatcLimitNumber} value={batchForm.limitNumber} defaultValue="1" style={{ width: 50 }}>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                </Select>
                <Button type="dashed" onClick={this.onBatch} style={{ marginLeft: "10px" }}>批量填充</Button>
              </div>

              <Button onClick={() => { this.setState({ SKUvisible: true }) }}>添加SKU</Button>
            </div>
          </div>
          <Table
            dataSource={SKUvalue}
            columns={this.columns()}
            locale={this.locale()}
            pagination={false}
            rowKey="goodsSku"
            className="setSeckill-table-body"
          />
        </div>
        <Modal
          title="添加SKU"
          visible={SKUvisible}
          onOk={this.handleOk}
          onCancel={this.onCancel}
          className="setSeckill"
          width="860px"
          footer={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              已选{SKUListvalue.length}/{SKUlist.length}
              <div style={{ textAlign: "right" }}>
                <Button onClick={this.handleOk} type="primary">选择</Button>
                <Button onClick={this.onCancel} style={{ marginLeft: 20 }}>取消</Button>
              </div>
            </div>
          }
        >
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            dataSource={SKUlist}
            columns={this.SKUcolumns()}
            locale={this.locale()}
            pagination={false}
            rowKey="goodsSku"
          />
        </Modal>
      </DyDrawer >
    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(SetSeckill)