import React from 'react';
import { DatePicker, Button, Input, Select, Table, Radio, Progress, Row, Col, Form } from 'antd';
import DyDrawer from 'components/DyDrawer';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import AJAX from 'api/apis';
import moment from 'moment';


const { RangePicker } = DatePicker;
const { Option } = Select;

class RedEnvelopes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      list: [],
      redEnvelopeList: [],
      redEnvelopeStatus: ''
    };
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  /** ====   接口-    ========== */
  queryRedEnvelopeList = async (param = {}) => {

    await AJAX.redEnvelopes.queryRedEnvelopeList({ ...param }).then(res => {
      // console.log(res)
      this.setState({
        redEnvelopeList: res.data.list
      })
    })
    this.setState({ tableLoading: false })
  }

  /** ====   接口-end    ========== */
  showDrawer = () => {
    this.setState({
      visible: true,
    });
    // this.queryRedEnvelopeList()
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        steps: [
          {
            selector: '.commodity-lately',
            content: '选择商品',
          },
        ],
        isOpen: false,
        path: "ShareCoupons"
      })
    }, 500);
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.setReactours()
  };
  onConfirm = () => {
    this.setState({
      visible: false,
    });
    this.props.setReactours()
  };
  columns = () => {
    return [
      {
        title: '红包信息',
        dataIndex: 'name',
        width: 250,
        fixed: 'left',
        render: (name, item) => {
          return <div>
            <p>{name}</p>
            <p>ID：{item.redEnvelopeNum}</p>
          </div>
        }
      },
      {
        title: <div>面额/分配<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></div>,
        width: 150,
        dataIndex: 'denominationTypeName',
        render: (denominationTypeName, item) => {
          return <div>
            <p>￥{item.singleDenomination}</p>
            <p>{denominationTypeName}</p>
          </div>
        }
      },
      {
        title: '总金额/总数',
        width: 150,
        dataIndex: 'totalAmount',
        render: (totalAmount, item) => {
          return <span>￥{totalAmount} / {item.totalIssued}个</span>
        }
      },
      {
        title: <div>红包金额<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></div>,
        width: 150,
        dataIndex: 'useAmount',
        render: (useAmount, item) => {
          return <div>
            <p>领取：￥{useAmount}</p>
            <p>使用：￥{item.receiveAmount}</p>
            <Progress showInfo={false} percent={(item.receiveAmount / useAmount) * 100} size="small" />
          </div>
        }
        // 使用，领取
      },
      {
        title: <div>红包数<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></div>,
        width: 150,
        dataIndex: 'useRedEnvelopeNum',
        render: (useRedEnvelopeNum, item) => {
          return <div>
            <p>领取：{useRedEnvelopeNum}</p>
            <p>使用：{item.receiveRedEnvelopeNum}</p>
          </div>
        }
        // 使用，领取
      },
      {
        title: <div>投放信息<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></div>,
        width: 150,
        dataIndex: 'launchStatus',
        render: (launchStatus, item) => {
          return <div className="launch_info">
            <p className="launchStatus">{launchStatus == 1 ? '未投放' : launchStatus == 2 ? '投放中' : launchStatus == 3 ? '投放完成' : '投放终止'}</p>
            <p><span className="yu">预</span>{launchStatus == 1 ? '--' : item.preheatTime}</p>
            <p><span className="qiang">抢</span>{launchStatus == 1 ? '--' : item.receiveStartTime}</p>
          </div>
        }
      },
      {
        title: '使用有效时间',
        width: 150,
        dataIndex: 'useEffectiveTimeNum',
        render: (useEffectiveTimeNum, item) => {
          return <div>
            {useEffectiveTimeNum ? <span>领取后{useEffectiveTimeNum}小时内有效</span> : <div>
              <p>{item.startTime}至</p>
              <p>{item.endTime}</p>

            </div>}
          </div>
        }
      },
      {
        title: '预计佣金',
        width: 150,
        dataIndex: 'estimateCommission',
        render: (estimateCommission, item) => {
          return <span>￥{estimateCommission}</span>
        }

      },
      {
        title: '状态',
        fixed: 'right',
        width: 100,
        dataIndex: 'redEnvelopeStatus',
        render: (redEnvelopeStatus, item) => {
          return <span>{redEnvelopeStatus == 1 ? '未生效' : redEnvelopeStatus == 2 ? '生效中' : redEnvelopeStatus == 3 ? '已作废' : '已过期'}</span>
        }
      },
      {
        title: '操作',
        width: 100,
        fixed: 'right',
        dataIndex: 'redEnvelopeId',
        render: (redEnvelopeId, item) => {
          return <div>
            {item.redEnvelopeStatus == 1 ? <span onClick={() => { this.props.LaunchSet(item) }} className="curs" style={{ color: '#6647ff' }}>投放</span> : null}
            {item.redEnvelopeStatus == 2 ? <span className="curs" style={{ color: '#6647ff' }}>作废</span> : null}
          </div>
        }

      },
    ]
  }
  locale = () => {
    return {
      emptyText: (
        <div className="ShareCoupons-emptyText RedEnvelopes-emptyText">
          <span>暂无数据</span>
        </div>
      )
    }
  }
  onRedEnvelopes = () => {
    this.props.onRedEnvelopes()
  }
  formRef = e => {
    this.form = e
  }
  onChange = (e) => {
    this.setState({ redEnvelopeStatus: e.target.value })
    this.props.redEnvelopeStatus(e.target.value)
  }
  tableOnchange = (e) => {
    this.props.tableOnchange({
      pageNum: e.current,
      pageSize: e.pageSize,
      redEnvelopeStatus: this.state.redEnvelopeStatus
    })
  }
  searchRed = () => {
    this.form.validateFields().then(value => {
      // console.log(value)
      let obj = {
        redEnvelopeInfo: '',
        redEnvelopeStatus: this.state.redEnvelopeStatus,
        useStartTime: '',
        useEndTime: '',
        launchStartTime: '',
        launchEndTime: '',
      }
      if (value.redEnvelopeInfo) obj.redEnvelopeInfo = value.redEnvelopeInfo;
      if (value.use_time) {
        obj.useStartTime = moment(value.use_time[0]).format('YYYY-MM-DD')
        obj.useEndTime = moment(value.use_time[1]).format('YYYY-MM-DD')
      }
      if (value.launch_time) {
        obj.launchStartTime = moment(value.launch_time[0]).format('YYYY-MM-DD')
        obj.launchEndTime = moment(value.launch_time[1]).format('YYYY-MM-DD')
      }
      this.props.tableOnchange(obj)
    })
  }
  render() {
    const { visible } = this.state
    const { redEnvelopeList, redTable } = this.props
    return (
      <DyDrawer
        title="发红包"
        placement="right"
        closable={false}
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        visible={visible}
        showFooter={false}
        className="ShareCoupons RedEnvelopes"
        width="996px"
      >
        <div className="ShareCoupons-form">
          {/* <div className="ShareCoupons-form-right">
            <div style={{ marginBottom: "20px" }}>
              <span>红包信息</span><Input style={{ width: 280, margin: "0 20px" }} placeholder="请输入红包名称/ID" />
              <span>使用时间</span><RangePicker placeholder={["开始时间", "结束时间"]} style={{ width: 280, margin: "0 20px" }} />
            </div>
            <div>
              <span>投放时间</span><RangePicker placeholder={["开始时间", "结束时间"]} style={{ width: 280, margin: "0 20px" }} />
            </div>
          </div> */}
          <Form
            name="validate_other"
            ref={this.formRef}
          >
            <Row>
              <Col span={11} >
                <Form.Item
                  name='redEnvelopeInfo'
                  label='红包信息'
                >
                  <Input placeholder="请输入红包名称/ID" />
                </Form.Item>
              </Col>

              <Col span={11} offset={1}>
                <Form.Item
                  name='use_time'
                  label='使用时间'
                >
                  <RangePicker />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='launch_time'
                  label='投放时间'
                >
                  <RangePicker style={{ width: 315 }} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className="ShareCoupons-form-left">
            <Button type="primary" onClick={this.searchRed}>查询</Button>
            <Button onClick={() => { this.form.resetFields(); this.props.tableOnchange() }}>重置</Button>
          </div>
        </div>
        <div className="ShareCoupons-linkButton">
          <Radio.Group defaultValue="" onChange={this.onChange}>
            <Radio.Button value="">全部</Radio.Button>
            <Radio.Button value="1">未生效</Radio.Button>
            <Radio.Button value="2">生效中</Radio.Button>
          </Radio.Group>
          <Button style={{ marginLeft: 10 }} type="primary" onClick={this.onRedEnvelopes} >新建红包</Button>
        </div>
        <Table
          columns={this.columns()}
          onChange={this.tableOnchange}
          locale={this.locale()}
          rowKey="redEnvelopeId"
          scroll={{ x: 780 }}
          dataSource={redEnvelopeList}
          pagination={{
            total: redTable.total,
            current: redTable.pageNum,
            pageSize: redTable.pageSize
          }}
        />

      </DyDrawer>
    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(RedEnvelopes)