import React from 'react';
import moment from 'moment';
import { Button, Form, Input, Tabs, Select, Table, Upload, message, Radio, Modal, Alert, DatePicker } from 'antd';
import DyDrawer from 'components/DyDrawer';
import { LoadingOutlined, PlusOutlined, ExclamationCircleFilled, LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import AJAX from "@api/apis";
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import { number } from 'echarts';
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { TabPane } = Tabs;
const hourList = [
  { value: 1, label: '1小时' },
  { value: 2, label: '2小时' },
  { value: 3, label: '3小时' },
  { value: 4, label: '4小时' },
  { value: 5, label: '5小时' },
  { value: 6, label: '6小时' },
  { value: 7, label: '7小时' },
  { value: 8, label: '8小时' },
  { value: 9, label: '9小时' },
  { value: 10, label: '10小时' },
  { value: 11, label: '11小时' },
  { value: 12, label: '12小时' }
]
class AddRedEnvelope extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      imageUrl: [],
      imgLoading: false,
      red_value: '',
      closeModalVisible: false,
      useEffectiveTimeType_value: 1,
      denominationType_value: 1,
      totalAmount: 0,
      totalIssued: 0,
    };
  }
  formRef = e => {
    this.form = e
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  }

  disabledDateTime() {
    return {
      disabledHours: () => this.range(0, 24).splice(4, 20),
      disabledMinutes: () => this.range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  showDrawer = () => {
    this.setState({
      visible: true,
      red_value: ''
    });
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: true,
        isOpen: true,
        path: "/redenvelopes_add",
        steps: [
          {
            selector: ".RedEnvelopesDrawer .ant-form",
            content: '填写红包信息'
          },
          {
            selector: ".RedEnvelopesDrawer .ant-drawer-footer .ant-btn-primary",
            content: '点击确认添加'
          },
        ],
      })
    }, 500);
  };

  onClose = () => {
    this.setState({
      closeModalVisible: true,
      red_value: '',
      useEffectiveTimeType_value: 1,
      denominationType_value: 1,
      totalAmount: 0,
      totalIssued: 0,
      // visible: false,
    });
    this.props.setReactours()
  };
  onConfirm = () => {
    this.form.validateFields().then(data => {
      let { denominationType, endTime, name, singleDenomination, totalAmount, totalIssued, useEffectiveTimeNum, useEffectiveTimeType } = data
      AJAX.redEnvelopes.addRedEnvelope({
        denominationType,
        endTime: useEffectiveTimeType == 1 ? '' : moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
        name,
        singleDenomination: denominationType == 1 ? singleDenomination : '',
        totalAmount: denominationType == 1 ? '' : totalAmount,
        totalIssued,
        useEffectiveTimeNum: useEffectiveTimeType == 1 ? useEffectiveTimeNum : '',
        useEffectiveTimeType
      }).then(res => {
        if (res.code == 1) {
          message.success(res.msg)
          this.setState({
            visible: false,
            red_value: '',
            useEffectiveTimeType_value: 1,
            denominationType_value: 1,
            totalAmount: 0,
            totalIssued: 0,
          });
          this.props.setReactours()
          this.props.queryRedEnvelopeList()
        }
      })
    })

  };
  closeModalOk = () => {
    this.setState({
      visible: false,
      closeModalVisible: false
    });
  }
  closeModalCancle = () => {
    this.setState({
      visible: false,
      closeModalVisible: false
    });
  }
  redChange = e => {
    this.setState({
      red_value: e.target.value
    })
  }
  singleDenominationChange = e => {
    let { totalIssued } = this.state;
    this.setState({
      singleDenomination: e.target.value,
      totalAmount: Number(e.target.value) * Number(totalIssued)
    })
  }
  totalIssuedChange = e => {
    let { singleDenomination } = this.state;
    this.setState({
      totalIssued: e.target.value,
      totalAmount: Number(e.target.value) * Number(singleDenomination)
    })
  }

  useEffectiveTimeTypeChange = e => { //使用有效时间切换
    if (e.target.value == this.state.useEffectiveTimeType_value) return;
    this.setState({
      useEffectiveTimeType_value: e.target.value
    })
  }
  denominationTypeChange = e => { //面额分配切换
    if (e.target.value == this.state.denominationType_value) return;
    this.setState({
      denominationType_value: e.target.value
    })
  }
  totalAmount = () => {
    return <div className="ant-form-item-explain ant-form-item-explain-error"><div role="alert">总金额不可超过可用资金，若资金不足，请先充值</div></div>
  }

  render() {
    const { visible, red_value, closeModalVisible, useEffectiveTimeType_value, denominationType_value, totalAmount } = this.state
    const drawerTitle = (
      <div onClick={() => { this.setState({ closeModalVisible: true }) }}>
        <LeftOutlined style={{ color: "#6647ff", marginRight: '5px' }} />
        <span className="curs" style={{ color: "#6647ff", marginRight: '10px' }}>返回发红包</span>
        新建红包
      </div>
    )
    return (
      <div>
        <DyDrawer
          title={drawerTitle}
          placement="right"
          visible={visible}
          showFooter={true}
          onClose={this.onClose}
          onConfirm={this.onConfirm}
          className="RedEnvelopesDrawer"
          confirmText="确认添加"
          // footer={this.footer}
          width="996px"
        >
          <div>
            <div className='title-tips'>
              <ExclamationCircleFilled style={{ color: "#6647ff", marginRight: '5px' }} />
              <span>如想了解如何创建，点击</span>
              <span style={{ color: "#6647ff", marginLeft: '10px' }}>查看详情</span>
            </div>
            <Form
              name="validate_other"
              ref={this.formRef}
              initialValues={{
                useEffectiveTimeType: 1,
                denominationType: 1,
                useEffectiveTimeNum: 6
              }}
            >
              <Form.Item name="name" label="红包名称" rules={[{ required: true, message: '请输入红包名称' }]}>
                <Input value={red_value} maxLength="10" style={{ width: 260 }} onChange={this.redChange} suffix={<div>{red_value.length}/10</div>} />
              </Form.Item>
              <Form.Item label="红包类型">
                <span className="ant-form-text">普通红包</span>
              </Form.Item>
              <Form.Item label="领取用户限制">
                <span className="ant-form-text">全部用户可领</span>
              </Form.Item>
              <Form.Item label="领取结束时间">
                <span className="ant-form-text">开抢后20秒</span>
              </Form.Item>
              <Form.Item label="每人限领">
                <span className="ant-form-text">1次</span>
              </Form.Item>
              <Form.Item name="useEffectiveTimeType" label="使用有效时间">
                <Radio.Group onChange={this.useEffectiveTimeTypeChange}>
                  <Radio value={1}>按领取后有效时间设置</Radio>
                  <Radio value={2}>按开始结束时间设置</Radio>
                </Radio.Group>
              </Form.Item>
              {useEffectiveTimeType_value == 1 ? <div className="discount df setCoupons">
                领取后
                <Form.Item name="useEffectiveTimeNum" className="custom-inp mr-6" label="" rules={[{ required: true, message: '请选择有效时期' }]}>
                  <Select options={hourList} ></Select>
                </Form.Item>
                内有效
              </div> : null}
              {useEffectiveTimeType_value == 2 ? <div className="discount setCoupons">
                <div className="mb-12">
                  <span className="wh-span">开始时间</span>
                  <span>开抢时间</span>
                </div>
                <div className="df">
                  <span className="wh-span">结束时间</span>
                  <Form.Item name="endTime" className="custom-inp" label="" rules={[{ required: true, message: '请选择结束时间' }, {
                    validator: (_, value, callback) => {
                      let date = new Date()
                      if (date > value) {
                        callback('使用结束时间需晚于当前时间');
                      }
                      callback();
                    },
                  }]}>
                    <DatePicker disabledDate={this.disabledDate} disabledTime={this.disabledRangeTime} showTime format="YYYY-MM-DD HH:mm:ss" />
                  </Form.Item>
                </div>
              </div> : null}
              <Form.Item name="denominationType" label={<div>面额分配<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></div>}>
                <Radio.Group onChange={this.denominationTypeChange} >
                  <Radio value={1}>固定面额</Radio>
                  <Radio value={2}>随机面额</Radio>
                </Radio.Group>
              </Form.Item>
              {denominationType_value == 1 ? <Form.Item name="singleDenomination" label="单个面额" rules={[
                { required: true, message: '请输入单个面额' },
                {
                  validator: (_, value, callback) => {
                    // console.log(value < 0.01, value,typeof Number(value) == "number")
                    if (value && typeof Number(value) == "number") {
                      if (value > 100000) {
                        callback("请输入0.01至10万的数字，支持小数点后2位");
                      }
                      if (Number(value) < 0.01) {
                        callback("请输入0.01至10万的数字，支持小数点后2位");
                      }
                      if (Number(value) == 0) {
                        callback("请输入0.01至10万的数字，支持小数点后2位");
                      }
                    }
                    // if (value < 0.01) {
                    //   callback("请输入0.01至10万的数字，支持小数点后2位");
                    // }
                    callback();
                  },
                }
              ]} >
                <Input type="number" min={0.1} prefix="￥" onChange={e => { this.singleDenominationChange(e) }} />
              </Form.Item> : null}
              {denominationType_value == 2 ? <Form.Item name="totalAmount" label="总金额" rules={[
                { required: true, message: '请输入总金额' },
                {
                  validator: (_, value, callback) => {
                    // console.log(value, Number(value),Number(value) === 0)
                    if (value) {
                      if (value > 100000) {
                        callback("请输入1至10万的整数");
                      }
                      if (value < 1) {
                        callback("请输入1至10万的整数");
                      }
                    }
                    if (value < 1 && value == "0") {
                      callback("请输入1至10万的整数");
                    }
                    callback();
                  },
                }
              ]} extra={<div>可用资金<QuestionCircleOutlined style={{ color: '#ccc', margin: '0 2px' }} />￥0.00</div>} >
                <Input type="number" min={0.1} prefix="￥" />
              </Form.Item> : null}
              <Form.Item name="totalIssued" label="发放总量" rules={[
                { required: true, message: '请输入发放总量' },
                {
                  validator: (_, value, callback) => {
                    // console.log(value, Number(value),Number(value) === 0)
                    if (value) {
                      if (value > 100000) {
                        callback("请输入1至10万的整数");
                      }
                      if (value < 1) {
                        callback("请输入1至10万的整数");
                      }
                    }
                    if (value < 1 && value == "0") {
                      callback("请输入1至10万的整数");
                    }
                    callback();
                  },
                }
              ]}>
                <Input type="number" min={1} suffix="个" onChange={e => { this.totalIssuedChange(e) }} />
              </Form.Item>
              {denominationType_value == 1 ? <Form.Item className="allMoney" label="总金额">
                {totalAmount ? <p>￥{totalAmount}</p> : null}
                <p className="ant-form-text">输入单张面额，发放总理后，自动计算总金额</p>
                <p className="ant-form-text">可用资金<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} />￥0.00</p>
              </Form.Item> : null}
              {denominationType_value == 2 ? <Form.Item className="allMoney" label="单个金额">
                <p className="ant-form-text">输入总金额，发放总量后，自动计算没人获得的红包金额</p>
                <p className="ant-form-text">用户实际领取面额系统随机产生，可能与平均面额有差异</p>
              </Form.Item> : null}
            </Form>
          </div>
        </DyDrawer >
        <Modal
          visible={closeModalVisible}
          title=""
          closable={false}
          centered={true}
          className="home-live-modal"
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.closeModalOk} style={{ marginRight: 8 }} type="primary">
                确定
              </Button>
              <Button onClick={this.closeModalCancle}>
                取消
              </Button>
            </div>
          }
        >
          <div>
            <Alert message="确认退出新建红包?" type="warning" showIcon />
            <p style={{ color: '#55585c', marginLeft: '35px' }}>退出后不可恢复，确定要取消吗？</p>
          </div>
        </Modal>
      </div>
    )
  }
}
export default connect((state) => state, { reactours: (value) => reactours(value) })(AddRedEnvelope)