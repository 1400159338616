import React from 'react';
import { Button, Form, Input, Tabs, Select, Table, Upload, message } from 'antd';
import AJAX from "@api/apis";
import DyDrawer from 'components/DyDrawer';
import { LoadingOutlined, PlusOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
// import CommoditySelect from './../CommoditySelect'
const { TextArea } = Input;
const { TabPane } = Tabs;

class Appeal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      fileList:[],
      imageUrl: [],
      textAreaValue: '',
      imgLoading: false
    };
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        isOpen: false,
        path: ""
      })
    }, 500);
  };

  onClose = () => {
    this.setState({
      visible: false,
    });

  };
  onConfirm = () => {
    let { imageUrl, textAreaValue } = this.state;
    AJAX.abnormalAccountInfo.addAppealInfo({
      abnormalAccountInfoId: this.props.id,
      cause: textAreaValue,
      imgUrl: imageUrl.join(",")
    }).then(res => {
      if (res.code == 1) {
        message.success(res.msg)
        this.props.queryAllByLimit()
        this.setState({
          visible: false,
        });
      }
    })
  };
  footer = () => {
    return (
      <div className="appeal-footer" >
        <div>
          <span style={{ color: "#b0b2b4" }}>仅支持一次申诉，请谨慎操作，恶意申诉会加重处罚</span>
          <div>
            <Button onClick={this.onConfirm} style={{ marginRight: 8 }} type="primary">
              确定添加
            </Button>
            <Button onClick={this.onClose}>
              关闭
            </Button>
          </div>

        </div>
      </div>
    )
  }
  beforeUpload = (file) => {
    let { imageUrl,fileList } = this.state
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('上传的格式不正确');
      return false;
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error('上传图片大小超过5MB');
      return false;
    }
    AJAX.file.upload(file).then((res) => {
      imageUrl.push(res.data[0].url)
      fileList.push(res.data[0])
      this.setState({
        fileList:fileList,
        imageUrl:imageUrl
      })
    }).catch((err) => {
      console.log(err, "err")
    })
  }
  textAreaChange = (e) => {
    this.setState({
      textAreaValue: e.target.value
    })
  }

  render() {

    const { visible, fileList, imgLoading } = this.state
    const uploadButton = (
      <div>
        {imgLoading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>({fileList.length}/4)</div>
      </div>
    );
    return (
      <DyDrawer
        title="违规申诉"
        placement="right"
        visible={visible}
        showFooter={true}
        onClose={this.onClose}
        className="appeal"
        confirmText="确认添加"
        footer={this.footer}
        width="878px"
      >
        <div>
          <div className='title-tips'>
            <ExclamationCircleFilled style={{ color: "#6647ff", marginRight: '5px' }} />
            <span>请上传包含购物车商品的视频截图</span>
            <span style={{ color: "#6647ff", marginLeft: '15px' }}>查看示例</span>
          </div>
          <div className="avatar-box">
            <p className="fs-18 mt-24 mb-12">申诉图片(必填)</p>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              // showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={(file) => { this.beforeUpload(file); return false; }}
              onChange={this.handleChange}
              fileList={fileList}
            >
              {fileList.length >= 4 ? null : uploadButton}
            </Upload>
          </div>
          <div>
            <p className="fs-18 mt-24 mb-12">违规申诉
              <span className="fs-16" style={{ color: '#9ea0a2' }}>（选填）</span>
            </p>
            <TextArea showCount maxLength={200} rows={4} placeholder="请认真填写申诉理由，完善的理由有助于申诉通过~" onChange={this.textAreaChange} />
          </div>
        </div>
      </DyDrawer >
    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(Appeal)
