import React from 'react';
import { Modal, Button, Alert } from 'antd';
import "./index.css";

/** 
 * props
 * modalVisible， 可以不传，不传可用ref控制
 * handleOk ,可以不传
 * handleCancel ,可以不传
 * showIcon ，是否有感叹号，为true时传title无效
 * title， message，文案
 * okText 确定文案
 * cancelText 取消文案
*/
class DyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }
  showModal = () => {
    this.setState({
      visible: true
    })
  }
  handleOk = async () => {
    this.props.handleOk && await this.props.handleOk()
    this.setState({
      visible: false
    })
  };

  handleCancel = () => {
    this.props.handleCancel && this.props.handleCancel()
    this.setState({
      visible: false
    })
  };
  componentDidMount() {
    // console.log(this.props.visible)
  }
  render() {
    const { visible } = this.state
    const { title, message, showIcon, modalVisible, cancelText, okText } = this.props
    // console.log(modalVisible)
    return (
      <Modal
        visible={typeof (modalVisible) == "undefined" ? visible : modalVisible}
        title={showIcon ? title : ''}
        closable={false}
        className="Dy-modal"
        onCancel={this.handleCancel}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={this.handleOk} style={{ marginRight: 8 }} type="primary">
              {okText || "确定"}

            </Button>
            <Button onClick={this.handleCancel}>
              {cancelText || "取消"}

            </Button>

          </div>
        }
      >
        {
          showIcon ? (
            <Alert message={message} type="warning" showIcon />
          ) : (
            <React.Fragment>
              <h3>{title}</h3>
              <div>{message}</div>
            </React.Fragment>
          )
        }
      </Modal>
    )
  }
}

export default DyModal