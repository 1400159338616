import React from 'react';
import { Button, Form, Input, Tabs, Typography, Table, message } from 'antd';
import DyDrawer from 'components/DyDrawer';
import CommoditySelect from './../CommoditySelect';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import AJAX from "api/apis";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import DyImage from 'components/DyImage';
const { Paragraph } = Typography;
const { TabPane } = Tabs;

class AddCommodity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      list: [
        {
          key: '1',
          name: '无叶挂脖风扇',
          tip: '【雅致白】',
          age: 32,
          address: '12.9',
          condition: "10",
          term: '9999',
          quantity: '1',
          img: "home-live-commodity-list1",
        },
        {
          key: '2',
          name: '无叶挂脖风扇',
          tip: '【少女粉】',
          age: 32,
          address: '12.9',
          condition: "10",
          term: '9999',
          quantity: '1',
          img: "home-live-commodity-list2",
        },
      ],
      checkedList: [],
      checked: "lately",
      goodsUrl: '',
      active: "1",
      addLoading: false,
      getGoodsInfoLoading: false,
    };
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  /** ====  接口APi ======= */
  addLiveRoomGoods = () => {
    let checkedList = this.state.checkedList
    if (checkedList.length <= 0) return
    if (this.state.addLoading) return
    this.setState({ addLoading: true })
    let query = {
      goodsIds: checkedList.join(',')
    }
    AJAX.homeLive.addLiveRoomGoods(query).then(res => {
      // console.log(res)
      this.onClose()
      this.props.queryAllByLimit()
    })
    setTimeout(() => {
      this.setState({ addLoading: false })
    }, 2000);
  }

  /** ====  接口APi-end ======= */
  showDrawer = () => {
    this.setState({
      visible: true,
    });

    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        steps: [
          {
            selector: '.commodity-lately',
            content: '选择商品',
          },
        ],
        isOpen: false,
        path: "addCommodity"
      })
    }, 500);
  };
  columns = () => {
    return [
      {
        title: '商品信息',
        dataIndex: 'goodsName',
        render: (text, row, index) => {
          return (
            <div className="commodityManagement-table-name">
              <DyImage style={{ width: 62, height: 62 }} src={row.imgUrl}></DyImage>
              <div className="commodityManagement-table-name-center">
                <div><Paragraph ellipsis={{ rows: 2, expandable: false }}>{row.goodsName}</Paragraph></div>
                <div></div>
                <div><ExclamationCircleOutlined />新手店铺</div>
              </div>
            </div>
          )
        },
      },
      {
        title: '商品ID',
        dataIndex: 'goodsNumber',
      },
      {
        title: '商品价格',
        dataIndex: 'goodsPrice',
        render: (text) => "￥" + text
      },
      // {
      //   title: '商品来源',
      //   dataIndex: 'goodsSource',
      //   render: (text) => {
      //     return goodsSource(text)
      //   }
      // },
    ]
  }

  onClose = () => {
    this.setState({
      visible: false,
      checkedList: [],
      goodList: [],
      goodsUrl: ''
    });
    this.props.setReactours()
  };
  onConfirm = () => {
    // this.setState({
    //   visible: false,
    // });
    // this.props.setReactours()
    this.addLiveRoomGoods()
  };
  onCheckedList = (list) => {
    this.setState({
      checkedList: list
    })

  }
  goodsUrlOnchang = (e) => {
    this.setState({ goodsUrl: e.target.value })
  }
  //1、40
  getGoodsInfo = () => {
    if (this.state.getGoodsInfoLoading) return
    this.setState({ getGoodsInfoLoading: true })
    let goodsIds = this.state.goodsUrl
    goodsIds = goodsIds.includes(',') ? goodsIds.split(',') : goodsIds.split('\n')
    goodsIds = goodsIds.map(item => {
      return item.split('=')[1]
    })
    goodsIds = goodsIds.filter(function (s) {
      return s && s.trim(); // 注：IE9(不包含IE9)以下的版本没有trim()方法
    });
    goodsIds = goodsIds.join(",")
    // console.log(goodsIds)
    if (goodsIds.length > 0) {
      AJAX.displayWindow.getGoodsDetail({ goodsIds }).then(res => {
        // console.log(res)
        this.setState({
          goodList: res.data,
          checkedList: []
        })
      })
    } else {
      message.warning("获取商品失败：没有搜索到您的商品哦，请检查链接或者更换一个商品吧~")
    }
    setTimeout(() => {
      this.setState({ getGoodsInfoLoading: false })
    }, 2000);

  }
  footer = () => {
    const { checkedList, checked } = this.state
    // console.log(checkedList, checked,checkedList[checked])
    return (
      <div className="addCommodity-footer" >
        <div className="addCommodity-footer-select">
          <span>已选{checkedList.length}/100</span>
        </div>
        <div>
          <span>新加商品将展示在商品列表最前面，<span onClick={() => {
            window.location.href = window.location.origin + "/home-live-set"
          }} style={{ color: "#6647ff" }}>修改添加位詈逻辑</span></span>
          <div>
            <Button onClick={this.onConfirm} style={{ marginRight: 8 }} disabled={checkedList.length <= 0} type="primary">
              确定添加
            </Button>
            <Button onClick={this.onClose}>
              取消
            </Button>
          </div>

        </div>
      </div>
    )
  }
  activeOnchange = (e) => {
    this.setState({
      active: e,
      checkedList: [],
      goodList: [],
      goodsUrl: ''
    })
  }


  render() {
    const { visible, list, checkedList, checked, goodsUrl, active, goodList } = this.state
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({ checkedList: selectedRows.map(item => item.goodsId) })
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    return (
      <DyDrawer
        title="添加商品"
        placement="right"
        visible={visible}
        showFooter={true}
        onClose={this.onClose}
        className="addCommodity"
        confirmText="确认添加"
        footer={this.footer}
        width="828px"
      >
        <Tabs defaultActiveKey="1" onChange={this.activeOnchange} activeKey={active}>
          <TabPane tab="选择商品" key="1">
            <CommoditySelect onCheckedList={this.onCheckedList} checkedList={checkedList} list={list} />
          </TabPane>
          <TabPane tab="商品链接" key="2">
            <Input.TextArea value={goodsUrl} onChange={this.goodsUrlOnchang} rows={6} placeholder='请粘贴商品链接，支持批量粘贴，请用英文逗号 “,” 或换行隔开，每次最多30个' />
            <div className="addCommodity-distinguish">
              <Button type="primary" onClick={this.getGoodsInfo} >识别链接</Button>
              <span>添加商品仍有问题? <span style={{ color: "#6647ff" }}>查看帮助</span></span>
            </div>
            <Table
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              locale={{
                emptyText: "暂无可选商品，可在上方文本框中输入商品链接添加商品"
              }}
              columns={this.columns()}
              dataSource={goodList}
              rowKey="goodsId"
            />
          </TabPane>
        </Tabs>
      </DyDrawer >
    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(AddCommodity)