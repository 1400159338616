import React from 'react';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import './index.css'
import RechargeFund from 'components/rechargeFund'
import WithdrawalFunds from 'components/withdrawalFunds'
import { Table, Button, Form, Row, Col, Input, Select, DatePicker } from 'antd';
import { QuestionCircleOutlined, RightOutlined } from '@ant-design/icons';
const { Option } = Select
const { RangePicker } = DatePicker;


class FundDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  rechargeFund = () => {
    // this.refs.RechargeFund.showDrawer()
  }
  withdrawalFunds = () =>{
    // this.refs.WithdrawalFunds.showDrawer()
  }
  locale = () => {
    return {
      emptyText: (
        <div className="ShareCoupons-emptyText RedEnvelopes-emptyText">
          <span>暂无数据</span>
        </div>
      )
    }
  }
  render() {
    let { data } = this.state
    const columns = [
      {
        title: '操作时间',
        dataIndex: 'name',
      },
      {
        title: '类型/批次',
        dataIndex: 'age',
      },
      {
        title: '金额',
        dataIndex: 'address',
      },
      {
        title: '提现/支付方式',
        dataIndex: 'address',
      },
      {
        title: '状态',
        fixed: 'right',
        dataIndex: 'address',
      }
    ];
    return (
      <div className="fundDetails">
        <div className="fundDetails-title">
          <div className="df jc-sb ai-c red-sum">
            <div className="available">
              <p>可用创券资金 <QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></p>
              <p className="fs-18 bolds mt-6 mb-6">￥0.00</p>
            </div>
            <div>
              <Button onClick={this.withdrawalFunds} style={{ marginRight: 8 }}>提现</Button>
              <Button onClick={this.rechargeFund} style={{ marginRight: 8 }} type="primary">充值</Button>
            </div>
          </div>
        </div>
        <div className="create-record">
          <p className="fs-16">资金明细</p>
          <div className="red-tables">
            <Form
              name="validate_other"
              onFinish={this.onFinish}
            >
              <Row>
                <Col span={8}>
                  <Form.Item
                    name='use-time'
                    label='操作时间'
                  >
                    <RangePicker locale={locale} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name='red-status'
                    label='类型'
                  >
                    <Select defaultValue={0} placeholder="">
                      <Option value={0}>全部</Option>
                      <Option value={1}>商品满减券</Option>
                      <Option value={2}>商品直减券</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <div className="text-center">
                    <Button onClick={this.onConfirm} style={{ marginRight: 10 }} type="primary">
                      查询
                    </Button>
                    <Button onClick={this.onClose}>
                      重置
                    </Button>
                  </div>
                </Col>

              </Row>
            </Form>
          </div>
          <Table columns={columns} locale={this.locale()} dataSource={data} />
          <RechargeFund ref="RechargeFund" />
          <WithdrawalFunds ref="WithdrawalFunds" />
        </div>
      </div>
    )
  }
}

export default FundDetails