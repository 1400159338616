import React from 'react';
import {
  Badge,
  Avatar,
  Menu,
  Dropdown,
  Typography,
  Modal,
  Layout,
  Button,
  Collapse,
  Pagination,
  Tooltip,

} from 'antd';
import {
  DownOutlined,
  RightOutlined,
} from '@ant-design/icons';
import "./index.css";
import DyModal from "components/DyModal";
import container from './container';
import AJAX from "api/apis";


const { Text, Paragraph } = Typography;
const { Content, Sider } = Layout;
const { Panel } = Collapse;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsVisible: false,
      current: '1',
      newsList: [
        {
          title: "站外引流每周处罚公告",
          content: "站外导流第四期治理公告！ 达人朋友一定要避免违规～",
          time: "2021-08-13 18:50",
          key: 1,
        },
        {
          title: "创作者管理总则解读",
          content: "达人创作者的干货来了！在内容创作时，达人应遵守哪些创作原则？应避免哪些违规行为？了解电商创作者管理总则的相关内容，请点击查看",
          time: "2021-08-13 18:50",
          key: 2,
        },
        {
          title: "规则考试中心上线啦",
          content: "作者规则考试中心上线啦！参与并满分通过考试即可获得1分信用分奖励，快来一起学习吧",
          time: "2021-08-13 18:50",
          key: 3,
        },
        {
          title: "站外引流每周处罚公告",
          content: "站外导流第四期治理公告！ 达人朋友一定要避免违规～",
          time: "2021-08-13 18:50",
          key: 4,
        }
      ],
      actionValue: "/inlet-page"
    };
  }
  componentDidMount() {
    this.navAction()
  }
  componentDidUpdate(nextProps) {
    let url = nextProps.history.location.pathname.split("-")
    url = `${url[0]}-${url[1]}`
    // console.log(url)
    if (this.state.actionValue != url) {
      // console.log(nextProps.history.location.pathname, window.location.pathname, this.state.actionValue)
      this.navAction()
    }
  }


  menu = () => {
    const { userInfo } = this.props
    return (
      <Menu>
        <Menu.Item key='1' >
          <div style={{ display: "flex" }}>百应ID <Paragraph style={{ marginLeft: 5 }} copyable={{ tooltips: false }}> {userInfo && userInfo.buyInId}</Paragraph></div>
        </Menu.Item>
        <Menu.Item key='2' onClick={() => {
          this.refs.signOut.showModal()
        }} >
          退出
        </Menu.Item>
      </Menu>
    )
  }
  handleOk = () => {
    this.setState({ newsVisible: false })
  };

  handleCancel = () => {
    this.setState({ newsVisible: false })
  };
  handleClick = e => {
    this.setState({ current: e.key })
  };
  navAction = () => {

    if (window.location.pathname.includes("/inlet-marketing")) {
      this.setState({
        actionValue: '/inlet-marketing'
      })
    }
    if (window.location.pathname.includes("/inlet-page")) {
      this.setState({
        actionValue: '/inlet-page'
      })
    }
  }
  onSignOut = () => {
    window.location.href = window.location.origin
  }
  onLink = (url) => {
    AJAX.score.addUserStepsRecord({ operationSteps: url })
    this.props.history.push(url)
    this.navAction()
  }
  render() {
    const { newsVisible, current, newsList, actionValue } = this.state
    const { userInfo } = this.props
    return (
      <div className="InletHeader" >
        {
          actionValue == '/inlet-page' ? (
            <div className='header-inner'>
              <div className="header-right">
                <div className="logo" >
                  <img src={require("@assets/images/inlet/logo.png").default} ></img>
                </div>
                <ul className="header-nav" >
                  <li >电商罗盘</li>
                  <li className='yingxiaozhongxin' onClick={() => { this.onLink("/inlet-marketing") }}>营销中心</li>
                  <li>巨量千川</li>
                  <li>精选联盟</li>
                  <li>学习中心</li>
                  <li>服务市场</li>
                </ul>
              </div>
              <div className="header-left" >
                <span><img src={require("@assets/images/inlet/header-4.svg").default} ></img>客户端</span>
                <span><img src={require("@assets/images/inlet/header-3.svg").default} ></img>接待</span>
                <Badge count={2}>
                  <img src={require("@assets/images/inlet/header-2.svg").default} ></img>
                  消息
                </Badge>
                <Dropdown overlay={this.menu} placement="bottomLeft" overlayStyle={{ top: 60 }}>
                  <span className="header-left-dropdown" >
                    <img src={require("@assets/images/inlet/av.svg").default} ></img>
                    <span className='name text-ellipsis-1'>{userInfo.liveName ? userInfo.liveName : '513513513513513'}</span>
                    <span className="header-left-dropdown-daren"><DownOutlined /></span>
                  </span>
                </Dropdown>
              </div>
            </div>
          ) : (
            <div className='header-inner marketing'>
              <div className="header-right">
                <div className="logo" >
                  <img src={require("@assets/images/inlet/logo-2.png").default} ></img>
                </div>
                <ul className="header-nav" >
                  <li className='zhibozhongkong' onClick={() => { this.onLink("/home-live") }} >直播中控</li>
                  <li>电商广告</li>
                  <li>
                    <Tooltip overlayClassName="header_tooltip" visible={true} title="合作达人" placement="right" color='#ff4050' trigger="contextMenu">
                      精选联盟
                    </Tooltip>
                  </li>
                </ul>
              </div>
              <div className="header-left">
                <Dropdown overlay={this.menu} placement="bottomLeft" overlayStyle={{ top: 60 }}>
                  <span className="header-left-dropdown" >
                    <Avatar src={userInfo.photo ? userInfo.photo : require("@assets/images/homeLive/home-Avatar.jpeg").default} />
                    <span className='name'>{userInfo.liveName ? userInfo.liveName : '513'}</span>
                    <span className="header-left-dropdown-daren"><DownOutlined /></span>
                  </span>
                </Dropdown>
              </div>
            </div>
          )
        }

        <Modal
          title="Basic Modal"
          className="newsModal"
          footer={false}
          visible={newsVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="860px"
        >
          <Layout>
            <Sider style={{ width: 120 }} width="160px">
              <Menu
                selectedKeys={current}
                defaultOpenKeys={['sub1', "sub2", "sub3", "sub4", "sub5"]}
                mode="inline"
                expandIcon={false}
                onClick={this.handleClick}
              >
                <Menu.Item key="1">平台规则<span className="newsModal-red-tip">4</span></Menu.Item>
                <Menu.Item key="2">通知功能</Menu.Item>
                <Menu.Item key="3">活动通知</Menu.Item>
                <Menu.Item key="4">合作动态</Menu.Item>
                <Menu.Item key="5">问卷调查</Menu.Item>
              </Menu>
              <Button>全部已读</Button>
            </Sider>
            <Content>
              <Collapse expandIconPosition="right" expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />} >

                {
                  current == "1" && newsList.map(item => {
                    return (
                      <Panel key={item.key} header={item.title} style={{ marginBottom: 10 }} extra={item.time}>
                        <p>{item.content}</p>
                        <div className="newsModal-panel" style={{ textAlign: "right" }}>
                          <Button size="small">立即查看</Button>
                        </div>
                      </Panel>
                    )
                  })
                }
              </Collapse>
              <Pagination defaultCurrent={1} total={50} />
            </Content>
          </Layout>

        </Modal>
        <DyModal handleOk={this.onSignOut} showIcon={true} ref="signOut" message="确定退出登录？" />
      </div>
    )
  }
}

export default container(Header)