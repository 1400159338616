import React from 'react';
import { Layout, Divider, Menu, Checkbox, Input, Empty } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import AJAX from "api/apis";
import DyImage from 'components/DyImage';
const { Content, Sider } = Layout;

class CommoditySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      commodityList: [],
      checkAll: false,
      indeterminate: false,
      checkedList: '',
      commodityAction: "1",
    };
  }
  componentDidMount() {
    this.getSpecialGoods()
  }
  getSpecialGoods = async () => {
    let query = {
      goodsName: ''
    }
    await AJAX.homeLive.getSpecialGoods(query).then(res => {
      console.log(res)
      this.setState({
        commodityList: res.data,
      })
    })
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  onConfirm = () => {
    this.setState({
      visible: false,
    });
  };
  onChange = (list, name) => {
    let commodityList = this.state.commodityList
    let indeterminate = this.state.indeterminate
    let checkAll = this.state.checkAll
    let checkedList = this.state.checkedList
    let ele = commodityList.find(val => val.name == name)
    indeterminate[name] = !!list.length && list.length < ele.data.length
    checkAll[name] = list.length === ele.data.length
    checkedList[name] = list
    this.setState({
      checkAll,
      indeterminate,
      checkedList
    })
    this.props.onCheckedList(checkedList, name)
    // console.log(list, name, ele, commodityList)
  };

  onCheckAllChange = (e, name) => {
    let commodityList = this.state.commodityList
    let indeterminate = this.state.indeterminate
    let checkAll = this.state.checkAll
    let checkedList = this.state.checkedList
    if (commodityList.length <= 0) return
    let ele = commodityList.find(val => val.name == name)
    ele = ele.data.map(item => item.id)
    indeterminate[name] = false
    checkAll[name] = e.target.checked
    checkedList[name] = e.target.checked ? ele : []
    this.setState({
      checkAll,
      indeterminate,
      checkedList
    })
    this.props.onCheckedList(checkedList, name)
    // console.log(e);
  };

  handleClick = e => {
    this.setState({ commodityAction: e.key })
  };
  render() {
    const { commodityList, checkAll, indeterminate, commodityAction, checkedList } = this.state
    return (
      <Layout className="commoditySelect">
        <Sider width="100px">
          <Menu
            onClick={this.handleClick}
            style={{ width: 100 }}
            defaultSelectedKeys={['2']}
            mode="inline"
          >
            {/* <Menu.Item key="1">最近添加</Menu.Item> */}
            <Menu.Item key="2">定向商品 <QuestionCircleOutlined /></Menu.Item>
            {/* <Menu.Item key="3">我的店铺</Menu.Item> */}
            {/* <Menu.Item key="4">定向商品</Menu.Item> */}
          </Menu>
        </Sider>
        <Content>
          <div className="commodity-lately">
            <div className="commodity-lately-header">
              <Checkbox indeterminate={indeterminate} onChange={(e) => { this.onCheckAllChange(e) }} checked={checkAll}>全选</Checkbox>
              <Input.Search placeholder="搜索商品名称或ID" style={{ width: 200 }} />
            </div>
            {

              commodityList.length > 0 ? (
                <Checkbox.Group value={checkedList} onChange={(e) => { this.onChange(e) }}>
                  {
                    commodityList.map(item => {
                      return (
                        <div className="commodity-lately-item" key={item.goodsId}>
                          <Checkbox value={item.goodsId}></Checkbox>
                          <div className="commodity-lately-item-body">
                            <DyImage style={{ width: 62, height: 62 }} src={item.imgUrl}></DyImage>
                            <div className="commodity-lately-body-center">
                              <div>{item.goodsName}</div>
                              <div>新手店铺</div>
                              <div>赚— 佣金率{item.goodsCommissionRate || 0}% <Divider type="vertical" /> 售价：{item.goodsCommission || 0}</div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }

                </Checkbox.Group>
              ) : (
                <Empty
                  image={<img style={{ width: 320, height: 210 }} src={require("@assets/images/homeLive/commodity-empty.png").default} />}
                  description="暂无可添加商品"
                />
              )
            }
          </div>
        </Content>
      </Layout>

    )
  }
}

export default CommoditySelect