
import React from 'react';
import { Tabs, Menu, Dropdown, Button, Table } from 'antd';
import "./LiveSendCouponsTable.css";
import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import SendCouponsModal from "components/SendCouponsModal";
import SendCoupons from './../SendCoupons';
import AJAX from "api/apis";
import DyModal from '@components/DyModal';

const { TabPane } = Tabs;


class LiveSendCouponsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sendVisible: false,
            sendType: "shop",
            couponchValue: null,
            couponsModal: {
                title: "发放优惠券",
                message: "立即向全员发券？"
            },
            tableLoading: false,
            table: {
                current: 1,
                pageSize: 10,
                total: 20
            }
        };
    }
    componentDidMount() {
        this.queryRedEnvelopeList()
    }
    /** ====  接口APi ======= */

    queryRedEnvelopeList = async (param = {}) => {
        this.setState({ tableLoading: true })
        let query = {
            "couponType": 3,
            "pageNum": 1,
            "pageSize": 10,
            ...param
        }
        await AJAX.liveRoomCoupon.getAllCouponList(query).then(res => {
            // console.log(res)
            this.setState({
                couponList: res.data?.list,
                table: {
                    current: res.data.pageNum,
                    pageSize: res.data.pageSize,
                    total: res.data.total
                }
            })
        })
        this.setState({ tableLoading: false })
    }
    launchCoupon = () => {
        let value = this.state.couponchValue
        if (value.launchStatus !== 3) {
            let query = {
                "couponId": value.couponId,
                "type": value.launchStatus === 0 ? 1 : 0
            }
            AJAX.liveRoomCoupon.launchCoupon(query).then(res => {
                this.queryRedEnvelopeList()
            })
        } else {
            this.deleteLiveRoomCoupon(value)
        }

    }
    deleteLiveRoomCoupon = () => {
        let value = this.state.couponchValue
        AJAX.liveRoomCoupon.deleteLiveRoomCoupon({ liveRoomCouponId: value.id }).then(res => {
            this.queryRedEnvelopeList()
        })
    }

    /** ====  接口APi-end ======= */
    callback = (key) => {
        console.log(key);
    }
    menu = () => {
        return (
            <Menu onClick={this.openSendCouponsModal}>
                <Menu.Item key='shop'>店铺优惠券</Menu.Item>
                <Menu.Item key='discount'>优惠券码</Menu.Item>
                <Menu.Item key='directional'>定向券</Menu.Item>
            </Menu>
        )
    }
    openSendCouponsModal = (e) => {
        let isDrawer = window.location.href.includes("/home-live-SendCoupons")
        if (isDrawer) {
            this.setState({
                sendType: e.key,
            }, () => {
                this.SendCoupons.showDrawer()
            })

        } else {
            this.setState({
                sendVisible: true,
                sendType: e.key,
            })
        }

    }
    sendCouponsModalOk = () => {
        this.setState({
            sendVisible: false,
        })
    }
    sendCouponsModalCancel = () => {
        this.setState({
            sendVisible: false,
        })
    }
    columns = (type) => {
        if (type) {
            return [
                {
                    title: '序号',
                    dataIndex: 'id',
                    render: (text, row, index) => {
                        return index + 1
                    }
                },
                {
                    title: '优惠券信息',
                    dataIndex: 'couponName',
                    render: (text, row) => {
                        return (
                            <div>
                                <p>券名：{text}</p>
                                {/* <p>店铺：牛纬服饰</p> */}
                            </div>
                        )
                    }
                },
                {
                    title: '类型',
                    dataIndex: 'type',
                    with: 160,
                    render: (text, row) => {
                        return (
                            <div style={{ whiteSpace: "nowrap" }}>
                                <div>满{row.fillMoney ? row.fillMoney : 0}减{row.discountMoney}</div>
                                <div>{text === 1 ? "商品满减券" : "商品直减券"}</div>
                            </div>
                        )
                    }
                },
                {
                    title: '领取条件',
                    dataIndex: 'receiveDate',
                    render: (text, row) => {
                        return (
                            <div>
                                <p>{text}</p>
                                <p>每人限领{row.limitedCollarNum}张</p>
                            </div>
                        )
                    }
                },
                {
                    title: '使用期限',
                    dataIndex: 'useDate',
                },
                {
                    title: '数量',
                    dataIndex: 'useCount',
                    render: (text, row) => {
                        return text + "/" + row.totalIssued
                    }
                },
                {
                    title: '操作',
                    dataIndex: 'launchStatus',
                    render: (text, row) => {
                        if (text === 3) {
                            return (
                                <div>
                                    <span style={{ cursor: "pointer", color: "#ccc", whiteSpace: "nowrap" }}>
                                        立即发券
                                    </span>
                                    <br />
                                    <span style={{ cursor: "pointer", color: "#6647ff", whiteSpace: "nowrap" }} onClick={() => { this.launchCoupons(row) }}>
                                        {text === 0 ? "立即发券" : text === 1 ? "停止发放" : "移除"}
                                    </span>
                                </div>
                            )
                        }
                        return <span
                            style={{ cursor: "pointer", color: "#6647ff", whiteSpace: "nowrap" }}
                            onClick={() => { this.launchCoupons(row) }}
                        >
                            {text === 0 ? "立即发券" : text === 1 ? "停止发放" : "移除"}
                        </span>
                    }
                },
            ]
        } else {
            return [
                {
                    title: '序号',
                    dataIndex: 'name',
                },
                {
                    title: '发券活动',
                    dataIndex: 'age',
                },
                {
                    title: '发送任务',
                    dataIndex: 'address',
                },
                {
                    title: '发送规则',
                    dataIndex: 'condition',
                },
                {
                    title: '数量',
                    dataIndex: 'quantity',
                },
                {
                    title: '操作',
                    dataIndex: 'launchStatus',
                    render: (text, row) => {
                        if (text === 2) {
                            return (
                                <div>
                                    <span style={{ cursor: "pointer", color: "#ccc", whiteSpace: "nowrap" }}>
                                        立即发券
                                    </span>
                                    <span style={{ cursor: "pointer", color: "#6647ff", whiteSpace: "nowrap" }} onClick={() => { this.launchCoupons(row) }}>
                                        {text === 0 ? "立即发券" : text === 1 ? "停止发放" : "移除"}
                                    </span>
                                </div>
                            )
                        }
                        return <span
                            style={{ cursor: "pointer", color: "#6647ff", whiteSpace: "nowrap" }}
                            onClick={() => { this.launchCoupons(row) }}
                        >
                            {text === 0 ? "立即发券" : text === 1 ? "停止发放" : "移除"}
                        </span>
                    }
                },
            ]
        }

    }
    launchCoupons = (row) => {
        let couponsModal = {
            title: row.launchStatus === 0 ? "发放优惠券" : row.launchStatus === 1 ? "停止发放优惠券" : "移除优惠券",
            message: row.launchStatus === 0 ? "立即向全员发券？" : row.launchStatus === 1 ? "立即停止向全员发券？" : "确定移除吗？",
        }
        this.setState({ couponchValue: row, couponsModal })
        this.refs.launchCoupons.showModal()
    }
    locale = (type) => {
        return {
            emptyText: (
                <div>
                    <img src={require("@assets/images/homeLive/send-coupons-bg.png").default} />
                    {
                        type ? <div style={{ color: "#111", marginBottom: 10 }} >尚未开播，可提前添加优惠券</div>
                            : <div style={{ color: "#111", marginBottom: 20 }} >无平台券，请联系平台运营进行添加</div>
                    }

                    {
                        type == 1 ? <p style={{ color: "#85878A" }} >直播间可以同时发多批次优惠券啦，上限10个批次，每次发券默认每用户发1张</p> :
                            type == 2 ? <p style={{ color: "#85878A" }}>主播券创建路径：【直播管理】-【主播券管理】</p> : null
                    }

                </div>
            )
        }
    }
    tableOnchange = (e) => {
        // console.log(e)
        this.queryRedEnvelopeList({
            pageNum: e.current,
            pageSize: e.pageSize,
        })
    }
    render() {
        const { couponList, sendVisible, sendType, couponsModal, tableLoading, table } = this.state
        return (
            <div className="LiveSendCouponsTable">
                <div className="LiveSendCouponsTable-header">
                    <span onClick={() => { window.open("https://school.jinritemai.com/doudian/web/article/102060") }} style={{ cursor: "pointer" }}>投放说明</span>
                    <Dropdown overlay={this.menu()} placement="bottomLeft" arrow>
                        <Button type="primary">添加<DownOutlined /></Button>
                    </Dropdown>
                </div>
                <Tabs defaultActiveKey="3" onChange={this.callback}>
                    <TabPane tab="店铺券" key="1">
                        <Table
                            dataSource={[]}
                            columns={this.columns(1)}
                            locale={this.locale(1)}
                            rowKey="id"
                            loading={tableLoading}
                        />
                    </TabPane>
                    <TabPane tab="平台卷" key="2">
                        <Table
                            dataSource={[]}
                            columns={this.columns(0)}
                            locale={this.locale()}
                            rowKey="id"
                            loading={tableLoading}
                        />
                    </TabPane>
                    <TabPane tab="主播券" key="3">
                        <Table
                            dataSource={couponList}
                            columns={this.columns(1)}
                            locale={this.locale(2)}
                            rowKey="id"
                            loading={tableLoading}
                            pagination={{
                                total: table.total,
                                current: table.pageNum,
                                pageSize: 10
                            }}
                            onChange={this.tableOnchange}
                        />
                    </TabPane>
                    <TabPane tab={<span>支付券 <QuestionCircleOutlined /></span>} key="4">
                        <Table
                            dataSource={[]}
                            columns={this.columns(0)}
                            locale={this.locale()}
                            rowKey="id"
                            loading={tableLoading}
                        />
                    </TabPane>
                </Tabs>
                <SendCouponsModal handleOk={this.sendCouponsModalOk} queryRedEnvelopeList={this.queryRedEnvelopeList} handleCancel={this.sendCouponsModalCancel} type={sendType} visible={sendVisible} />
                <DyModal ref="launchCoupons" handleOk={this.launchCoupon} title={couponsModal.title} message={couponsModal.message} />

                <SendCoupons queryRedEnvelopeList={this.queryRedEnvelopeList} type={sendType} setReactours={this.props.setReactours} onRef={(ref) => { this.SendCoupons = ref }} />
            </div>
        )
    }
}

export default LiveSendCouponsTable