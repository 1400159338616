import React from 'react';
import { Layout, Divider, Menu, Checkbox, Input, Empty, Table } from 'antd';
import AJAX from "api/apis";
import DyImage from 'components/DyImage';
const { Content, Sider } = Layout;

class CommoditySelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      commodityList: [],
      checkAll: false,
      indeterminate: false,
      checkedList: '',
      commodityAction: "1",
      windowGoodsList: [],
      table: {
        total: 0,
        pageSize: 10,
        pageNum: 1,
      }
    };
  }
  /** ====   接口-    ========== */
  getShopWindowGoodsList = async (param = {}) => {
    this.setState({ tableLoading: true })
    let query = {
      flag: 0,
      ...param
    }
    await AJAX.displayWindow.getShopWindowGoodsList(query).then(res => {
      // console.log(res)
      this.setState({
        commodityList: res.data.list,
        table: {
          total: res.data.total,
          pageSize: res.data.pageSize,
          pageNum: res.data.pageNum,
        }
      })
    })
    this.setState({ tableLoading: false })
  }
  componentDidMount() {
    this.getShopWindowGoodsList()
  }
  getStoreGoods = async () => {
    let query = {
      goodsName: ''
    }
    await AJAX.homeLive.getStoreGoods(query).then(res => {
      console.log(res)
      this.setState({
        commodityList: res.data.length > 0?.list,
      })
    })
  }
  getSpecialGoods = async () => {
    let query = {
      goodsName: ''
    }
    await AJAX.homeLive.getSpecialGoods(query).then(res => {
      console.log(res)
      this.setState({
        commodityList: res.data.length > 0?.list,
      })
    })
  }
  /** ====   接口-end    ========== */
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  onConfirm = () => {
    this.setState({
      visible: false,
    });
  };
  onChange = (list) => {
    let commodityList = this.state.commodityList
    let indeterminate = this.state.indeterminate
    let checkAll = this.state.checkAll
    let checkedList = this.state.checkedList
    indeterminate = !!list.length && list.length < commodityList.length
    checkAll = list.length === commodityList.length
    checkedList = list
    this.setState({
      checkAll,
      indeterminate,
      checkedList
    })
    this.props.onCheckedList(checkedList)
    // console.log(list, name, ele, commodityList)
  };

  onCheckAllChange = (e) => {
    let commodityList = this.state.commodityList
    let indeterminate = false
    let checkAll = e.target.checked
    let checkedList = e.target.checked ? commodityList.map(item => item.goodsId) : []
    this.setState({
      checkAll,
      indeterminate,
      checkedList
    })
    this.props.onCheckedList(checkedList)
    // console.log(e);
  };

  handleClick = e => {
    this.setState({ commodityAction: e.key, searchValue: '' })
  };
  searchGoods = (e) => {
    let commodityAction = this.state.commodityAction
    if (commodityAction == '2' && commodityAction == '1') this.getShopWindowGoodsList({
      goodsName: e
    })
  }
  tableOnchange = (e) => {
    this.getShopWindowGoodsList({
      pageNum: e.current,
      pageSize: e.pageSize,
    })
    this.props.onCheckedList([])
  }
  columns = () => {
    const { searchValue } = this.state
    return [
      {
        title: <div className="commodity-lately-header">
          <span>全选</span>
          <Input.Search
            placeholder="搜索商品名称或ID"
            allowClear
            value={searchValue}
            style={{ width: 200 }}
            onSearch={this.searchGoods}
            onChange={e => { this.setState({ searchValue: e.target.value }) }}
          />
        </div>,
        dataIndex: 'goodsId',
        render: (text, row, index) => {
          return (
            <div className="commodity-lately-item" >
              {/* <Checkbox value={item.goodsId}></Checkbox> */}
              <div className="commodity-lately-item-body">
                <DyImage style={{ width: 62, height: 62 }} src={row.imgUrl}></DyImage>
                <div className="commodity-lately-body-center">
                  <div>{row.goodsName}</div>
                  <div>新手店铺</div>
                  <div>赚— 佣金率{row.goodsCommissionRate || 0}% <Divider type="vertical" /> 售价：{row.goodsCommission || 0}</div>
                </div>
              </div>
            </div>
          )
        }
      },
    ]
  }
  render() {
    const { commodityList, checkedList, searchValue, table } = this.state
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        let checkedList = selectedRows.map(item => item.goodsId)
        
        this.props.onCheckedList(checkedList)
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    return (
      <Layout className="commoditySelect">
        <Sider width="100px">
          <Menu
            onClick={this.handleClick}
            style={{ width: 100 }}
            defaultSelectedKeys={['1']}
            defaultOpenKeys={['sub1']}
            mode="inline"
          >
            <Menu.Item key="1" onClick={() => { this.getShopWindowGoodsList() }}>最近添加</Menu.Item>
            <Menu.Item key="2" onClick={() => { this.getShopWindowGoodsList() }}>橱窗商品 </Menu.Item>
            <Menu.Item key="3" onClick={this.getStoreGoods}>我的店铺</Menu.Item>
            <Menu.Item key="4" onClick={this.getSpecialGoods}>定向商品</Menu.Item>
          </Menu>
        </Sider>
        <Content>
          <div className="commodity-lately">

            <Table
              rowSelection={rowSelection}
              onChange={this.tableOnchange}
              columns={this.columns()}
              dataSource={commodityList}
              rowKey="goodsId"
              pagination={{
                total: table.total,
                current: table.pageNum,
                pageSize: table.pageSize,
                pageSizeOptions: [10, 20, 30],
                showSizeChanger: true,
                showTotal: total => `共 ${total} 条数据`
              }}
            />

          </div>
        </Content>
      </Layout>

    )
  }
}

export default CommoditySelect