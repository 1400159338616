import {combineReducers} from 'redux';
import language from './language';
import loading from './loading';
import reactours from './reactours';
import userInfo from './userInfo';

export default combineReducers({
    language,
    loading,
    reactours,
    userInfo,
})