import React from 'react';
import './index.css';

export default class TipsIcon extends React.Component {
  constructor() {
    super();
    this.state = {
    }
  }

  render() {

    return (
      <div
        onClick={() => { this.props.clicks(true) }}
        className="tipsIcon"
      >
        <img src={require("@assets/images/tipsIcon.png").default} />
      </div>
    )
  }
}