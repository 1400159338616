import React from 'react';
import { ShopOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Input, Select, Table, Alert, Typography } from 'antd';
import "./index.css";
import SetBuyingModal from "components/SetBuyingModal";
import AddCommodity from "./AddCommodity";
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import AJAX from "api/apis";
import { goodsSource, getStepsLocalStorage } from 'utils/utils'
import DyModal from '@components/DyModal';
import DyImage from 'components/DyImage';
const { Text, Paragraph } = Typography;

class CommodityManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setBuying: false,
            goodsList: [],
            delGoodsId: "",
            poinit: {
                id: "",
                sellPoint: ''
            },
            goodsLoading: false,
            selectGoodsList: [],
            path: "commodityManagement",
            closeQueryAllByLimit: false,
            table: {
                current: 1,
                pageSize: 10,
                total: 20
            }
        };
    }
    componentDidMount() {
        if (getStepsLocalStorage(this.state.path)) {
            this.queryAllByLimit()
            this.setReactours()
        } else {
            this.setState({
                goodsList: [
                    { "id": 21, "goodsId": 1, "goodsName": "华为02手表", "goodsNumber": "AA41212454", "imgUrl": "", "goodsPrice": "1000.00", "goodsCommission": "10.00", "goodsCommissionRate": "10.00", "goodsSource": null, "goodsInventory": 20, "sellNumber": 11, "sellPoint": null, "seckillPrice": "1.00~3.00", "seckillId": 6, "seckillStatus": 2, "seckillCreateTime": "2021-09-09 17:08:40", "activityEndTime": 10, "seckillTime": 0 }
                ],
                closeQueryAllByLimit: true
            })
            setTimeout(() => {
                this.setReactours()
            }, 500);

        }
    }
    componentDidUpdate(nextProps) {
        // console.log(this.props.isTourOpen, this.props.isTourOpen === false, getStepsLocalStorage(this.state.path), this.state.closeQueryAllByLimit)
        if (this.props.isTourOpen === false && getStepsLocalStorage(this.state.path) && this.state.closeQueryAllByLimit) {
            this.queryAllByLimit()
            this.setState({ closeQueryAllByLimit: false })
        }
    }
    /** ====  接口APi ======= */
    queryAllByLimit = async (param = {}) => {
        this.setState({ goodsLoading: true })
        let query = {
            pageNum: 1,
            pageSize: this.state.table.pageSize,
            ...param
        }
        await AJAX.homeLive.queryAllByLimit(query).then(res => {
            // console.log(res)
            this.setState({
                goodsList: res.data.list,
                goodsLoading: false,
                table: {
                    current: res.data.pageNum,
                    pageSize: res.data.pageSize,
                    total: res.data.total
                }
            })
            // message.success("列表刷新成功")
        })
    }
    deleteLiveGoods = async (id) => {
        let query = {
            liveRoomGoodsId: id
        }
        await AJAX.homeLive.deleteLiveGoods(query).then(res => {
            this.queryAllByLimit()
            this.setState({ selectGoodsList: [] })
        })
    }
    addGoodsSellPoint = async (query) => {

        await AJAX.homeLive.addGoodsSellPoint(query).then(res => {
            this.queryAllByLimit()
        })
    }
    /** ====   接口-end    ========== */
    setReactours = () => {
        this.props.reactours({
            isTourOpen: true,
            isOpen: true,
            path: "commodityManagement",
            steps: [
                {
                    selector: '.commodityManagement-table .ant-btn-primary',
                    content: (
                        <div>
                            <div>添加商品</div>
                            <p>可以快速添加店铺、定向等更多商品</p>
                        </div>
                    ),
                },
                {
                    selector: ".commodityManagement .home-maidian",
                    content: (
                        <div>
                            <img style={{ width: "100%", minWidth: 271 }} src={require("@assets/images/homeLive/home-maidian.png").default} />
                            <div>快去设置卖点</div>
                            <p>信息将暂时给用户</p>
                        </div>
                    )
                },
            ]
        })
    }
    columns = () => {
        return [
            {
                title: '商品序号',
                dataIndex: 'id',
                render: (text, row, index) => {
                    return <Input style={{ width: 50 }} value={index + 1} />
                }
            },
            {
                title: '商品信息',
                dataIndex: 'goodsName',
                render: (text, row, index) => {
                    return (
                        <div className="commodityManagement-table-name">
                            <DyImage style={{ width: 62, height: 62 }} src={row.imgUrl}></DyImage>
                            <div className="commodityManagement-table-name-center">
                                <div><Paragraph ellipsis={{ rows: 2, expandable: false }}>{row.goodsName}</Paragraph></div>
                                <div></div>
                                <div><ExclamationCircleOutlined />新手店铺</div>
                            </div>
                        </div>
                    )
                },
            },
            {
                title: '商品ID',
                dataIndex: 'goodsNumber',
            },
            {
                title: '商品价格',
                dataIndex: 'goodsPrice',
                render: (text) => "￥" + text
            },
            {
                title: '商品来源',
                dataIndex: 'goodsSource',
                render: (text) => {
                    return goodsSource(String(text))
                }
            },
            {
                title: '商品卖点',
                dataIndex: 'sellPoint',
                render: (text, row, index) => {
                    if (text) {
                        return (
                            <div style={{ width: 110 }}>
                                <Paragraph ellipsis={{ rows: 1, expandable: false }}>{text}</Paragraph>
                                <span type="link" className="home-maidian" onClick={() => { this.setBuyingOpen(row) }}>修改</span>
                            </div>
                        )
                    }
                    return <span type="link" className="home-maidian" onClick={() => { this.setBuyingOpen(row) }}>设置卖点</span>
                }
            },
            {
                title: '操作',
                dataIndex: 'id',
                render: (text) => {
                    return (
                        <div>
                            <Button type="link" danger onClick={() => { this.delGoodsModal(text) }} >删除</Button>
                            <Button type="link" danger onClick={() => { this.queryAllByLimit() }}>刷新</Button>
                        </div>
                    )
                }
            },
        ]
    }
    setBuyingOpen = (value) => {
        let poinit = this.state.poinit
        poinit.id = value.id
        poinit.sellPoint = value.sellPoint || ''
        this.setState({
            setBuying: true,
            poinit
        })
    }
    setBuyingOk = () => {
        this.setState({ setBuying: false })
        this.addGoodsSellPoint(this.state.poinit)
    }
    setBuyingCancle = () => {
        this.setState({ setBuying: false })
    }
    delGoodsModal = (id) => {
        this.refs.delGoods.showModal();
        this.setState({ delGoodsId: id })
    }
    onPoinit = (e) => {
        let poinit = this.state.poinit
        poinit.sellPoint = e
        this.setState({
            poinit
        })
    }
    tableOnchange = (e) => {
        // console.log(e)
        this.queryAllByLimit({
            pageNum: e.current,
            pageSize: e.pageSize,
        })
    }
    render() {
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                this.setState({ selectGoodsList: selectedRows.map(item => item.id) })
            },
            getCheckboxProps: (record) => ({
                disabled: record.name === 'Disabled User',
                // Column configuration not to be checked
                name: record.name,
            }),
        };
        const { goodsList, setBuying, goodsLoading, selectGoodsList, poinit, table } = this.state
        return (
            <div className="commodityManagement">
                <Alert message="「直播间商品管理」近期将逐步下线，暂不继续进行功能更新，请前往「直播中控台」进行商品管理" type="warning" showIcon />
                <div className="commodityManagement-table">
                    <div className="commodityManagement-table-header">
                        <div className="commodityManagement-table-header-right">
                            <strong>直播间商品 ({table && table.total})</strong>
                            <span className="commodityManagement-not-live">未开播</span>
                            <span>直播间最多支持100个商品</span>
                        </div>
                        <div>
                            <Button disabled={selectGoodsList <= 0} onClick={() => { this.delGoodsModal(selectGoodsList.join(',')) }}>批量删除</Button>
                            <Button type="primary" onClick={() => {
                                this.AddCommodity.showDrawer(0)
                            }} >添加商品</Button>
                        </div>
                    </div>
                    <Table
                        rowSelection={{
                            type: "checkbox",
                            ...rowSelection,
                        }}
                        columns={this.columns()}
                        dataSource={goodsList}
                        pagination={{
                            total: table.total,
                            current: table.pageNum,
                            pageSize: table.pageSize
                        }}
                        onChange={this.tableOnchange}
                        rowKey="id"
                        loading={goodsLoading}
                    />
                </div>
                <SetBuyingModal poinit={poinit} visible={setBuying} onPoinit={this.onPoinit} setBuyingOk={this.setBuyingOk} setBuyingCancle={this.setBuyingCancle} />
                <DyModal ref="delGoods" showIcon={true} message="确认从该直播间删除该商品" handleOk={() => { this.deleteLiveGoods(this.state.delGoodsId) }} />

                <AddCommodity setReactours={this.setReactours} queryAllByLimit={this.queryAllByLimit} onRef={(ref) => { this.AddCommodity = ref }} />
            </div>
        )
    }
}
// reducers中的数据 ,然后更新组件
const mapStateToProps = (state) => ({
    isTourOpen: state.reactours.isTourOpen,
    steps: state.reactours.steps,
    isOpen: state.reactours.isOpen,
    reactours: state.reactours.reactours,
    data: state
})

export default connect(mapStateToProps, { reactours: (value) => reactours(value) })(CommodityManagement)