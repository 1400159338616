import React from 'react';
import LiveSendCouponsTable from "./module/LiveSendCouponsTable";
import "./index.css";
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';

class LiveSendCoupons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.setReactours()
        // console.log(this.refs)
    }
    setReactours = () => {
        this.props.reactours({
            isTourOpen: true,
            isOpen: true,
            steps: [
                {
                    selector: ".LiveSendCouponsTable-header .ant-dropdown-trigger",
                    content: (
                        <div>
                            <div>直播间发券！（使新功能引导）</div>
                            <p>抖音小店商家可以创建达人定向优惠券和粉丝专享券啦，可以联系小店合作商家创建哦</p>
                        </div>
                    )
                },
            ],
            path: "LiveSendCoupons"
        })
    }
    render() {
        return (
            <div className="LiveSendCoupons">
                <div className="LiveSendCoupons-header" >
                    本场直播间优惠券
                    <span>未开播</span>
                </div>
                <LiveSendCouponsTable setReactours={this.setReactours} />

            </div>
        )
    }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(LiveSendCoupons)