import React from 'react';
import { Popover, Divider, Input } from 'antd';
import DyDrawer from 'components/DyDrawer';
import { RightOutlined } from '@ant-design/icons';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import AJAX from "api/apis";
class EditCommodity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      plateList: [],
      plateValue: [0, 1, 2, 3],
      promotionInfoId: '',
      promtionTitleValue: '',
      maidianValue: '',
      promotionInfo: {},
      addloading: false,
    }
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  /** ====   接口-    ========== */
  getPromotionInfo = (row) => {
    let plateList = []
    let plateValue = []
    plateList = row.img.map((item, index) => {
      item.id = index
      item.key = index
      return item
    })
    plateValue = row.img.map((item, index) => {
      return index
    })
    this.setState({ promotionInfoId: row.id, promotionInfo: row, plateList, plateValue })
    AJAX.displayWindow.getPromotionInfo({ shopwindowGoodsId: row.id }).then(res => {
      // console.log(res)
      if (res.data) {
        this.setState({
          promtionTitleValue: res.data.videoPromotionTitle,
          maidianValue: res.data.promotionPoint,
        })
      } else {
        this.setState({
          promtionTitleValue: '',
          maidianValue: '',
        })
      }
    })
  }

  updatePromotionInfo = () => {
    if (this.state.addloading) return
    this.setState({ addloading: true })
    let data = {
      "shopwindowGoodsId": this.state.promotionInfoId,
      "promotionPoint": this.state.maidianValue,
      "videoPromotionTitle": this.state.promtionTitleValue
    }

    AJAX.displayWindow.updatePromotionInfo(data).then(res => {
      this.onClose()
    })
    setTimeout(() => {
      this.setState({ addloading: false })
    }, 3000);
  }
  /** ====   接口-end    ========== */
  showDrawer = (id) => {
    this.setState({
      visible: true,
    });
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        isOpen: false,
        path: ""
      })
    }, 300);
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.setState({ promotionInfoId: '' })
    this.props.setReactours()
  };
  onConfirm = () => {
    this.updatePromotionInfo()
    this.props.setReactours()
  };
  content = (img) => {
    return <div className={"example-info-" + img}></div>
  }
  onPlate = (id) => {
    let plateList = this.state.plateList
    let plateValue = this.state.plateValue
    if (plateValue.includes(id)) {
      // console.log(key)
      plateValue.splice(plateValue.indexOf(id), 1)
    } else {
      plateValue.push(id)
    }
    plateList.forEach(item => {
      if (item.id == id) {
        item.key = plateValue.indexOf(id)
      } else {
        item.key = plateValue.indexOf(item.id)
      }
    })
    this.setState({
      plateValue
    })
  }
  onPromtionTitle = (e) => {
    this.setState({ promtionTitleValue: e.target.value })
  }
  onMaidian = (e) => {
    this.setState({ maidianValue: e.target.value })
  }




  render() {
    const { visible, plateList, promtionTitleValue, maidianValue, promotionInfo } = this.state
    return (
      <DyDrawer
        title="编辑推广信息"
        placement="right"
        visible={visible}
        showFooter={true}
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        className="EditCommodity"
        width="660px"
      >
        <div className="EditCommodity-header">
          <img style={{ width: 120, height: 120 }} src={promotionInfo && promotionInfo.imgUrl}></img>
          <div className="EditCommodity-header-body">
            <div>{promotionInfo && promotionInfo.goodsName}</div>
            <div>售价￥ {promotionInfo && promotionInfo.goodsPrice}<Divider type="vertical" /> 已售15件</div>
            <p className="coupon-wrap"><b>小店</b>  <RightOutlined /></p>
          </div>
        </div>
        <div className="EditCommodity-body">
          <div className="EditCommodity-body-item">
            <strong>短视频推广标题</strong>
            <Input value={promtionTitleValue} maxLength={10} placeholder="标题将在短视频播放页面展示" suffix={<span>{promtionTitleValue.length}/10</span>} onChange={this.onPromtionTitle} />
            <Popover content={this.content("shili")} title="">
              <span className="example">示例</span>
            </Popover>
          </div>
          <div className="EditCommodity-body-item">
            <strong>直播推广卖点</strong>
            <Input value={maidianValue} maxLength={15} placeholder="推广卖点将在直摇问展示" suffix={<span>{maidianValue.length}/15</span>} onChange={this.onMaidian} />
            <Popover content={this.content("shili2")} title="">
              <span className="example">示例</span>
            </Popover>
          </div>
          <div className="EditCommodity-body-item">
            <strong>商品图片</strong>
            <ul className="EditCommodity-body-figure">
              {
                plateList.map(item => {
                  return (
                    <li key={item.img} onClick={() => { this.onPlate(item.id) }}>
                      <img style={{ width: 92, height: 92 }} src={item.goodsImgUrl}></img>
                      {
                        item.key > -1 && <div className="EditCommodity-body-plate"><span>{item.key + 1}</span></div>
                      }
                    </li>
                  )
                })
              }

            </ul>
          </div>
        </div>
      </DyDrawer >
    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(EditCommodity)