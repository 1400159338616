import React from 'react';
import { Input, Button, Modal } from 'antd';

class SetBuyingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: ""
    };
  }
  componentDidUpdate(nextProps) {
    // console.log(this.props.isTourOpen, this.props.isTourOpen === false, getStepsLocalStorage(this.state.path), this.state.closeQueryAllByLimit)
    if (nextProps.poinit.sellPoint != this.state.inputValue) {
      console.log(nextProps.poinit.sellPoint)
      this.setState({
        inputValue: nextProps.poinit.sellPoint || ''
      })
    }
  }
  setBuyingOpen = () => {
    this.setState({ setBuying: true })

  }
  setBuyingOk = () => {
    this.props.setBuyingOk()
    this.setState({ inputValue: "" })
    this.props.onPoinit('')
  }
  setBuyingCancle = () => {
    this.props.setBuyingCancle()
    this.setState({ inputValue: "" })
    this.props.onPoinit('')
  }
  onChange = (e) => {
    this.setState({
      inputValue: e.target.value
    })
    this.props.onPoinit(e.target.value)
  }

  render() {
    const { visible } = this.props
    const { inputValue } = this.state
    return (
      <Modal
        title="修改商品卖点"
        closable={false}
        visible={visible}
        onOk={this.setBuyingOk}
        onCancel={this.setBuyingCancle}
        width="400px"
        cancelText="取消"
        okText="确定"
        style={{ top: "40%" }}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={this.setBuyingOk} style={{ marginRight: 8 }} type="primary">
              确定
            </Button>
            <Button onClick={this.setBuyingCancle}>
              取消
            </Button>

          </div>
        }
      >
        <Input value={inputValue} onChange={this.onChange} maxLength={15} suffix={inputValue.length + "/15"} placeholder="请输入卖点文案" />
      </Modal>
    )
  }
}

export default SetBuyingModal