import React from 'react';
import BasicRoute from "router";
import SideNav from 'components/SideNav';
import DyHeader from 'components/Header';
import InletHeader from 'components/Header/InletHeader';
import { Layout } from 'antd';
import container from './container';
import CustomerService from 'components/CustomerService';
import "./index.css";
import Reactours from "components/reactours";

const { Content, Sider } = Layout;

class AppChildren extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disHeader: ["/login", "/login/scancode"].includes(window.location.pathname),
      pathname: window.location.pathname,
    };
  }
  componentDidUpdate(nextProps, nextState) {

    if (window.location.pathname != this.state.pathname) {
      this.setState({
        pathname: window.location.pathname,
        disHeader: ["/login", "/login/scancode"].includes(window.location.pathname),
      })
    }
  }

  componentDidMount() {
  }
  render() {
    const { disHeader } = this.state
    return (
      <div className="App" >
        {
          window.location.pathname.includes('live') && !disHeader && <DyHeader></DyHeader>
        }
        {
          window.location.pathname.includes('inlet') && <InletHeader></InletHeader>
        }

        <Layout className={window.location.pathname.includes('inlet') ? "intlet-main" : !disHeader ? "app-main" : ''} >
          {
            !disHeader && <Sider theme="light" width={window.location.pathname.includes("/inlet-page") ? "246px" : "184px"} >
              <SideNav />
            </Sider>
          }

          <Content><BasicRoute /></Content>
        </Layout>
        <Reactours />
        <CustomerService />
      </div>
    )
  }
}

export default container(AppChildren)