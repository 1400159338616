
import React from 'react';
import { Button, Alert, Checkbox, Tabs, Progress, Row, Col, Radio, Table, Typography, Breadcrumb, Divider } from 'antd';
import * as echarts from "echarts";
import moment from "moment";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { dafenModal } from 'utils/utils';
import cookieUtils from "utils/cookie";
import Portrait from "./../portrait"

const { TabPane } = Tabs;
const { Paragraph, Text } = Typography;
class DataLiveDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      boardList: [
        {
          key: 1,
          title: "流量",
          svg: "data-live-detail-kanban1",
          bgColor: "rgb(227, 238, 252)",
          data: [
            {
              name: "直播间观看人数",
              value: 21,
            },
            {
              name: "直播间观看次数",
              value: 21,
            },
            {
              name: "平均在线人数",
              value: 1,
              children: {
                name: "最高在线人数",
                value: 3,
              }
            },
            {
              name: "平均观看时长",
              value: 51,
              children: {
                name: "粉丝平均观看0秒",
                value: 3,
              }
            },
          ]
        },
        {
          key: 2,
          title: "用户",
          svg: "data-live-detail-kanban2",
          bgColor: "rgb(237, 227, 252)",
          data: [
            {
              name: "商品曝光人数",
              value: 21,
            },
            {
              name: "商品点击人数",
              value: 21,
            },
            {
              name: "直播期间成交人数",
              value: 1,
              children: {
                name: "粉丝占比0.0%",
                value: 3,
              }
            },
          ]
        },
        {
          key: 3,
          title: "互动",
          svg: "data-live-detail-kanban3",
          bgColor: "rgb(255, 228, 203)",
          data: [
            {
              name: "新增粉丝数",
              value: 4,
            },
            {
              name: "直播间评论数",
              value: 23,
            },
            {
              name: "直播间点赞数",
              value: 15,
            },
            {
              name: "直播间分享次数",
              value: 3,
            },
          ]
        },
        {
          key: 4,
          title: "用户",
          svg: "data-live-detail-kanban4",
          bgColor: "rgb(255, 244, 203)",
          data: [
            {
              name: "支付订单量",
              value: 32,
              children: {
                name: "粉丝占比0.0%",
                value: 3,
              }
            },
            {
              name: "支付订单金额",
              value: 33.00,
              children: {
                name: "粉丝占比0.0%",
                value: 3,
              }
            },
            {
              name: "订单付款率",
              value: "10%",

            },
          ]
        },
      ],
      realTime: {
        id: 1,
        title: "实时趋势",
        tip: "整体流量来源在各关键节点的转化效果，仅成交相关数据不含第三方商品数据",
        data: [
          {
            key: 1,
            name: "流量指标",
            list: ['实时在先人数', '进入直播间人数']
          },
          {
            key: 2,
            name: "互动指标",
            list: ['评论次数', '点赞次数', '分享次数', '新增粉丝数']
          },
          {
            key: 3,
            name: "用户指标",
            list: ['商品曝光人数', '商品点击人数', '直播期间待支付订单人数', '直播期间支付订单人数']
          },
          {
            key: 4,
            name: "成交指标",
            list: ['直播期间成交订单数', '直播期间成交金额', '直播期间待支付订单数', '直播期间待支付金额']
          },
        ]
      },
      flow: {
        id: 2,
        title: "流量来源",
        tip: "基于每五分钟进入直播间的总次数进行流量拆解，最多支持选择两个流量来源；",
        data: [
          {
            key: 1,
            name: "整体流量",
            list: ['全部自然流量', '全部付费流量']
          },
          {
            key: 2,
            name: "自然流量",
            list: ['推荐页短视频引流', '粉丝流量', '直播自然推荐', '其他流量']
          },
          {
            key: 3,
            name: "付费流量",
            list: ['千川广告', '竞价直播推广', '品牌广告']
          },
        ]
      },
      dataSource: [
        {
          key: '1',
          id: "3496239999865778638",
          name: '挂脖无叶小风扇便携式懒人无叶迷你随身小型可充电挂颈小电',
          addTime: "2021/06/08 00:10",
          type: "智能家居",
          price: 123,
          exposure: 66,
          click: 20,
          clickRate: 20,
          img: "home-live-commodity-list1",
          addOrder: 12,
          addPrice: 99.8,
          deal: 99.8
        },
        {
          key: '2',
          id: "349623999865778638",
          name: '挂脖无叶小风扇便携式懒人无叶迷你随身小型可充电挂颈小电',
          addTime: "2021/06/08 00:10",
          type: "智能家居",
          price: 123,
          exposure: 66,
          click: 2,
          clickRate: 20,
          img: "home-live-commodity-list3",
          addOrder: 12,
          addPrice: 99.8,
          deal: 99.8
        },
      ],
      conversionData: {
        a: {
          title: "千川广告核心数据",
          tip: "千川广告成单漏斗",
          data: [
            {
              name: "订单数",
              value: "0"
            },
            {
              name: "直播间消耗",
              value: "¥0.00"
            },
            {
              name: "进入直播间人数",
              value: "0"
            },
            {
              name: "新增粉丝数",
              value: "0"
            },
            {
              name: "抖店直接支付金额",
              value: "¥0.00"
            },
          ]
        },
        b: {
          title: "竞价直播广告核心数据",
          tip: "竞价直播广告成单漏斗",
          data: [
            {
              name: "直播间消耗",
              value: "¥0.00"
            },
            {
              name: "进入直播间人次",
              value: "0"
            },
            {
              name: "新增粉丝人数",
              value: "0"
            },
            {
              name: "直接成交金额",
              value: "¥0.00"
            },
            {
              name: "7日间接成交金额",
              value: "¥0.00"
            },
          ]
        }
      },
      conversionValue: "a"
    };
  }
  getSomeDays = (daysCount = 5) => {
    const now = moment().startOf("hour").add(1, "hours");

    return Array(daysCount)
      .fill(null)
      .map((item) => {
        return now.add(-1, "hours").format('LT');
      })
      .reverse();
  };
  getSomeFakeNumber = (count = 5) => {
    return Array(count)
      .fill(null)
      .map(() => {
        return Math.floor(Math.random() * 1000);
      });
  };
  componentDidMount() {
    // 基于准备好的dom，初始化echarts实例
    this.ceateEcharts("realTime")
    this.ceateEcharts("flow")
    this.vertical()
    if (cookieUtils.getCookie('amazon_url') == this.props.location.pathname) {
      dafenModal()
    }
  }
  ceateEcharts = (id) => {
    if (document.getElementById(id)) {
      var myChart = echarts.init(document.getElementById(id));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["实时在线人数", "直播旗舰成交额度"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "12%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.getSomeDays(),
        },
        yAxis: {
          type: "value",
        },
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100,
            bottom: '0px'
          },
        ],
        series: [
          {
            name: "实时在线人数",
            type: "line",
            stack: "总量",
            data: this.getSomeFakeNumber(),
            color: "rgb(102,134,255)"
          },
          {
            name: "直播旗舰成交额度",
            type: "line",
            stack: "总量",
            data: this.getSomeFakeNumber(),
            color: "rgb(255,203,102)"
          },
        ],
      });
    }

  }
  vertical = () => {
    if (document.getElementById("analysis")) {
      var myChartan = echarts.init(document.getElementById("analysis"));
      myChartan.setOption({
        color: ['rgb(102,134,255)', "rgb(255,203,102)"],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          bottom: 'bottom',
          data: ['非粉丝', "粉丝"]
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: true,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: [
              { value: 335, name: '非粉丝' },
              { value: 33, name: '粉丝' },
            ]
          }
        ]
      })
    }

  }
  trendEcharts = (value, type) => {
    return (
      <div className="DataLiveDetail-trend echarts">
        <h3>{value.title} <span>{value.tip}</span></h3>
        <Checkbox.Group style={{ width: '100%' }}>
          {
            value.data.map(item => {
              return (
                <div key={item.name} style={{ marginBottom: 20 }} >
                  <span>{item.name}</span>
                  {
                    item.list.map((val, index) => {
                      return <Checkbox key={`${item.key}${index}`} value={`${item.key}${index}`}>{val}</Checkbox>
                    })
                  }

                </div>
              )
            })
          }
        </Checkbox.Group>
        <div className={"DataLiveDetail-trend-" + type} id={type} style={{ width: "100%", height: "300px", maxWidth: 1092 }}></div>
        <Radio.Group defaultValue={3}>
          <Radio value={1}>添加商品至直播间</Radio>
          <Radio value={2}>讲解商品</Radio>
          <Radio value={3}>无</Radio>
        </Radio.Group>
      </div>
    )
  }
  columns = () => {
    return [
      {
        title: '排名',
        dataIndex: 'key',
        fixed: 'left',
        width: 60,
      },
      {
        title: '商品信息',
        dataIndex: 'name',
        fixed: 'left',
        width: 240,
        render: (text, row, index) => {
          return (
            <div className="DataLiveDetail-table-basics">
              <img src={require(`@assets/images/homeLive/${row.img}.png`).default}></img>
              <Paragraph ellipsis={{ rows: 2, expandable: false, symbol: '' }} >{text}</Paragraph >
            </div>
          )
        }
      },
      {
        title: "添加到直播间",
        dataIndex: 'addTime',
        sorter: (a, b) => a.age - b.age,
        width: 160,
      },
      {
        title: "商品曝光人数",
        dataIndex: 'exposure',
        sorter: (a, b) => a.age - b.age,
        width: 130,
      },
      {
        title: "商品点击人数",
        dataIndex: 'click',
        sorter: (a, b) => a.age - b.age,
        width: 130,
      },
      {
        title: "商品点击率",
        dataIndex: 'clickRate',
        sorter: (a, b) => a.age - b.age,
        width: 130,
      },
      {
        title: "创建订单人数",
        dataIndex: 'addOrder',
        sorter: (a, b) => a.age - b.age,
        width: 130,
      },
      {
        title: "创建订单数",
        dataIndex: 'addOrder',
        sorter: (a, b) => a.age - b.age,
        width: 130,
      },
      {
        title: "创建订单金额",
        dataIndex: 'addPrice',
        sorter: (a, b) => a.age - b.age,
        width: 130,
        render: (text) => {
          return "￥" + text
        }
      },
      {
        title: "成交订单人数",
        dataIndex: 'addOrder',
        sorter: (a, b) => a.age - b.age,
        width: 130,
      },
      {
        title: "成交订单数",
        dataIndex: 'addOrder',
        sorter: (a, b) => a.age - b.age,
        width: 130,
      },
      {
        title: "成交金额",
        dataIndex: 'addPrice',
        sorter: (a, b) => a.age - b.age,
        fixed: 'right',
        width: 130,
        render: (text) => {
          return "￥" + text
        }
      },
    ]
  }
  locale = () => {
    return {
      emptyText: <div className="DataLiveDetail-empty">
        {/* <img src={require(`@assets/images/dataLive/share-coupons-empty.png`).default} /> */}
        <span>暂无数据</span>
      </div>
    }
  }
  onConversion = (e) => {
    this.setState({
      conversionValue: e.target.value
    })
  }

  render() {
    const { boardList, realTime, flow, dataSource, conversionData, conversionValue } = this.state
    return (
      <div className="DataLiveDetail">
        <Breadcrumb>
          <Breadcrumb.Item href="/live-data">直播带货数据</Breadcrumb.Item>
          <Breadcrumb.Item>数据详情</Breadcrumb.Item>
        </Breadcrumb>
        <div className="DataLiveDetail-header">
          <div className="DataLiveDetail-header-right DataLive-table-basics">
            <img src={require(`@assets/images/dataLive/data-live-table1.png`).default} />
            <div>
              <div>XC狗狗...正在直播</div>
              <p>直播时间 2021/08/10 14:37 - 2021/08/10 14:39</p>
              <p>直播时长 1分钟 推广 商品 0件</p>
            </div>
          </div>
          <div className="DataLiveDetail-header-left">
            <p>更新时间：2021/08/23 10:42:00</p>
            <Button>实时大屏</Button>
          </div>
        </div>
        <Alert message="直播期间数据实时更新，每分钟更新一次，直播结束后数据按天更新；由于带货规则调整，本页面只提供抖店商品的订单数据" type="warning" showIcon />
        <div className="DataLiveDetail-board">
          <h3>整体看板</h3>
          <div className="DataLiveDetail-board-body">
            {
              boardList.map(item => {
                return (
                  <ul className="DataLiveDetail-item" key={item.key}>
                    <li style={{ backgroundColor: item.bgColor }}>
                      <img src={require(`@assets/images/dataLive/${item.svg}.svg`).default} />
                      <div>{item.title}</div>
                    </li>
                    {
                      item.data.map(val => {
                        return (
                          <li key={val.name}>
                            <div>{val.name}</div>
                            <p>{val.value}</p>
                            {
                              val.children && <span>{val.children.name}</span>
                            }
                          </li>
                        )
                      })
                    }
                  </ul>
                )
              })
            }
          </div>
        </div>
        {
          this.trendEcharts(realTime, "realTime")
        }
        <Tabs defaultActiveKey="1">
          <TabPane tab="流量分析" key="1">
            <div className="DataLiveDetail-tabs DataLiveDetail-flow">
              {
                this.trendEcharts(flow, "flow")
              }
              <div className="DataLiveDetail-flow-analysis">
                <h3>播后来源汇总</h3>
                <Row>
                  <Col span={12}>
                    <h4>流量分析</h4>
                    <div className="DataLiveDetail-flow-progress">直播自然推荐 <Progress strokeColor="rgb(102,134,255)" percent={30} /></div>
                    <div className="DataLiveDetail-flow-progress">其他流量 <Progress strokeColor="rgb(255,203,102)" percent={30} /></div>
                    <div className="DataLiveDetail-flow-progress">竞价直播推荐 <Progress strokeColor="rgb(255,102,102)" percent={23} /></div>
                    <div className="DataLiveDetail-flow-progress">品牌广告 <Progress strokeColor="rgb(255,151,66)" percent={30} /></div>
                    <div className="DataLiveDetail-flow-progress">推荐页短视频引流 <Progress strokeColor="rgb(255,151,66)" percent={2} /></div>
                    <div className="DataLiveDetail-flow-progress">粉丝量 <Progress strokeColor="rgb(255,151,66)" percent={30} /></div>
                  </Col>
                  <Col span={12}>
                    <h4>粉丝分析</h4>
                    <div id="analysis" style={{ width: "100%", height: "300px" }}></div>
                  </Col>
                </Row>
              </div>
              <div className="DataLiveDetail-conversion">
                <h3>整体转化 <span>整体流量来源在各关键节点的转化效果，仅成交相关数据不含第三方商品数据</span></h3>
                <div className="DataLiveDetail-conversion-body">
                  <img src={require("@assets/images/dataLive/data-live-detail-conversion1.png").default} ></img>
                </div>
              </div>
              <div className="DataLiveDetail-conversion">
                <h3>付费转化效果 <span>付费流量来源的转化情况</span></h3>
                <div className="DataLiveDetail-conversion-header">
                  <Radio.Group defaultValue="a" onChange={this.onConversion} >
                    <Radio.Button value="a">千川竞价广告 <QuestionCircleOutlined /></Radio.Button>
                    <Radio.Button value="b">竞价直播推广</Radio.Button>
                  </Radio.Group>
                  <div style={{ margin: "20px 0" }} >{conversionData[conversionValue].title}</div>
                  <ul className="DataLiveDetail-conversion-data">
                    {
                      conversionData[conversionValue].data.map(item => {
                        return (
                          <li key={item.name}>
                            <div>{item.name}</div>
                            <p>{item.value}</p>
                          </li>
                        )
                      })
                    }
                  </ul>
                  <div>{conversionData[conversionValue].tip}</div>
                </div>
                <div className="DataLiveDetail-conversion-body">
                  <img src={require("@assets/images/dataLive/data-live-detail-conversion2.png").default} ></img>
                </div>
                {
                  conversionValue == "b" && (
                    <div className="DataLiveDetail-conversion-cost">
                      <div style={{ margin: "20px 0" }} >竞价直播广告成本数据</div>
                      <ul>
                        <li><span>千次展示成本</span> <QuestionCircleOutlined /><Divider /><span>￥0.00</span></li>
                        <li><span>平均引流成本回</span> <QuestionCircleOutlined /><Divider /><span>￥0.00</span></li>
                        <li><span>新增粉丝成本</span> <QuestionCircleOutlined /><Divider /><span>￥0.00</span></li>
                        <li><span>商品点击成本回</span> <QuestionCircleOutlined /><Divider /><span>￥0.00</span></li>
                        <li><span>直接成交ROI</span> <QuestionCircleOutlined /><Divider /><span>￥0.00</span></li>
                        <li><span>7日间接成交ROI</span> <QuestionCircleOutlined /><Divider /><span>￥0.00</span></li>
                      </ul>
                    </div>
                  )
                }
              </div>
            </div>
          </TabPane>
          <TabPane tab="商品排行" key="2">
            <div className="DataLiveDetail-ranking">
              <Table columns={this.columns()} scroll={{ x: 1056 }} dataSource={dataSource} locale={this.locale()} />
            </div>
          </TabPane>
          <TabPane tab="用户画像" key="3">
            <Portrait title="看播用户画像" type={1} />
            <Portrait title="支付用户画像" />
          </TabPane>
        </Tabs>
      </div>
    )
  }
}

export default DataLiveDetail