import React from 'react';
import { PageHeader, Form, Radio, message } from 'antd';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import './index.css';
import AJAX from 'api/apis';

class LiveSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LiveSetdata: {}
    };
  }
  componentDidMount() {
    this.setReactours()
    // console.log(this.refs)
    this.getLiveSetting()
  }
  // 查询直播间设置
  getLiveSetting = () => {
    AJAX.liveSet.getLiveSetting().then(res => {
      this.setState({
        LiveSetdata: res.data
      })
    })
  }
  // 修改-添加直播间设置
  updateLiveSetting = () => {

    AJAX.liveSet.updateLiveSetting(this.state.LiveSetdata).then(res => {
      message.info("设置成功")
    })
  }
  setReactours = () => {
    this.props.reactours({
      isTourOpen: false,
      isOpen: false,
      path: "live-set"
    })
  }
  onChange = (e) => {
    let LiveSetdata = this.state.LiveSetdata
    LiveSetdata.goodsSite = e.target.value === 0 ? false : true
    this.setState({
      LiveSetdata
    }, () => {
      this.updateLiveSetting()
    })
  }
  render() {
    const { LiveSetdata } = this.state
    return (
      <div className="live-set">
        <PageHeader
          className="site-page-header"
          onBack={() => null}
          title="直播设置"
          backIcon={false}
        />
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item label="商品添加位置" help={`添加商品到购物袋，新添加的商品将会展示在商品列表的最${LiveSetdata.goodsSite === false ? "前" : "后"}面`}>
            <Radio.Group defaultValue={1} value={LiveSetdata.goodsSite === false ? 0 : 1} onChange={this.onChange}>
              <Radio value={0}>置顶</Radio>
              <Radio value={1}>置底</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="直播商品类型" >
            <Radio.Group value={"1"}>
              <Radio value="1">普通商品</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
        <map name="planetmap" id="planetmap" style={{ width: 100, height: 100 }}>
          <area shape="circle" coords="180,139,14" href="venus.html" alt="Venus" />
          <area shape="circle" coords="129,161,10" href="mercur.html" alt="Mercury" />
          <area shape="rect" coords="0,0,110,260" href="sun.html" alt="Sun" />
        </map>
      </div>
    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(LiveSet)