import moment from "moment";
import { Button, Modal } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons';
import cookieUtils from "utils/cookie";

export const uglifyValue = (value = {}) => {
  return window.btoa(encodeURIComponent(JSON.stringify(value)));
};

export const beautifyValue = (string = "") => {
  return JSON.parse(decodeURIComponent(window.atob(string)));
};

export function minDateSelect(current, minDate = moment()) {
  return current && current.endOf("day") < minDate.endOf("day");
}

export function dataMixin(template, data, _format) {
  return template.map((item) => {
    const { format, key, ...otherItem } = item;

    const $_format = format || _format || ((v) => v);

    const base = {
      ...otherItem,
      key,
      value: $_format(data[key]),
    };

    if (item.children || item.content) {
      const key = base.children ? "children" : "content";
      base[key] = dataMixin(item[key], data, _format);
    }

    return base;
  });
}

export const goodsSource = (value) => {
  let str = ''
  switch (value) {
    case "1":
      str = "淘宝"
      break
    case "2":
      str = "小店"
      break
    case "3":
      str = "京东"
      break
    case "4":
      str = "考拉海购"
      break
    case "5":
      str = "唯品会"
      break
    case "6":
      str = "苏宁易购"
      break
    case "7":
      str = "洋码头"
      break
  }
  return str
}

export const getStepsLocalStorage = (key) => {
  if (key) {
    let list = localStorage.getItem("stepList") || null
    if (list) {
      return JSON.parse(list).includes(key)
    }
  }
  return false
}

export const dafenModal = () => {
  const modal = Modal.info();
  modal.update({
    centered: true,
    footer: false,
    className: 'dafen',
    width: '330px',
    content: (
      <div className="expire-modal-content">
        <div className="expire-modal-content-text">
          <div className="p-24">
            <div className="scoreTipsText">
              {/* <img src="../../../assets/images/public/maskjg.png" /> */}
              <ExclamationCircleFilled style={{ fontSize: '20px', marginRight: '4px', color: 'rgb(248,194,101)' }} />
              <span>实操流程《{cookieUtils.getCookie("process_name")}》已完成，是否进行打分？</span>
            </div>
          </div>
        </div>
        <div className="expire-modal-btn">
          <Button type="primary" className="cancelBtn" onClick={() => {
            modal.destroy();
            cookieUtils.setCookie('amazon_url', '')
          }} data="hovers-c">取消</Button>
          <Button type="primary" onClick={() => {
            window.parent.postMessage({
              cmd: 'amazonDialog',
              params: {
                success: true,
                data: true
              }
            }, '*');
            cookieUtils.setCookie('amazon_url', '')
            modal.destroy();
          }} data="hovers-7">进行打分</Button>
        </div>
      </div>
    )
  });
}