import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import AJAX from "api/apis";
import cookieUtils from "utils/cookie";

export default function (Comp) {

  const mapStateToProps = (state) => {
    return {

    }
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    /*
    *说明：dispatch:redux的方法  ownProps：容器组件的props对象
    *使用方法：
    *参数：
    */
    return {
      userInfo: (value) => {
        dispatch({
          type: 'SET_USER_INFO',
          userInfo: value
        })
      }
    }
  };



  const wrapper = class extends React.Component {
    constructor(props) {
      super(props);
    }
    componentDidMount() {
      this.getLiveRoomInfo()
    }
    /** ====  接口APi ======= */
    getLiveRoomInfo = async () => {
      if(!cookieUtils.getCookie("live_streaming_token")) return
      await AJAX.homeLive.getLiveRoomInfo().then(res => {
        // console.log(res)
        if (res.code == 1) {
          this.props.userInfo({
            ...res.data
          })
        }

      })
    }
    render() {
      return (
        <Comp
          {...this.props}
          {...this.state}
        />
      );
    }
  };
  return connect(mapStateToProps, mapDispatchToProps)(withRouter(wrapper));

}
