import React from 'react';
import Tour from 'reactour';
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import './reactours.css';
import TipsIcon from "components/tipsIcon";
import { connect } from 'react-redux';
import { reactours } from "store/actions/reactours";

class Reactours extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTourOpen: true,
      // isTourOpen: false,
      spareIsTourOpen: this.props.isOpen,
      discountStep: 0
    }
  }
  disableBody = target => {
    disableBodyScroll(target)
  }
  enableBody = target => {
    // console.log("2")
    enableBodyScroll(target)
  }

  requestClose = () => {
    var obj = document.getElementsByClassName("kWwJhA")[0];
    var tourBoxObj = document.getElementsByClassName("tourBox")[0];
    obj.style.animationName = "fadeInRightBig2";
    tourBoxObj.style.animationDuration = ".3s";
    tourBoxObj.style.animationName = "fadeInRightBig2";
    setTimeout(() => {
      let value = {
        isTourOpen: false,
      }
      this.props.reactours(value)
    }, 200)
    document.body.style.overflowY = 'auto'
  }
  clicks = (e) => {
    let value = {
      isTourOpen: e,
      // steps: this.props.steps
      path: ""
    }
    // console.log(value)
    this.props.reactours(value)
  }
  render() {
    let { isTourOpen, isOpen } = this.props
    const accentColor = "#28bbac";
    return <React.Fragment>
      {
        isTourOpen ? <Tour
          className="tourBox"
          {...this.props}
          isOpen={isTourOpen}
          onRequestClose={this.requestClose}
          accentColor={accentColor}
          closeWithMask={false}
          disableInteraction={false}
          startAt={0}
          onAfterOpen={target => (document.body.style.overflowY = 'hidden')}
          onBeforeClose={target => (document.body.style.overflowY = 'auto')}
        /> : null
      }
      {
        isOpen && <TipsIcon clicks={this.clicks} />
      }

    </React.Fragment>
  }
}

// reducers中的数据 ,然后更新组件
const mapStateToProps = (state) => ({
  isTourOpen: state.reactours.isTourOpen,
  steps: state.reactours.steps,
  isOpen: state.reactours.isOpen,
  reactours: state.reactours.reactours,
  data: state
})

export default connect(mapStateToProps, { reactours: (value) => reactours(value) })(Reactours)