import React from 'react';
import { Button, Checkbox, Input, Tabs, Select, Table, Typography } from 'antd';
import DyDrawer from 'components/DyDrawer';
import { QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import CommoditySelect from './../CommoditySelect'
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import AJAX from "api/apis";
import DyImage from 'components/DyImage';
import { goodsSource } from 'utils/utils'
const { Paragraph } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

class AddCommodity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      checkedList: [],
      checked: "lately",
      distinguishValue: "",
      goodList: [],
      showDisValue: false,
      getGoodsInfoLoading: false,
    };
  }
  columns = () => {
    return [
      {
        title: '商品信息',
        dataIndex: 'goodsName',
        with: "10%",
        render: (text, row, index) => {
          return (
            <div className="commodityManagement-table-name">
              <DyImage style={{ width: 62, height: 62 }} src={row.imgUrl}></DyImage>
              <div className="commodityManagement-table-name-center">
                <div><Paragraph ellipsis={{ rows: 2, expandable: false }}>{row.goodsName}</Paragraph></div>
                {/* <div>ID：{row.id}</div> */}
                <div><ExclamationCircleOutlined />新手店铺</div>
              </div>
            </div>
          )
        },
      },
      {
        title: '商品ID',
        with: "20%",
        dataIndex: 'goodsNumber',
      },
      {
        title: '商品价格',
        with: "20%",
        dataIndex: 'goodsPrice',
        render: (text) => "￥" + text
      },
      // {
      //   title: '商品来源',
      //   dataIndex: 'goodsSource',
      //   render: (text) => {
      //     return goodsSource(text)
      //   }
      // },
    ]
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  /** ====   接口-    ========== */
  getGoodsInfo = () => {
    if (this.state.getGoodsInfoLoading) return
    this.setState({ getGoodsInfoLoading: true })
    let goodsIds = this.state.distinguishValue
    goodsIds = goodsIds.includes(',') ? goodsIds.split(',') : goodsIds.split('\n')
    goodsIds = goodsIds.map(item => {
      return item.split('=')[1]
    })
    goodsIds = goodsIds.filter(function (s) {
      return s && s.trim(); // 注：IE9(不包含IE9)以下的版本没有trim()方法
    });
    // console.log(goodsIds)
    goodsIds = goodsIds.join(",")
    AJAX.displayWindow.getGoodsDetail({ goodsIds }).then(res => {
      // console.log(res)
      this.setState({
        goodList: res.data,
        checkedList: []
      })
    })
    setTimeout(() => {
      this.setState({ getGoodsInfoLoading: false })
    }, 2000);
  }
  //1、40
  addGoods = () => {
    const { checkedList, showDisValue, addLoading } = this.state
    if (addLoading) return
    this.setState({ addLoading: true })
    if (checkedList.length > 0) {
      let goodsIds = checkedList.map(item => item.goodsId).join(",")
      let data = {
        goodsIds,
        liveStatus: showDisValue
      }
      AJAX.displayWindow.addGoods(data).then(res => {
        this.onClose()
        this.props.getShopWindowGoodsList()
      })
      setTimeout(() => {
        this.setState({ addLoading: false })
      }, 2000);
    }

  }
  /** ====   接口-end    ========== */
  showDrawer = () => {
    this.setState({
      visible: true,
    });
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        isOpen: false,
        path: ""
      })
    }, 300);
  };

  onClose = () => {
    this.setState({
      visible: false,
      goodList: [],
      checkedList: [],
      showDisValue: false,
      distinguishValue: ''
    });
    this.props.setReactours()
  };
  onConfirm = () => {
    this.addGoods()
    this.props.setReactours()
  };
  onCheckedList = (list, name) => {
    this.setState({
      checkedList: list,
      checked: name
    })
  }
  onDistinguish = (e) => {
    this.setState({
      distinguishValue: e.target.value
    })
  }
  onShowDis = (e) => {
    // console.log(e)
    this.setState({
      showDisValue: e.target.checked
    })
  }
  footer = () => {
    const { checkedList, showDisValue } = this.state
    // console.log(checkedList, checked,checkedList[checked])
    return (
      <div className="addCommodity-footer" >
        <div className="addCommodity-footer-select">
          <span>已选{checkedList.length}/100</span>
        </div>
        <div>
          <Checkbox onChange={this.onShowDis} >不在橱窗展示 <QuestionCircleOutlined /></Checkbox>
          <div>
            <Button onClick={this.onConfirm} style={{ marginRight: 8 }} disabled={checkedList.length <= 0} type="primary">
              确定添加
            </Button>
            <Button onClick={this.onClose}>
              取消
            </Button>
          </div>

        </div>
      </div>
    )
  }


  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({ checkedList: selectedRows })
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    const { visible, checkedList, checked, distinguishValue, goodList } = this.state
    return (
      <DyDrawer
        title="添加商品"
        placement="right"
        visible={visible}
        showFooter={true}
        onClose={this.onClose}
        className="commodityManagement addCommodity"
        confirmText="确认添加"
        footer={this.footer}
        width="846px"
      >
        <Tabs defaultActiveKey="2">
          <TabPane tab="选择商品" key="1">
            <CommoditySelect onCheckedList={this.onCheckedList} checkedList={checkedList[checked]} />

          </TabPane>
          <TabPane tab={<span>商品链接<QuestionCircleOutlined style={{ marginLeft: 5, fontSize: 12 }} /></span>} key="2">
            <Input.TextArea rows={6} value={distinguishValue} onChange={this.onDistinguish} placeholder='请粘贴商品链接，支持批量粘贴，请用英文逗号 “,” 或换行隔开，每次最多30个' />
            <div className="addCommodity-distinguish">
              <Button type="primary" onClick={this.getGoodsInfo} >识别链接</Button>
              <span>添加商品仍有问题? <span style={{ color: "#6647ff", cursor: "pointer" }} onClick={() => { window.open("https://school.jinritemai.com/doudian/web/article/101805") }}>查看帮助</span></span>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                locale={{
                  emptyText: "暂无可选商品，可在上方文本框中输入商品链接添加商品"
                }}
                columns={this.columns()}
                dataSource={goodList}
                rowKey="goodsId"
              />
            </div>
          </TabPane>
        </Tabs>
      </DyDrawer >
    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(AddCommodity)