import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

export default function (Comp) {

  const mapStateToProps = (state) => {
    return {

    }
  };

  const mapDispatchToProps = (dispatch, ownProps) => {
    /*
    *说明：dispatch:redux的方法  ownProps：容器组件的props对象
    *使用方法：
    *参数：
    */
    return {

    }
  };



  const wrapper = class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        homeLive: [
          {
            key: 'sub1',
            title: "直播管理",
            data: [
              { key: '/home-live', name: '直播中控台', },
              { key: '/home-live-SendCoupons', name: '直播间发券', },
              { key: '/home-live-commodityManagement', name: '直播商品管理', },
              { key: '/home-live-set', name: '直播设置', },
            ],
          },
          {
            key: 'sub2',
            title: "橱窗管理",
            data: [
              { key: '/home-live-displayWindow', name: '橱窗商品管理', },
            ],
          },
          {
            key: 'sub3',
            title: "直播管理",
            data: [
              { key: 'link_1', name: '直播推广', path: '' },
            ],
          },
          {
            key: 'sub4',
            title: "营销管理",
            data: [
              { key: '/home-live-redenvelope-management/anchorTicket', name: '主播券管理', },
              { key: '/home-live-redenvelope-management/redenvelopes', name: '红包管理', },
            ],
          },
          {
            key: 'sub5',
            title: "违规管理",
            data: [
              { key: '/home-live-violation-management', name: '帐号违规管理', },
            ],
          },
        ],
        liveData: [
          {
            key: 'sub1',
            title: "数据参谋",
            data: [
              { key: '/live-data', name: '直播数据', },
              { key: '5', name: '成交详情', },
            ],
          },
          {
            key: 'sub2',
            title: "订单管理",
            data: [
              { key: '4', name: '联盟订单明细', },
              { key: '1', name: '导出记录', },
            ],
          },
          {
            key: 'sub3',
            title: "账单管理",
            data: [
              { key: '2', name: '佣金分成账单', path: '' },
              { key: '3', name: '资产总览', path: '' },
            ],
          },
          {
            key: 'sub4',
            title: "电商罗盘",
            data: [],
          },
        ],
        inletMarketing: [
          {
            key: 'sub1',
            title: "首页",
            data: [
              { key: '1', name: '818策略推荐', },
              { key: '2', name: '日常版首页', },
            ],
          },
          {
            key: 'sub2',
            title: "目活动热点资讯",
            data: [
              { key: '3', name: '活动日历', },
            ],
          },
          {
            key: 'sub3',
            title: "内容营销活动",
            data: [
              { key: '4', name: '活动广场' },
              { key: '5', name: '报名记录' },
            ],
          },
          {
            key: 'sub4',
            title: "营销工具",
            data: [
              { key: '6', name: '优惠券', },
              { key: '7', name: '限时限量购', },
              { key: '8', name: '满减', },
              { key: '9', name: '购物红包', },
              { key: '10', name: '定时开售', },
              { key: '11', name: '拼团', },
              { key: '12', name: '定金预售', },
              { key: '13', name: '裂变营销', },
              { key: '14', name: '达人专属营销', },
              { key: '15', name: '多件优惠', },
              { key: '16', name: '赠品活动', },
              { key: '17', name: '抽奖活动', },
              { key: '18', name: '联合新享', },
              { key: '19', name: '措配购', },
            ],
          },
          {
            key: 'sub5',
            title: "营销风险管理",
            data: [
              { key: '20', name: '风险价格预警', },
            ],
          },
          {
            key: 'sub6',
            title: "精选联盟",
            data: [
              { key: '21', name: '首页', },
              { key: '22', name: '计划管理', },
              { key: '23', name: '联盟数据', },
              { key: '24', name: '达人广场', },
              { key: '25', name: '团长招商', },
              { key: '26', name: '达人招商', },
              { key: '27', name: '抖Link大会', },
              { key: '28', name: '达人榜单', },
              { key: '29', name: '营销设置', },
            ],
          },
          {
            key: 'sub7',
            title: "直播卖货",
            data: [
              { key: '30', name: '账号管理', },
            ],
          },
        ],
        inletPage: [
          {
            key: 'sub1',
            title: "首页",
            data: [],
          },
          {
            key: 'sub2',
            title: "店铺",
            data: [
              '店铺设置', '资质中心', '店铺装修', '装修首页', '素材中心', '支付方式设置', '子账号管理',
              '黑名单', '商家体验分', '持续经营分', '商家保障中', '心任务中心', '数据授权', '权益中心', '经营帐号管理',
            ],
          },
          {
            key: 'sub3',
            title: "商品",
            data: [
              '商品创建', '商品管理', '商品导入', '商品分组', '商品分层', '商品优化', '商品素材', '视频榜单', '抖in好物助推', '商机中心', '商品搭配', '商品榜单',
            ],
          },
          {
            key: 'sub4',
            title: "订单",
            data: [
              '订单管理', '批量发货', '快速打单', '代发管理', '评价管理', '售卖预警', '卡券管理', '订单开票', '订单信息变更', '打单发货', '极速发货', '质检报告',
            ],
          },
          {
            key: 'sub5',
            title: "售后",
            data: [
              '售后工作台', '售后小助手', '服务请求', '客服电话设置', '小额打款', '快递拦截服务',
            ],
          },
          {
            key: 'sub6',
            title: "数据",
            data: [
              '核心数据', '直播列表', '直播复盘', '合作达人', '商品概览', '实时商品', '主播分析', '自播诊断', '直播计划', '交易构成', '财务概览',
              '达播诊断', '单品分析', '场景人群', '核心人群', '服务诊断', '人群画像', '售后分析',
              '评价分析', '客服分析', '体验政策', '退款分析', '营销分析', '物流概览', '物流指数', '商品卡分析', '自营视频', '合作视频', '用户负反馈', '品类分析', '活动分析', '店铺排行榜',
               '抖音商品榜', '直播榜单', '体验分析', '达人优选', '商城分析', '搜索分析',
            ],
          },
          {
            key: 'sub7',
            title: "物流",
            data: [
              '包裹中心', '运费模板', '地址库管理', '服务商管理', '电子面单', '预约送仓', '物流服务',
            ],
          },
          {
            key: 'sub8',
            title: "资产",
            data: [
              '账户中心', '结算账单', '历史报表', '店铺保证金', '佣金收入', '发票中心', '资金账单', '资金流水明细', '返点中心', '待结算订单', '活动保证金', '金融服务',
            ],
          },
          {
            key: 'sub9',
            title: "用户",
            data: [
              '用户资产', '会员权益', '会员等级', '入口管理', '积分管理', '人群管理', '短信管理', '场景运营', '人群运营',
            ],
          },
          {
            key: 'sub10',
            title: "保障中心",
            data: [
              '报备中心', '举报中心',
            ],
          },
          {
            key: 'sub11',
            title: "服务市场",
            data: [
              '服务市场', '授权管理'
            ],
          },
          {
            key: 'sub12',
            title: "奖惩中心",
            data: [
              '防控中心', '体检中心', '申诉中心',
            ],
          },
        ],
        pagekey: 'homeLive',
      };
    }
    componentDidMount() {
      this.navAction()
    }

    navAction = () => {
      if (window.location.pathname.includes("/home-live")) {
        this.setState({
          pagekey: 'homeLive'
        })
      }
      if (window.location.pathname.includes("/live-data")) {
        this.setState({
          pagekey: 'liveData'
        })
      }
      if (window.location.pathname.includes("/inlet-marketing")) {
        this.setState({
          pagekey: 'inletMarketing'
        })
      }
      if (window.location.pathname.includes("/inlet-page")) {
        this.setState({
          pagekey: 'inletPage'
        })
      }
    }
    render() {
      return (
        <Comp
          {...this.props}
          {...this.state}
          navAction={this.navAction}
        />
      );
    }
  };
  return connect(mapStateToProps, mapDispatchToProps)(withRouter(wrapper));

}
