import React from 'react';
import { Button, Input, Modal, Table } from 'antd';
import "./index.css"
import DyDrawer from 'components/DyDrawer';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import AJAX from "api/apis";

class SendCoupons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      coupons: {
        shop: {
          id: 1,
          title: "添加店铺优惠券",
          emptyText: "暂无店铺优惠券，快去抖店后台添加优惠券吧",
          columns: [
            {
              title: '优惠券信息',
              dataIndex: 'couponName',
            },
            {
              title: '店铺名称',
              dataIndex: 'liveCouponGoodsVo',
              render: (text, row) => {
                return text.storeName
              }
            },
            {
              title: '渠道',
              dataIndex: 'name',
            },
            {
              title: '类型',
              dataIndex: 'type',
              render: (text) => {
                return text === 1 ? "商品满减券" : "商品直减券"
              }
            },
            {
              title: '领取条件',
              dataIndex: 'receiveDate',
              render: (text, row) => {
                return (
                  <div>
                    <p>{text}</p>
                    <p>每人限领{row.useCount}张</p>
                  </div>
                )
              }
            },
            {
              title: '使用期限',
              dataIndex: 'useDate',
            },
            {
              title: '数量',
              dataIndex: 'useCount',
              render: (text, row) => {
                return text + "/" + row.totalIssued
              }
            },
          ]
        },
        directional: {
          id: 2,
          title: "添加定向券到直播间",
          emptyText: "可以联系商家添加达人定向券或达人粉丝券哦~",
          columns: [
            {
              title: '优惠券信息',
              dataIndex: 'couponName',
            },
            {
              title: '店铺名称',
              dataIndex: 'couponGoodsList',
              render: (text, row) => {
                return text && text[0].storeName
              }
            },
            {
              title: '渠道',
              dataIndex: 'name',
            },
            {
              title: '类型',
              dataIndex: 'type',
              render: (text, row) => {
                return (
                  <div>
                    <div>{text === 1 ? "商品满减券" : "商品直减券"}</div>
                    <div>满{row.fillMoney ? row.fillMoney : 0}减{row.discountMoney}</div>
                  </div>
                )
              }
            },
            {
              title: '领取条件',
              dataIndex: 'namreceiveDatee',
              render: (text, row) => {
                return (
                  <div>
                    <p>{text}</p>
                    <p>每人限领{row.useCount}张</p>
                  </div>
                )
              }
            },
            {
              title: '使用期限',
              dataIndex: 'useDate',
            },
            {
              title: '数量',
              dataIndex: 'useCount',
              render: (text, row) => {
                return text + "/" + row.totalIssued
              }
            },
          ]
        },
        discount: {
          id: 3,
          title: "添加优惠券码",
          emptyText: "可联系小店商家获取优惠券",
          columns: [
            {
              title: '优惠券信息',
              dataIndex: 'couponName',
            },
            {
              title: '类型',
              dataIndex: 'type',
              with: 150,
              render: (text, row) => {
                return (
                  <div>
                    <div>{text === 1 ? "商品满减券" : "商品直减券"}</div>
                    <div>满{row.fillMoney ? row.fillMoney : 0}减{row.discountMoney}</div>
                  </div>
                )
              }
            },
            {
              title: '领取条件',
              dataIndex: 'namreceiveDatee',
              render: (text, row) => {
                return (
                  <div>
                    <p>{text}</p>
                    <p>每人限领{row.limitedCollarNum}张</p>
                  </div>
                )
              }
            },
            {
              title: '使用期限',
              dataIndex: 'useDate',
              render: (text) => {
                let srt = text
                srt = text.split("至").map(item => {
                  return item.split(" ")[0]
                })
                console.log(srt)
                return srt.join("至")
              }
            },
            {
              title: '数量',
              dataIndex: 'useCount',
              render: (text, row) => {
                return text + "/" + row.totalIssued
              }
            },
          ]
        },
      },
      couponList: [],
      selectCoupons: [],
      table: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      addloading: false,
    };
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  getAllLiveCoupon = (param = {}) => {
    let query = {
      pageSize: 10,
      pageNum: 1,
      couponStatus: 1,
      ...param
    }
    AJAX.liveCoupon.getAllLiveCoupon(query).then(res => {
      // console.log(res)
      this.setState({
        couponList: res.data?.list,
        table: {
          current: res.data.pageNum,
          pageSize: res.data.pageSize,
          total: res.data.total
        }
      })
    })
  }
  addLiveRoomCoupon = () => {
    if (this.state.addloading) return
    this.setState({ addloading: true })
    let query = {
      "couponIds": this.state.selectCoupons.map(item => item.id).join(","),
      "couponType": 3
    }
    AJAX.liveRoomCoupon.addLiveRoomCoupon(query).then(res => {
      this.onClose()
      this.props.queryRedEnvelopeList()
    })
    setTimeout(() => {
      this.setState({ addloading: false })
    }, 3000);
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        steps: [
          {
            selector: '.commodity-lately',
            content: '选择商品',
          },
        ],
        isOpen: false,
        path: "addCommodity"
      })
    }, 300);
    if (this.props.type == "discount") {
      this.getAllLiveCoupon()
    }
  };

  onClose = () => {
    this.setState({
      visible: false,
      selectCoupons: [],
      couponList: [],
      table: {
        current: 1,
        pageSize: 10,
        total: 0
      }
    });
    this.props.setReactours()
  };
  onConfirm = () => {
    this.addLiveRoomCoupon()
  };
  locale = () => {
    return {
      emptyText: (
        <div className="ShareCoupons-emptyText RedEnvelopes-emptyText">
          <span>暂无数据</span>
        </div>
      )
    }
  }
  footer = () => {
    return (
      <div className="SendCouponsModal-modal-footer">
        <div>已选择{this.state.selectCoupons.length}项</div>
        <div>
          <Button type="primary" onClick={this.onConfirm} disabled={this.state.selectCoupons >= 0} >添加到直播间</Button>
          <Button style={{ marginLeft: 20 }} onClick={this.onClose}>取消</Button>
        </div>
      </div>
    )
  }
  tableOnchange = (e) => {
    // console.log(e)
    this.getAllLiveCoupon({
      pageNum: e.current,
      pageSize: e.pageSize,
    })
  }
  render() {
    const { type } = this.props
    const { coupons, visible, couponList, table } = this.state
    let sendType = type ? type : "shop"
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({
          selectCoupons: selectedRows
        })
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    return (
      <DyDrawer
        title={coupons[sendType].title}
        className="SendCouponsModal"
        placement="right"
        visible={visible}
        closable={false}
        onClose={this.onClose}
        showFooter={true}
        footer={this.footer}
        width="878px"
      >
        {
          sendType == "discount" && (
            <div className="SendCouponsModal-search">
              <Input placeholder="请输入19位优惠券批次号(可联系小店商家获取)" />
              <Button type="primary" >获取</Button>
            </div>
          )
        }

        <Table
          columns={coupons[sendType].columns}
          dataSource={couponList}
          locale={this.locale()}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          pagination={{
            total: table.total,
            current: table.pageNum,
            pageSize: table.pageSize
          }}
          onChange={this.tableOnchange}
          rowKey="id"
        />
      </DyDrawer>
    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(SendCoupons)