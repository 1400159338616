import React from 'react';


class CustomerService extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  render() {
    return (
      <div className="customerService">
        <img src={require("@assets/images/customerService.png").default} />
        <div className="customerService-text">
          <span>联</span>
          <span>系</span>
          <span>客</span>
          <span>服</span>
        </div>
      </div>
    )
  }
}

export default CustomerService