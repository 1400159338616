import React from 'react';
import { Divider } from 'antd';
import { Link } from "react-router-dom"
import { RightOutlined, CheckOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox } from 'antd';
import "./ScanCode.css"
import getUrlData from '@utils/getUrlData';
class ScanCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountType: {
        douyin: {
          title: "抖音APP",
          color: "rgb(22,24,35)",
          tips: [
            "获得你的手机号码", "获得你的公开信息(头像、昵称、地区和性别)", "应用可通过你抖音帐号发布视频内容", "获取你发布的抖音视频数据(播放数、点赞数、评论数、分享数) "
          ],
          loginCode: true
        },
        huoshan: {
          title: "火山小视频APP",
          color: "rgb(255,42,38)",
          tips: [
            "获得你的手机号码", "获得你的公开信息(头像、昵称、地区和性别)"
          ],
          loginCode: true
        },
        toutiao: {
          title: "今日头条授权登录",
          color: "rgb(255,255,255",
          tips: [
            "获得你的手机号码", "获得你的公开信息(头像、昵称、地区和性别)"
          ],
          loginCode: false,
          phoneCode: false,
        },
        xigua: {
          title: "西瓜视频授权登录",
          color: "rgb(255,255,255)",
          tips: [
            "获得你的手机号码", "获得你的公开信息(头像、昵称、地区和性别)", "应用可通过你抖音帐号发布视频内容"
          ],
          loginCode: false,
          phoneCode: true,
        },
      },
      urlType: getUrlData("type")
    };
  }

  phoneCode = () => {
    let accountType = this.state.accountType
    accountType[this.state.urlType].phoneCode = !accountType[this.state.urlType].phoneCode
    this.setState({
      accountType,
    })
  }
  loginCode = () => {
    let accountType = this.state.accountType
    accountType[this.state.urlType].loginCode = !accountType[this.state.urlType].loginCode
    this.setState({
      accountType,
    })
  }
  homeLink=()=>{
    window.location.href = window.location.origin + "/home-live"
  }

  render() {
    const { accountType, urlType } = this.state
    return (
      <div className="scancode" >
        <div className="scancode-header" style={{ backgroundColor: accountType[urlType].color }} >
          <div className="scancode-header-logo">
            <img src={require(`@assets/images/login/scancode-${urlType}-logo.png`).default} />
          </div>
        </div>
        <div className="scancode-body">
          {
            accountType[urlType].loginCode ? (
              <div className='scancode-body-right' >
                <div>使用 <span style={{ color: "rgb(254,53,126)" }}>{accountType[urlType].title}</span> ，打开首页搜索-扫—扫</div>
                <div>安全登录，防止盗号</div>
                <img src={require("@assets/images/login/scancode-code.png").default} />
                <div style={{ cursor: "pointer" }} onClick={this.loginCode} >手机验证码登录<RightOutlined /></div>
              </div>
            ) : (
              <Form name="basic" labelCol={{ span: 0, }} wrapperCol={{ span: 20, }}>
                <Form.Item name="title"><div className="scancode-body-title" >{accountType[urlType].title}</div></Form.Item>
                <Form.Item name="username" rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}>
                  <Input size="large" placeholder="请输入手机号" />
                </Form.Item>

                <Form.Item name="password" rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}>
                  {
                    accountType[urlType].phoneCode ? (
                      <Input.Password size="large" style={{ width: "220" }} placeholder="请输入密码" />
                    ) : (
                      <div className="verification-code">
                        <Input size="large" style={{ width: "165px" }} placeholder="请输入验证码" />
                        <div>发送验证码</div>
                      </div>
                    )
                  }

                </Form.Item>

                <Form.Item name="remember" wrapperCol={{ offset: 0, span: 16, }}>
                  {/* <Checkbox>已阅读并同意“用户协议”和“隐私政策”</Checkbox> */}
                  <Button type="primary" onClick={this.homeLink} >接受并登录</Button>
                  <div>已阅读并同意“用户协议”和“隐私政策</div>
                </Form.Item>
                <Form.Item name="remember" wrapperCol={{ offset: 0, span: 16, }}>
                  <div onClick={() => { this.phoneCode() }} style={{ textAlign: "center", cursor: "pointer" }} >{accountType[urlType].phoneCode ? "验证码登录" : "帐号密码登录"}</div>
                </Form.Item>
              </Form>
            )
          }

          <Divider type="vertical" />
          <div className="scancode-body-left">
            <p>巨量引擎账户中心将获得以下权限</p>
            {
              accountType[urlType].tips.map((item, index) => {
                return <p key={index} ><CheckOutlined />{item}</p>
              })
            }

          </div>
        </div>
      </div>
    )
  }
}

export default ScanCode