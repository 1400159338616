import React from 'react';
import {
    Avatar,
    Switch,
    Empty,
    Divider,
    Input,
    Button,
    Row,
    Col,
    Typography,
    Modal,
    Alert,
    Spin
} from 'antd';
import {
    CaretUpFilled,
    DownOutlined,
    CaretDownOutlined,
    QuestionCircleOutlined,
    RightOutlined,
    EditOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import SendCoupons from "./module/SendCoupons";
import SetSeckill from "./module/SetSeckill";
import AddCommodity from './module/AddCommodity';
import SetBuyingModal from "components/SetBuyingModal";
import LiveExtension from "./module/LiveExtension";
import ShareCoupons from './module/ShareCoupons';
import RedEnvelopes from './module/RedEnvelopes';
import AddRedEnvelope from 'view/RedEnvelopeManagement/RedEnvelopes/AddRedEnvelope';
import "./index.css";
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import AJAX from "api/apis";
import LaunchSet from 'view/RedEnvelopeManagement/RedEnvelopes/launchSet';
import { getStepsLocalStorage, dafenModal } from 'utils/utils';
import cookieUtils from "utils/cookie";
import DyImage from 'components/DyImage';
const moment = require('moment');

const { Paragraph } = Typography;

class HomeLive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openVisible: false,
            consoleDate: [
                {
                    name: "实时在线人数",
                    num: 187
                },
                {
                    name: "累计观看人数",
                    num: 208
                },
                {
                    name: "新增粉丝",
                    num: 60
                },
                {
                    name: "成交金额",
                    num: 319.6
                },
                {
                    name: "成交订单数",
                    num: 8
                },
                {
                    name: "成交人数占比",
                    num: 19.99
                },
            ],
            commentDate: [
                { name: "瑾夏年华", value: "666" },
                { name: "温柔少女心", value: "牛逼" },
                { name: "隔壁王老五", value: "来了！来了！" },
                { name: "小六子", value: "感谢大哥的飞机！" },
                { name: "常山蔡徐坤", value: "666" },
                { name: "地主家的傻儿子", value: "这手表会妨碍我开法拉利么？？？" },
                { name: "只为守护你ペ", value: "来了！来了！" },
                { name: "小六子", value: "感谢大哥的飞机！" },
                { name: "别低头，皇冠会掉", value: "我已下单，迪丽热巴依然爱我！" },
                { name: "本王不退位尔等都是臣", value: "牛逼" },
                { name: "绝情姑娘", value: "来了！来了！" },
                { name: "小六子", value: "感谢大哥的飞机！" },
                { name: "猫九", value: "我已下单，迪丽热巴依然爱我！" },
                { name: "一人独醉", value: "牛逼" },
                { name: "断爱", value: "来了！来了！" },
                { name: "梅窗月明清似水", value: "感谢大哥的飞机！" },
            ],
            setBuying: false,
            deleteModalVisible: false,
            goodsList: [],
            poinit: {
                id: "",
                sellPoint: ''
            },
            delGoodsId: '',
            searchValue: '',
            goodsLoading: false,
            redInfo: {},
            redEnvelopeList: [],
            seckillGoodsId: '',
            seckillGoodsValue: {},
            num: 100,
            path: "home-live",
            closeQueryAllByLimit: false,
            redTable: {
                current: 1,
                pageSize: 10,
                total: 20
            },
        };
        this.AddCommodity = null
    }
    componentDidMount() {

        // console.log(getStepsLocalStorage(this.state.path))
        if (getStepsLocalStorage(this.state.path)) {
            this.queryAllByLimit()
            this.setReactours()
        } else {
            this.setState({
                goodsList: [
                    { "id": 21, "goodsId": 1, "goodsName": "小风扇", "goodsNumber": "AA4324324", "imgUrl": "", "goodsPrice": "1000.00", "goodsCommission": "10.00", "goodsCommissionRate": "10.00", "goodsSource": null, "goodsInventory": 20, "sellNumber": 11, "sellPoint": null, "seckillPrice": "1.00~3.00", "seckillId": 6, "seckillStatus": 2, "seckillCreateTime": "2021-09-09 17:08:40", "activityEndTime": 10, "seckillTime": 0 }
                ],
                closeQueryAllByLimit: true
            })
            this.setReactours()
        }
        if(cookieUtils.getCookie('amazon_url') == this.props.location.pathname){
            dafenModal()
        }
    }
    componentDidUpdate(nextProps) {
        // console.log(this.props.isTourOpen, this.props.isTourOpen === false, getStepsLocalStorage(this.state.path), this.state.closeQueryAllByLimit)
        if (this.props.isTourOpen === false && getStepsLocalStorage(this.state.path) && this.state.closeQueryAllByLimit) {
            this.queryAllByLimit()
            this.setState({ closeQueryAllByLimit: false })
        }
    }
    /** ====  接口APi ======= */
    queryAllByLimit = async () => {
        this.backInterval && clearInterval(this.backInterval)
        this.setState({ goodsLoading: true })
        let query = {
            pageNum: 1,
            pageSize: 100,
            goodsName: this.state.searchValue
        }
        await AJAX.homeLive.queryAllByLimit(query).then(res => {
            // console.log(res)
            let list = res.data.list
            list.forEach(item => {
                item.seckillTime = 0
            })
            this.setState({
                goodsList: list,
                total: res.data.total,
                goodsLoading: false,
            }, () => {
                this.seckillTime()
            })
        })
    }
    deleteLiveGoods = async (id) => {
        let query = {
            liveRoomGoodsId: id
        }
        await AJAX.homeLive.deleteLiveGoods(query).then(res => {
            this.queryAllByLimit()
        })
    }
    addGoodsSellPoint = async (query) => {

        await AJAX.homeLive.addGoodsSellPoint(query).then(res => {
            this.queryAllByLimit()
        })
    }
    queryRedEnvelopeList = async (param = {}) => {
        await AJAX.redEnvelopes.queryRedEnvelopeList({ ...param }).then(res => {
            // console.log(res)
            this.setState({
                redEnvelopeList: res.data.list,
                redTable: {
                    current: res.data.pageNum,
                    pageSize: res.data.pageSize,
                    total: res.data.total
                }
            })
        })
    }
    stopSeckill = async (id) => {
        await AJAX.homeLive.stopSeckill({ seckillId: id }).then(res => {
            this.queryAllByLimit()
        })
    }

    /** ====  接口APi-end ======= */
    setReactours = () => {
        this.props.reactours({
            isTourOpen: true,
            steps: [
                {
                    selector: '.live-console-shopping .ant-switch',
                    content: (
                        <div className="live-console-shopping-tip">
                            <img style={{ width: "100%" }} src={require("@assets/images/homeLive/console-shopping-tip.png").default} />
                            <div>直播间上线安心购服务啦</div>
                            <p> · 开启后，直播间将获得安心购认证，提升带货口碑</p>
                            <p> · 本场直描仅可添加安心购商品，即商品满足品质及售后保障</p>
                            <p> · 直描中添加商品后，不可关闭服务</p>
                        </div>
                    ),
                },
                {
                    selector: ".home-SendCoupons-tip",
                    content: (
                        <div>
                            <div>直播间发券！（使新功能引导）</div>
                            <p>抖音小店商家可以创建达人定向优惠券和粉丝专享券啦，可以联系小店合作商家创建哦</p>
                        </div>
                    )
                },
                {
                    selector: ".home-LiveExtension-tip",
                    content: (
                        <div>
                            <div>巨量千川全新升级!</div>
                            <p>贴合内容电商经营，让营销更省心，更高效。</p>
                        </div>
                    )
                },
                {
                    selector: ".add-commodity",
                    content: (
                        <div>
                            <div>添加商品</div>
                            <p>可以快速添加店铺、定向等更多商品</p>
                        </div>
                    )
                },
                {
                    selector: ".home-maidian",
                    content: (
                        <div>
                            <img style={{ width: "100%", minWidth: 271 }} src={require("@assets/images/homeLive/home-maidian.png").default} />
                            <div>快去设置卖点</div>
                            <p>信息将暂时给用户</p>
                        </div>
                    )
                },
            ],
            isOpen: true,
            path: "home-live"
        })
    }
    showOpen = () => {
        this.setState({ openVisible: !this.state.openVisible })
    }
    OnSendCoupons = () => {
        this.sendCoupons.showDrawer()
    }
    setBuyingOpen = (value) => {
        let poinit = this.state.poinit
        poinit.id = value.id
        poinit.sellPoint = value.sellPoint || ''
        this.setState({
            setBuying: true,
            poinit
        })
    }
    setBuyingOk = () => {
        this.setState({ setBuying: false })
        this.addGoodsSellPoint(this.state.poinit)
    }
    setBuyingCancle = () => {
        this.setState({ setBuying: false })
    }
    deleteModalOpen = (id) => {
        this.setState({ deleteModalVisible: true, delGoodsId: id })
    }
    deleteModalOk = () => {
        this.setState({ deleteModalVisible: false })
        this.deleteLiveGoods(this.state.delGoodsId)
    }
    deleteModalCancle = () => {
        this.setState({ deleteModalVisible: false })
    }
    onRedEnvelopes = () => {
        this.AddRedEnvelope.showDrawer()
    }
    onSearchValue = (e) => {
        this.setState({
            searchValue: e
        }, () => {
            this.queryAllByLimit()
        })
    }
    LaunchSet = (item) => {
        this.setState({
            redInfo: item
        }, () => { this.LaunchSets.showDrawer() })
    }
    onPoinit = (e) => {
        let poinit = this.state.poinit
        poinit.sellPoint = e
        this.setState({
            poinit
        })
    }
    redEnvelopeStatus = (e) => {
        this.queryRedEnvelopeList({ redEnvelopeStatus: e })
    }
    tableOnchange = (e) => {
        this.queryRedEnvelopeList(e)
    }
    seckillTime = () => {
        let list = this.state.goodsList
        let status = []
        this.backInterval = setInterval(() => {
            list.forEach(item => {
                // item.seckillTime += 1
                // console.log(item)
                if (item.seckillStatus == 1) {
                    status.push(item.seckillStatus)
                    let CreateTime = moment(moment(item.seckillCreateTime) + item.activityEndTime * 60 * 1000);
                    let time = CreateTime - new Date();
                    // console.log(moment(time).format("mm:ss"), time, moment(CreateTime).format("YYYY-MM-DD hh:mm:ss"))
                    if (time >= 0) {
                        item.seckillTime = moment(time).format("mm:ss")
                    } else {
                        item.seckillStatus = 2
                    }
                }
            })
            this.setState({
                goodsList: list
            })
            if (status.length == 0) {

                clearInterval(this.backInterval)
            }
        }, 1000)
    }
    linkOldData = () => {
        AJAX.score.addUserStepsRecord({ operationSteps: "/live-data" })
        this.props.history.push("/live-data")
    }
    render() {
        const {
            openVisible,
            setBuying,
            deleteModalVisible,
            consoleDate,
            commentDate,
            goodsList,
            goodsLoading,
            redInfo,
            redEnvelopeList,
            seckillGoodsId,
            seckillGoodsValue,
            poinit,
            redTable,
            total
        } = this.state
        const { userInfo } = this.props
        return (
            <div className="home-live" style={openVisible ? { height: "calc(100% + 394px)" } : {}}>
                <div className="live-console">
                    <div className="live-console-open" style={{ height: openVisible ? "460px" : "76px" }} >
                        <img className="live-console-open-img" src={require("@assets/images/homeLive/home-Avatar.jpeg").default} />
                        <div className="live-console-open-inner">
                            <div className="live-console-open-title">
                                <div className="live-console-open-title-right">
                                    <Avatar src={userInfo.photo ? userInfo.photo : require("@assets/images/homeLive/home-Avatar.jpeg").default} />
                                    <div style={{ marginLeft: 10 }}>
                                        <p>{userInfo && userInfo.liveName}</p>
                                        <p><img style={{ width: 16, height: 16 }} src={require("@assets/images/homeLive/home-live-douyin_logo.png").default} />未开播</p>
                                    </div>
                                </div>
                                {
                                    openVisible ? <CaretUpFilled onClick={this.showOpen} /> : <CaretDownOutlined onClick={this.showOpen} />
                                }
                            </div>
                            {
                                openVisible && (
                                    <div className="live-console-open-body" style={{ height: "384px" }}>
                                        <div className="live-console-notstart" >
                                            <div>您尚未开始直播</div>
                                            <p>通过开播帮助,get直播新姿势</p>
                                            <p>开播帮助<DownOutlined /></p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="live-console-shopping">
                        <p><img style={{ width: 16, height: 16, marginBottom: 2, marginRight: 5 }} src={require("@assets/images/homeLive/home-live-anxin.png").default}></img>本场直播间开启安心购服务<QuestionCircleOutlined /></p>
                        <Switch checkedChildren="开" unCheckedChildren="关" size="small" defaultChecked />
                    </div>
                    <div className="live-console-comment">
                        <h3>用户评论</h3>
                        {/* <Empty
                            image={<img style={{ width: 46, height: 36 }} src={require("@assets/images/homeLive/home-live-console-empty.png").default}></img>}
                            description="您尚未开始直播"
                        /> */}
                        <div className="live-console-comment-data">
                            <p>用户评论在赶来的路上，请稍等~</p>
                            {
                                commentDate.map((item, index) => {
                                    return <p key={index}><span>{item.name}：</span>{item.value}</p>
                                })
                            }

                        </div>
                    </div>
                    <div className="live-console-comment live-console-data">
                        <h3>
                            <strong>直播实时数据</strong>
                            <span>
                                <span style={{ marginRight: 15 }}>
                                    <img style={{ width: 19, height: 19, marginBottom: 1 }} src={require("@assets/images/homeLive/home-live-jiank.svg").default} />
                                    大屏监控
                                </span>
                                <span onClick={this.linkOldData}>查看往期数据<RightOutlined /></span>
                            </span>
                        </h3>
                        {/* <Empty
                            image={<img style={{ width: 46, height: 36 }} src={require("@assets/images/homeLive/home-live-console-empty.png").default}></img>}
                            description="您尚未开始直播"
                        /> */}
                        <div className="live-console-data-body">
                            <Row>
                                {
                                    consoleDate.map((item, index) => {
                                        return (
                                            <Col span={8} key={index}>
                                                <div>{item.num}</div>
                                                <p>{item.name}</p>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                        <Divider />
                        <div className="live-console-data-footer" >
                            <h3 style={{ fontWeight: 700 }}>直播工具</h3>
                            <ul className="live-console-data-tool">
                                <li onClick={this.OnSendCoupons} className="home-SendCoupons-tip">
                                    <img style={{ width: 42, height: 42 }} src={require("@assets/images/homeLive/home-live-data1.png").default}></img>
                                    <p>发优惠券</p>
                                </li>
                                <li onClick={() => { this.LiveExtension.showDrawer() }} className="home-LiveExtension-tip" >
                                    <img style={{ width: 42, height: 42 }} src={require("@assets/images/homeLive/home-live-data3.png").default}></img>
                                    <p>直播推广</p>
                                </li>
                                <li onClick={() => { this.ShareCoupons.showDrawer() }}>
                                    <img style={{ width: 42, height: 42 }} src={require("@assets/images/homeLive/home-live-data1.png").default}></img>
                                    <p>分享裂变券</p>
                                </li>
                                <li onClick={() => { this.RedEnvelopes.showDrawer(); this.queryRedEnvelopeList() }}>
                                    <img style={{ width: 42, height: 42 }} src={require("@assets/images/homeLive/home-live-data3.png").default}></img>
                                    <p>发红包</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="live-commodity" >
                    <div className="live-commodity-title">
                        <span>直播商品</span>
                        <div>
                            <Input.Search
                                placeholder="请输入商品名称或ID"
                                enterButton="查找"
                                allowClear
                                onSearch={this.onSearchValue}
                                style={{ width: 250 }}
                            />
                            <Button onClick={this.queryAllByLimit} >刷新</Button>
                            <Button danger onClick={() => {
                                this.AddCommodity.showDrawer()
                            }} className="add-commodity" >添加商品</Button>
                        </div>
                    </div>
                    <Spin spinning={goodsLoading} wrapperClassName="live-commodity-body" >
                        {
                            goodsList.map((item, index) => {
                                return (
                                    <div key={item.id} className="live-commodity-item"  >
                                        <Row>
                                            <Col span={3} style={{ padding: "0 20px" }} >
                                                <Input value={index + 1} />
                                            </Col>
                                            <Col span={21} >
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div className="live-commodity-item-center" >
                                                        <DyImage src={item.imgUrl}></DyImage>
                                                        <div>
                                                            <div>{item.goodsName}</div>
                                                            <div>ID：<Paragraph style={{ display: "inline-block" }} copyable>{item.goodsNumber}</Paragraph></div>
                                                            <p className="home-maidian">{item.sellPoint ? item.sellPoint : "设置卖点"} <EditOutlined onClick={() => { this.setBuyingOpen(item) }} style={{ cursor: "pointer" }} /></p>
                                                        </div>
                                                    </div>
                                                    {
                                                        item.seckillStatus == 1 ? (
                                                            <Button danger onClick={() => {
                                                                this.stopSeckill(item.seckillId)
                                                            }} >停止秒杀</Button>
                                                        ) : (
                                                            <Button danger onClick={() => {
                                                                this.setState({ seckillGoodsId: item.goodsId, seckillGoodsValue: item }, () => { this.SetSeckill.showDrawer() })
                                                            }} >设置秒杀</Button>
                                                        )
                                                    }
                                                </div>
                                                <Divider style={{ margin: "10px 0" }} />
                                                <div className="live-commodity-item-record" >
                                                    {
                                                        item.seckillStatus == 1 && (
                                                            <div className="live-commodity-item-seckill">
                                                                <span>
                                                                    <img src={require("@assets/images/homeLive/seckill.png").default} />
                                                                    秒杀中：<strong>00:{item.seckillTime}</strong>
                                                                </span>
                                                                秒杀价：￥{item.seckillPrice}
                                                                <Divider type="vertical" />
                                                            </div>
                                                        )
                                                    }

                                                    <span>售价：￥{item.goodsPrice}</span>
                                                    <Divider type="vertical" />
                                                    <span>售出/库存：{item.sellNumber}/{item.goodsInventory}</span>
                                                    <Divider type="vertical" />
                                                    <span>成交金额{item.tradeMoney}</span>
                                                    {/* <Divider type="vertical" /> */}
                                                </div>
                                            </Col>
                                        </Row>
                                        <CloseOutlined onClick={() => { this.deleteModalOpen(item.id) }} className="live-commodity-item-close" />
                                    </div>
                                )
                            })
                        }
                    </Spin>

                </div>

                <SetBuyingModal visible={setBuying} poinit={poinit} onPoinit={this.onPoinit} setBuyingOk={this.setBuyingOk} setBuyingCancle={this.setBuyingCancle} />
                <Modal
                    visible={deleteModalVisible}
                    title=""
                    closable={false}
                    className="home-live-modal"
                    footer={
                        <div
                            style={{
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={this.deleteModalOk} style={{ marginRight: 8 }} type="primary">
                                确定
                            </Button>
                            <Button onClick={this.deleteModalCancle}>
                                取消
                            </Button>
                        </div>
                    }
                >
                    <Alert message="确认从该直播间删除该商品？" type="warning" showIcon />
                </Modal>

                {/* 直播间发券 */}
                <SendCoupons setReactours={this.setReactours} onRef={(ref) => { this.sendCoupons = ref }} />
                {/* 设置秒杀 */}
                <SetSeckill goodsId={seckillGoodsId} goodsValue={seckillGoodsValue} queryAllByLimit={this.queryAllByLimit} setReactours={this.setReactours} onRef={(ref) => { this.SetSeckill = ref }} />
                {/* 添加商品 */}
                <AddCommodity setReactours={this.setReactours} queryAllByLimit={this.queryAllByLimit} onRef={(ref) => { this.AddCommodity = ref }} />
                {/* 直播推广 */}
                <LiveExtension setReactours={this.setReactours} onRef={(ref) => { this.LiveExtension = ref }} />
                {/* 分享裂变券 */}
                <ShareCoupons setReactours={this.setReactours} onRef={(ref) => { this.ShareCoupons = ref }} />
                {/* 发红包 */}
                <RedEnvelopes
                    onRedEnvelopes={this.onRedEnvelopes}
                    redEnvelopeList={redEnvelopeList}
                    LaunchSet={this.LaunchSet}
                    setReactours={this.setReactours}
                    onRef={(ref) => { this.RedEnvelopes = ref }}
                    redEnvelopeStatus={this.redEnvelopeStatus}
                    redTable={redTable}
                    tableOnchange={this.tableOnchange}
                />
                {/* 新建红包 */}
                <AddRedEnvelope queryRedEnvelopeList={this.queryRedEnvelopeList} setReactours={this.setReactours} onRef={(ref) => { this.AddRedEnvelope = ref }} />
                {/* 红包投放 */}
                <LaunchSet queryRedEnvelopeList={() => { }} setReactours={this.setReactours} onRef={(ref) => { this.LaunchSets = ref }} redInfo={redInfo} />
            </div>
        )
    }
}
// reducers中的数据 ,然后更新组件
const mapStateToProps = (state) => ({
    isTourOpen: state.reactours.isTourOpen,
    steps: state.reactours.steps,
    isOpen: state.reactours.isOpen,
    reactours: state.reactours.reactours,
    data: state,
    userInfo: state.userInfo
})
export default connect(mapStateToProps, { reactours: (value) => reactours(value) })(HomeLive)