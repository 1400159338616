import React from 'react';
import { Avatar, Button, Radio, Input, Tooltip, Checkbox } from 'antd';
import DyDrawer from 'components/DyDrawer';
import { QuestionCircleOutlined, ExclamationCircleFilled, QuestionCircleFilled, RightOutlined } from '@ant-design/icons';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';


class LiveExtension extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      targetList: [
        {
          titile: "进入直播间",
          tip: "更多人进入直播间",
          img: "LiveExtension-target1",
          key: 0,
          estimate: {
            title: "预估带来观看量",
            num: "519 ~ 973",
            budget: "617 ~ 1156"
          },
        },
        {
          titile: "直播间商品点击",
          tip: "更多人点击商品",
          img: "LiveExtension-target2",
          key: 1,
          estimate: {
            title: "预估带来商品电量",
            num: "47 ~ 233",
            budget: "617 ~ 1156"
          },
        },
        {
          titile: "直播间下单",
          tip: "更多人下单商品",
          img: "LiveExtension-target3",
          key: 2,
          estimate: {
            title: "预估带来下单量 ",
            num: "1 ~ 4",
            budget: "617 ~ 1156"
          },
        },
        {
          titile: "直播间成交",
          tip: "更多人支付订单",
          img: "LiveExtension-target4",
          key: 3,
          estimate: {
            title: "预估带来成交量 ",
            num: "1 ~ 4",
            budget: "617 ~ 1156"
          },
        },
        {
          titile: "直播间粉丝提升",
          tip: "更多人关注抖音号",
          img: "LiveExtension-target5",
          key: 4,
          estimate: {
            title: "预估带来新增粉丝量 ",
            num: "11 ~ 55",
            budget: "617 ~ 1156"
          },
        },
        {
          titile: "直播间评论",
          tip: "更多人在直播间评论",
          img: "LiveExtension-target6",
          key: 5,
          estimate: {
            title: "预估带来评论量 ",
            num: "6 ~ 30",
            budget: "617 ~ 1156"
          },
        },

      ],
      targetAction: 0,
      budgetValue: "a",
      timeValue: 1,
      planNameValue: "2021-08-18_直播_10:31:10",
      adValue: "直播带货-系统默认分组",
    };
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        steps: [
          {
            selector: '.commodity-lately',
            content: '选择商品',
          },
        ],
        isOpen: false,
        path: "LiveExtension"
      })
    }, 500);
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.setReactours()
  };
  onConfirm = () => {
    this.setState({
      visible: false,
    });
    this.props.setReactours()
  };
  onBudget = (e) => {
    let targetList = this.state.targetList
    let targetAction = this.state.targetAction
    let estimate = targetList[Number(targetAction)].estimate
    // console.log(estimate)
    switch (e.target.value) {
      case "a":
        estimate.num = "1 ~ 5"
        estimate.budget = "617 ~ 1156"
        break
      case "b":
        estimate.num = "1 ~ 8"
        estimate.budget = "1028 ~ 1927"
        break
      case "c":
        estimate.num = "2 ~ 16"
        estimate.budget = "2055 ~ 3853"
        break
      case "d":
        estimate.num = "12 ~ 82"
        estimate.budget = "1.03万 ~ 1.93万"
        break
      case "e":
        estimate.num = "25 ~ 164"
        estimate.budget = "2.06万 ~ 3.85万"
        break
      case "f":
        estimate.num = "-"
        estimate.budget = "-"
        break
    }
    targetList[Number(targetAction)].estimate = estimate
    this.setState({
      budgetValue: e.target.value,
      targetList
    })
  }

  render() {
    const { visible, targetList, targetAction, budgetValue, timeValue, planNameValue, adValue } = this.state
    return (
      <DyDrawer
        title={<div>直播带货<QuestionCircleFilled style={{ marginLeft: 10, fontSize: 14 }} /></div>}
        placement="right"
        closable={false}
        onClose={this.onClose}
        onConfirm={this.onConfirm}
        visible={visible}
        showFooter={false}
        className="LiveExtension"
        width="996px"
      >
        {/* <img style={{ width: 42, height: 42 }} src={require("@assets/images/homeLive/LiveExtension-target2.png").default}></img> */}
        <div style={{ padding: "0 20px" }}>
          <div className="LiveExtension-header">
            <div className="LiveExtension-header-top">
              <div>
                <Avatar style={{ marginRight: 10 }} src={require("@assets/images/homeLive/home-Avatar.jpeg").default} />
                牛纬服饰
                <span className="LiveExtension-header-top-jian">直播间</span>
                <span style={{ color: "#6647FF" }}>官方</span>
              </div>
              <div>
                总余额 <QuestionCircleOutlined style={{ marginLeft: 10, fontSize: 12 }} />
                <span style={{ fontSize: 18, fontWeight: 700, margin: "0 20px" }}>0.00元</span>
                <Button size="small">充值</Button>
              </div>
            </div>
            <div className="LiveExtension-header-footer">
              <ExclamationCircleFilled style={{ color: "rgb(239, 103, 90)", marginRight: 10 }} />
              账户将或已经余额不足，为避免影响到计划正常投放，请尽快完成充值。
            </div>
          </div>
          <div className="LiveExtension-header-body" >
            <div className="LiveExtension-header-body-right">
              <div className="LiveExtension-target">
                <strong>转化目标 <QuestionCircleFilled /></strong>
                <div className="LiveExtension-target-body">
                  {
                    targetList.map(item => {
                      return (
                        <div
                          className={`LiveExtension-target-item ${targetAction == item.key ? "targetAction" : ""}`}
                          key={item.key}
                          onClick={() => { this.setState({ targetAction: item.key }) }}
                        >
                          <img style={{ width: 42, height: 42 }} src={require(`@assets/images/homeLive/${item.img}.png`).default}></img>
                          <div>
                            <div>{item.titile}</div>
                            <p>{item.tip}</p>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className="LiveExtension-budget">
                <strong>总预算</strong>
                <Radio.Group defaultValue="a" value={budgetValue} onChange={this.onBudget} >
                  <Radio.Button value="a">￥300</Radio.Button>
                  <Radio.Button value="b">￥500</Radio.Button>
                  <Radio.Button value="c">￥1000</Radio.Button>
                  <Radio.Button value="d">￥5000</Radio.Button>
                  <Radio.Button value="e">￥10000</Radio.Button>
                  <Radio.Button value="f">自定义</Radio.Button>
                </Radio.Group>
                <br />
                {
                  budgetValue == "f" && <Input className="budget-border" placeholder="请输入金额" suffix="元" />
                }

              </div>
              <div className="LiveExtension-time">
                <strong>投放时长</strong>
                <p><ExclamationCircleFilled style={{ color: "#333", marginRight: 10 }} />仅直播时进行投放，投放时长与实际直播时长越接近效果越好，避免时长差异较大带来的消耗浪费过多或平均消耗过低</p>
                <Input
                  style={{ width: 100, marginTop: 10, textAlign: "center" }}
                  addonBefore={<span onClick={() => { this.setState({ timeValue: timeValue - 1 }) }}>-</span>}
                  addonAfter={<span onClick={() => { this.setState({ timeValue: timeValue + 1 }) }}>+</span>}
                  value={timeValue}
                />
              </div>
              <div className="LiveExtension-time">
                <strong>定向人群</strong>
                <Radio.Group defaultValue="a" value="a">
                  <Radio.Button value="a">智能推荐</Radio.Button>
                  <Tooltip title="更多自定义能力前往巨量千川" color="#fff" overlayInnerStyle={{ color: "#000" }}>
                    <Radio.Button value="b" disabled>自定义人群</Radio.Button>
                  </Tooltip>
                </Radio.Group>
              </div>
              <div className="LiveExtension-time">
                <strong>计划名称</strong>
                <Input
                  className="budget-border"
                  style={{ width: 400, marginTop: 0 }}
                  value={planNameValue}
                  onChange={e => { this.setState({ planNameValue: e.target.value }) }}
                  maxLength={50}
                  suffix={<span>{planNameValue.length}/50</span>}
                />
              </div>
              <div className="LiveExtension-time">
                <strong>广告组 <QuestionCircleFilled /></strong>
                <Input
                  className="budget-border"
                  style={{ width: 300, marginTop: 0 }}
                  value={adValue}
                  onChange={e => { this.setState({ adValue: e.target.value }) }}
                />
              </div>
              <div className="LiveExtension-clause">
                <Checkbox>我已阅读并同意<span style={{ color: "#6647FF" }}>《直播间引流承诺函》</span></Checkbox>
              </div>
            </div>
            <div className="LiveExtension-header-body-left">
              <div className="LiveExtension-estimate">
                <div>预估带来直播间观看量</div>
                <strong>{targetList[Number(targetAction)].estimate.budget}</strong>
                <p>{targetList[Number(targetAction)].estimate.title}</p>
                <p>{targetList[Number(targetAction)].estimate.num}</p>
                <p>实际效果与创意质量有关</p>
              </div>
              <div className="LiveExtension-douyin">
                <Avatar style={{ marginRight: 10 }} src={require("@assets/images/homeLive/home-Avatar.jpeg").default} />
                <div className="LiveExtension-douyin-title">牛纬服饰</div>
              </div>
            </div>
          </div>



        </div>
        <div className="LiveExtension-tip">
          广告投放能力由巨量
          <img style={{ width: 79, height: 20 }} src={require("@assets/images/homeLive/LiveExtension-footer.png").default}></img>
          ，更多投放方式和投放管理请前往巨量千川 <RightOutlined />
        </div>
        <div className="LiveExtension-footer">
          <Button type="primary" onClick={this.onConfirm}>保存并发布</Button>
          <Button style={{ marginLeft: 20 }} onClick={this.onClose}>取消</Button>
        </div>
      </DyDrawer>
    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(LiveExtension)