import React from 'react';
import './index.css'
import { Button, Form, Input, Tabs, Select, Table, Upload, message, Radio, Modal, Alert, Row, Col,Checkbox } from 'antd';
import DyDrawer from 'components/DyDrawer';
import { LoadingOutlined, PlusOutlined, ExclamationCircleFilled, LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
// import AInput from '@a-components/AInput';
const { TextArea } = Input;
const { TabPane } = Tabs;

class RechargeFund extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  componentDidMount(){
    // this.props.onRef(this)
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        isOpen: false,
        path: ""
      })
    }, 500);
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };
  onConfirm = () => {
    this.setState({
      visible: false,
    });
  };
  footer = () => {
    return (
      <div className="appeal-footer" >
        <div className="df ai-c jc-sb">
          <Checkbox>我已阅读并同意<span style={{color:'#6647ff'}}>《联盟平台达人主播券营销营销服务协议》</span></Checkbox>
          <div>
            <Button onClick={this.onConfirm} style={{ marginRight: 8 }} type="primary">
              提交
            </Button>
            <Button onClick={this.onClose}>
              取消
            </Button>
          </div>

        </div>
      </div>
    )
  }
  closeModalOk = () => {
    this.setState({
      visible: false,
      closeModalVisible: false
    });
  }
  closeModalCancle = () => {
    this.setState({
      visible: false,
      closeModalVisible: false
    });
  }
  redChange = e => {
    this.setState({
      red_value: e.target.value
    })
  }

  render() {
    const { visible, red_value, closeModalVisible } = this.state
    return (
      <div>
        <DyDrawer
          title="充值创券资金"
          placement="right"
          visible={visible}
          showFooter={true}
          onClose={this.onClose}
          className="rechargeFundDrawer"
          footer={this.footer}
          width={'40%'}
        >
          <div>
            <div className="df jc-sb ai-c red-sum">
              <div className="available">
                <p>可用创券资金 <QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} /></p>
                <p className="fs-18 bolds mt-6 mb-6">￥0.00</p>
              </div>
            </div>
            <div className="money-list">
              <Form
              initialValues={{
                money:100
              }}>
                <Form.Item
                  label="充值金额"
                  name="money"
                >
                  <Radio.Group>
                    <Row>
                      <Col span={6}>
                        <Radio value={100}>￥100.00</Radio>
                      </Col>
                      <Col span={6}>
                        <Radio value={500}>￥500.00</Radio>
                      </Col>
                      <Col span={6}>
                        <Radio value={1000}>￥1000.00</Radio>
                      </Col>
                      <Col span={6}>
                        <Radio value={2000}>￥2000.00</Radio>
                      </Col>
                      <Col span={6}>
                        <Radio value={5000}>￥5000.00</Radio>
                      </Col>
                      <Col span={6}>
                        <Radio value={8000}>￥8000.00</Radio>
                      </Col>
                      <Col span={19}>
                        <Radio value={0}><Input className="custom-money" prefix="￥" placeholder="自定义" /></Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </div>
          </div>
        </DyDrawer >
      </div>
    )
  }
}
export default connect((state) => state, { reactours: (value) => reactours(value) })(RechargeFund)