import React from 'react';
import { Alert, Button, Input, message, Select, Table, Typography, Row, Col, Form } from 'antd';
import DyDrawer from 'components/DyDrawer';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import AJAX from "api/apis";
const { Paragraph, Text } = Typography;

const { Option } = Select;

class ShareCoupons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      shareList: [],
      shareLoading: false
    };
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  /** ====  接口APi ======= */
  queryAllByLimit = async (param = {}) => {
    this.setState({ shareLoading: true })
    let query = {
      pageNum: 1,
      pageSize: 10,
      ...param
    }
    await AJAX.homeLive.fissionCouponQueryAllByLimit(query).then(res => {
      // console.log(res)
      this.setState({
        shareList: res.data.list,
        shareLoading: false
      })
    })
  }
  launchFissionCoupon = (row) => {
    if (row.activityStatus == 1 || row.activityStatus == 2) {
      let query = {
        "fissionCouponId": row.id,
        "openStatus": row.activityStatus == 1 ? true : false
      }
      AJAX.homeLive.launchFissionCoupon(query).then(res => {
        this.queryAllByLimit()
        message.info(res.msg)
      })
    }

  }
  /** ====  接口APi-end ======= */
  showDrawer = () => {
    this.setState({
      visible: true,
    });
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        steps: [
          {
            selector: '.commodity-lately',
            content: '选择商品',
          },
        ],
        isOpen: false,
        path: "ShareCoupons"
      })
    }, 500);
    this.queryAllByLimit()
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.setReactours()
  };
  onConfirm = () => {
    this.setState({
      visible: false,
    });
    this.props.setReactours()
  };
  columns = () => {
    return [
      {
        title: '活动名称',
        dataIndex: 'activityName',
        fixed: 'left',
        width: 250,
        render: (text, row) => {
          return (
            <div>
              <div>{text}</div>
              <div style={{ display: "flex" }}>ID：<Paragraph copyable>{row.activityNumber}</Paragraph></div>
            </div>
          )
        }
      },
      {
        title: '店铺名称',
        dataIndex: 'storeName',
        width: 200,
      },
      {
        title: '活动时间',
        dataIndex: 'activityStartTime',
        width: 200,
        render: (text, row) => {
          return (
            <div>{text}至{row.activityEndTime}</div>
          )
        }
      },
      {
        title: '优惠券使用时间',
        dataIndex: 'useDateType',
        width: 200,
        render: (text, row) => {

          let str = ''
          switch (text) {
            case 1:
              str = '与领取时间相同'
              break
            case 2:
              str = row.useStartDateTime + "至" + row.useEndDateTime
              break
            case 3:
              str = `领到券当日开始${row.restrictDay}天内有效`
              break
          }
          return str
        }
      },
      {
        title: '分享者优惠',
        dataIndex: 'sharerCouponType',
        width: 200,
        render: (text, row) => {
          return (
            <div>
              <div>减{text == 1 ? row.sharerFillMoney : row.sharerFillMoney}券</div>
              <div>店铺券</div>
            </div>
          )
        }
      },
      {
        title: '分享者使用情况',
        dataIndex: 'shareNumber',
        width: 200,
        render: (text, row) => {
          return (
            <div>
              <p>分享人数：{text}</p>
              <p>领取数量：{row.receiveNumber}</p>
              <p>限制天数：{row.restrictDay}</p>
            </div>
          )
        }
      },
      {
        title: '被分享者优惠',
        dataIndex: 'bySharerCouponType',
        width: 200,
        render: (text, row) => {
          return (
            <div>
              <div>减{text == 1 ? row.bySharerFillMoney : row.bySharerSubMoney}券</div>
              <div>店铺券</div>
            </div>
          )
        }
      },
      {
        title: '被分享者使用情况',
        dataIndex: 'totalIssued',
        width: 200,
        render: (text, row) => {
          return (
            <div>
              <p>发放：{text}</p>
              <p>领取：{row.receiveNumber}</p>
              <p>使用：{row.useNumber}</p>
            </div>
          )
        }
      },
      {
        title: '状态',
        dataIndex: 'activityStatus',
        width: 100,
        render: (text, row) => {
          return (
            <Text delete={(text === 0 || text === 3 || text === 4)}>
              {
                text === 0 ? '未开始' : text === 1 ? "待投放" : text === 2 ? "投放中" : text === 3 ? "已作废" : "已失效"
              }
            </Text>
          )
        }
      },
      {
        title: '操作',
        dataIndex: 'activityStatus',
        fixed: 'right',
        width: 100,
        render: (text, row) => {
          return (
            <Button
              type="link" disabled={(text == 0 || text == 3 || text == 4)}
              onClick={() => {
                this.launchFissionCoupon(row)
              }}
            >
              {text === 2 ? "停止投放" : "立即投放"}
            </Button>
          )
        }
      },
    ]
  }
  locale = () => {
    return {
      emptyText: (
        <div className="ShareCoupons-emptyText">
          <span>暂无数据</span>
        </div>
      )
    }
  }
  formRef = e => {
    this.form = e
  }
  searchRed = () => {
    this.form.validateFields().then(value => {
      // console.log(value)
      let obj = {
        activityName: value.name,
        storeName: value.storeName,
        activityStatus: value.status,
      }
      this.queryAllByLimit(obj)
    })
  }

  render() {
    const { visible, shareList } = this.state
    return (
      <DyDrawer
        title="分享裂变券"
        placement="right"
        closable={false}
        onClose={this.onClose}
        visible={visible}
        showFooter={false}
        className="ShareCoupons"
        width="996px"
      >
        <Alert
          message={<span>直播间分享裂变券来啦！适合商家专场直播使用。可联系合作商家设置裂变营销活动（为保证用户体验，目前仅支持全店通用券，暂不支持指定商品券），刺激用户分享直播间，帮助直播间引流，高效站外拉新。<span style={{ color: "#6647ff" }}>查看帮助中心</span></span>}
          type="warning"
          icon={<ExclamationCircleFilled />}
          showIcon={true}
        />
        <div className="ShareCoupons-form">
          <Form
            name="validate_other"
            ref={this.formRef}
          >
            <Row>
              <Col span={11} >
                <Form.Item
                  name='name'
                  label='活动名称'
                >
                  <Input style={{ width: 280, margin: "0 20px" }} placeholder="请输入活动名称或活动ID" />
                </Form.Item>
              </Col>

              <Col span={11} offset={1}>
                <Form.Item
                  name='storeName'
                  label='店铺名称'
                >
                  <Input style={{ width: 280, margin: "0 20px" }} placeholder="请输入店铺名称或店铺ID" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='status'
                  label='活动状态'
                >
                  <Select style={{ width: 280, margin: "0 20px" }}>
                    <Option value="1">未开始</Option>
                    <Option value="2">待投放</Option>
                    <Option value="3">投放中</Option>
                    <Option value="4">已作废</Option>
                    <Option value="5">已失效</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className="ShareCoupons-form-left">
            <Button type="primary" onClick={this.searchRed}>查询</Button>
            <Button onClick={() => { this.form.resetFields(); this.queryAllByLimit() }}>重置</Button>
          </div>
        </div>
        <div className="ShareCoupons-linkButton">
          <Button danger onClick={() => { window.open("https://fxg.jinritemai.com/login") }}>管理裂变券</Button>
          <Button danger onClick={() => { window.open("https://fxg.jinritemai.com/login") }}>创建裂变券</Button>
        </div>
        <Table columns={this.columns()} locale={this.locale()} scroll={{ x: 780 }} dataSource={shareList} rowKey="id" />
      </DyDrawer>
    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(ShareCoupons)