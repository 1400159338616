import React from 'react';
import moment from 'moment';
import { Button, Form, Input, Tabs, Select, Table, Upload, message, Radio, Modal, Alert, DatePicker } from 'antd';
import DyDrawer from 'components/DyDrawer';
import { LoadingOutlined, PlusOutlined, ExclamationCircleFilled, LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import AJAX from "@api/apis";
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { TabPane } = Tabs;
const hourList = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' }

]
class LaunchSet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelLoding: false,
      visible: false,
      imageUrl: [],
      imgLoading: false,
      red_value: '',
      closeModalVisible: false,
      castTimeType_value: 1,
      denominationType_value: 1
    };
  }
  formRef = e => {
    this.form = e
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        isOpen: false,
        path: ""
      })
    }, 500);
  };

  onClose = () => {
    this.setState({
      // closeModalVisible: true
      visible: false,
      castTimeType_value: 1
    });
  };
  onConfirm = () => {
    let { modelLoding } = this.state
    this.form.validateFields().then(data => {
      if (modelLoding) return;
      this.setState({ modelLoding: true })
      let { castTimeType, preheatTimeNum, preheatTimeNums, receiveStartTime } = data
      AJAX.redEnvelopes.launchRedEnvelope({
        castTimeType,
        redEnvelopeId: this.props.redInfo.redEnvelopeId,
        preheatTimeNum: castTimeType == 1 ? preheatTimeNum : preheatTimeNums,
        receiveStartTime: castTimeType == 1 ? '' : moment(receiveStartTime).format('YYYY-MM-DD HH:mm:ss'),
      }).then(res => {
        if (res.code == 1) {
          message.success(res.msg)
          this.setState({
            visible: false,
            castTimeType_value: 1
          });
          this.props.queryRedEnvelopeList()
        }else{
          setTimeout(() => {
            this.setState({ modelLoding: false })
          }, 3500);
        }
      }).catch(() => {
        setTimeout(() => {
          this.setState({ modelLoding: false })
        }, 3500);
      })
    })

  };
  closeModalOk = () => {
    this.setState({
      visible: false,
      closeModalVisible: false
    });
  }
  closeModalCancle = () => {
    this.setState({
      visible: false,
      closeModalVisible: false
    });
  }
  redChange = e => {
    this.setState({
      red_value: e.target.value
    })
  }

  castTimeTypeChange = e => { //使用有效时间切换
    if (e.target.value == this.state.castTimeType_value) return;
    this.setState({
      castTimeType_value: e.target.value
    })
  }
  denominationTypeChange = e => { //面额分配切换
    if (e.target.value == this.state.denominationType_value) return;
    this.setState({
      denominationType_value: e.target.value
    })
  }

  render() {
    const { visible, red_value, closeModalVisible, castTimeType_value, denominationType_value } = this.state
    let { redInfo } = this.props;
    return (
      <div>
        <DyDrawer
          title="投放设置"
          placement="right"
          visible={visible}
          showFooter={true}
          onClose={this.onClose}
          onConfirm={this.onConfirm}
          className="RedEnvelopesDrawer LaunchSetDrawer"
          confirmText="确定"
        // footer={this.footer}
        >
          <div>
            <div className='title-tips'>
              <ExclamationCircleFilled style={{ color: "#6647ff", marginRight: '5px' }} />
              <span>开始投放后，投放设置不可修改，请谨慎操作</span>
            </div>
            <div className="red_info">
              <p className="info">红包信息</p>
              <div className="df">
                <div style={{ width: '40%' }}>
                  <div>
                    <span className="red_title">红包名称</span>
                    <span className="fs-16">{redInfo.name}</span>
                  </div>
                  <div>
                    <span className="red_title">红包类型</span>
                    <span className="fs-16">普通红包</span>
                  </div>
                  <div>
                    <span className="red_title">领取结束时间</span>
                    <span className="fs-16">开抢后N秒</span>
                  </div>
                  <div>
                    <span className="red_title">使用有效时间</span>
                    <span className="fs-16">开抢时间  至  {redInfo.endTime ? redInfo.endTime : '--'}</span>
                  </div>
                  <div>
                    <span className="red_title">发放总量</span>
                    <span className="fs-16">1个</span>
                  </div>
                </div>
                <div style={{ width: '40%' }}>
                  <div>
                    <span className="red_title">红包ID</span>
                    <span className="fs-16">{redInfo.redEnvelopeNum}</span>
                  </div>
                  <div>
                    <span className="red_title">领取用户限制</span>
                    <span className="fs-16">全部用户可领取</span>
                  </div>
                  <div>
                    <span className="red_title">每人限领</span>
                    <span className="fs-16">1次</span>
                  </div>
                  <div>
                    <span className="red_title">总金额</span>
                    <span className="fs-16">￥{redInfo.totalAmount}</span>
                  </div>
                  <div className="df">
                    <span className="red_title">单张面额</span>
                    <div>
                      <p className="fs-16">{redInfo.denominationType == 1 ? '固定面额' : '随机面额'}  ￥{redInfo.singleDenomination}</p>
                      <p className="fs-14">用户实际领取面额系统随机产生，可能与平均面额有差异</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <Form
              name="validate_other"
              ref={this.formRef}
              initialValues={{
                castTimeType: 1,
                preheatTimeNum: 1,
                preheatTimeNums: 1
              }}
            >
              <Form.Item name="castTimeType" label="使用有效时间">
                <Radio.Group onChange={this.castTimeTypeChange}>
                  <Radio value={1}>按时间段设置</Radio>
                  <Radio value={2}>按精确时间设置</Radio>
                </Radio.Group>
              </Form.Item>
              {castTimeType_value == 1 ? <div className="discount setCoupons">
                <div className="df">
                  设置后
                  <Form.Item name="preheatTimeNum" className="custom-inp mr-6" label="" rules={[{ required: true, message: '请选择有效时期' }]}>
                    <Select options={hourList} ></Select>
                  </Form.Item>
                  分钟开抢
                </div>
                <p>按此方式设置投放时间，提交后将立即出现咋直播间开始预热</p>
              </div> : null}
              {castTimeType_value == 2 ? <div className="discount setCoupons">
                <div className="df">
                  <Form.Item name="receiveStartTime" className="custom-inp" label="" rules={[{ required: true, message: '请选择结束时间' }]}>
                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
                  </Form.Item>
                  开抢，开抢前
                  <Form.Item name="preheatTimeNums" className="custom-inp mr-6" label="" rules={[{ required: true, message: '请选择有效时期' }]}>
                    <Select options={hourList} ></Select>
                  </Form.Item>
                  分钟预热
                </div>
              </div> : null}
            </Form>
          </div>
        </DyDrawer >
        <Modal
          visible={closeModalVisible}
          title=""
          closable={false}
          centered={true}
          className="home-live-modal"
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.closeModalOk} style={{ marginRight: 8 }} type="primary">
                确定
              </Button>
              <Button onClick={this.closeModalCancle}>
                取消
              </Button>
            </div>
          }
        >
          <div>
            <Alert message="确认退出新建红包?" type="warning" showIcon />
            <p style={{ color: '#55585c', marginLeft: '35px' }}>退出后不可恢复，确定要取消吗？</p>
          </div>
        </Modal>
      </div>
    )
  }
}
export default connect((state) => state, { reactours: (value) => reactours(value) })(LaunchSet)