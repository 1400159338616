import React from 'react';
import './index.css'
import AJAX from "@api/apis";
import Appeal from './appeal'
import { Table, Button, Modal } from 'antd';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import DyImage from 'components/DyImage';
class ViolationManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      closeModalVisible: false,
      tableData: [],
      id:null,
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 10
      },
      videoUrl:null
    }
    this.paginationProps = {
      showSizeChanger: true,
      position: ['bottomCenter'],
      pageSizeOptions: [5, 10, 20, 50, 100],
      onShowSizeChange: (current, pageSize) => {
        let page = this.state.page;
        page.pageSize = pageSize;
        this.setState({
          page: page,
          loading: true
        }, () => {
          this.queryAllByLimit()
        })
      },
      onChange: (current) => {
        let page = this.state.page;
        page.pageNum = current;
        this.setState({
          page: page,
          loading: true
        }, () => {
          this.queryAllByLimit()
        })
      },
    }
  }
  componentDidMount() {
    this.setReactours();
    this.queryAllByLimit()
  }
  queryAllByLimit = () => {
    AJAX.abnormalAccountInfo.queryAllByLimit({ pageSize: this.state.page.pageSize, pageNum: this.state.page.pageNum }).then(res => {
      if (res.code == 1) {
        this.setState({
          tableData: res.data.list,
          page: {
            ...this.state.page,
            pageSize: res.data.pageSize,
            pageNum: res.data.pageNum,
            total: res.data.total
          }
        })
      }
    })
  }
  setReactours = () => {
    this.props.reactours({
      isTourOpen: false,
      isOpen: false,
      path: ""
    })
  }
  locale = () => {
    return {
      emptyText: (
        <div className="ShareCoupons-emptyText RedEnvelopes-emptyText">
          <span>暂无数据</span>
        </div>
      )
    }
  }
  render() {
    let { tableData, closeModalVisible,id,videoUrl} = this.state
    let { pageNum, pageSize, total } = this.state.page

    const columns = [
      {
        title: '违规内容',
        dataIndex: 'content',
        width:250,
        fixed: 'left',
        render: (content,item) => {
          return <div className="df ai-c">
            <DyImage style={{width:'50px',height:'50px'}} className="curs" src={item.imgUrl} onClick={() => { this.setState({ closeModalVisible: true,videoUrl:item.videoUrl }) }}></DyImage>
            <div className="df contents">
              <DyImage style={{width:'50px',height:'50px'}} src={item.imgUrl}></DyImage>
              <span style={{ lineHeight: '24px', marginLeft: '6px' }} className="text-ellipsis-2">{content}</span>
            </div>
          </div>
        }
      },
      {
        title: '违规原因',
        width:200,
        dataIndex: 'cause',
        render: (cause, item) => {
          return <div>
            <div>{cause}</div>
            <div className="col-9f">{cause}</div>
            <div className="col-9f">{item.updateTime}</div>
          </div>
        }
      },
      {
        title: '处罚结果',
        width:150,
        dataIndex: 'result',
        render: (result) => {
          let arr = result.split(",")
          console.log(result,"arr")
          return <ul>
            {arr.map((item, index) => {
              return <li key={index}>{item}</li>
            })}
          </ul>
        }
      },
      {
        title: '整改建议',
        width:200,
        dataIndex: 'advise',
        render: advise => {
          return <span>{advise ? advise : "--"}</span>
        }
      },
      {
        title: '当前状态',
        width:150,
        dataIndex: 'nowStatus',
        render: (nowStatus) => {
          return <div className="df ai-c"><span className="radius_span"></span><span>{nowStatus == 1 ? '已违规' : '--'}</span></div>
        }
      },
      {
        title: '申诉状态',
        width:150,
        dataIndex: 'appealStatus',
        render: (appealStatus) => {
          return <span>{appealStatus == 0 ? '申诉成功' : appealStatus == 1 ? '待申诉' : appealStatus == 2 ? '申述中' : appealStatus == 3 ? '申述失败' : '--'}</span>
        }
      },
      {
        title: '操作',
        width:100,
        fixed: 'right',
        dataIndex: 'id',
        render: (id,{appealStatus}) => {
          return <div className="btn">
            {appealStatus == 1 ? <p onClick={() => { this.setState({id},()=>{this.appeal.showDrawer()})}}>申诉</p> : null}
            <a href="https://school.jinritemai.com/doudian/web/article/113829" target="_blank">规则解读</a>
          </div>
        }
      },
    ];
    return (
      <div className="violationManagement">
        <p className="title">违规管理</p>
        <Table
          columns={columns}
          dataSource={tableData}
          locale={this.locale()}
          scroll={{ x: 500 }}
          pagination={{
            ...this.paginationProps,
            style: { marginTop: 36, marginBottom: 24 },
            pageNum, pageSize, total
          }} />
        <Appeal setReactours={this.setReactours} queryAllByLimit={this.queryAllByLimit} id={id} onRef={(ref) => { this.appeal = ref }} />
        <Modal
          visible={closeModalVisible}
          title="违规视频/直播"
          centered={true}
          className="violation-modal"
          footer={false}
          maskClosable={true}
          destroyOnClose={true}
          onCancel={() => { this.setState({ closeModalVisible: false }) }}
        >
          <div>
            <video
              src={require("@assets/images/manage/violation.mp4").default}
              autoPlay
              controls
              loop
            ></video>
          </div>
        </Modal>
      </div>

    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(ViolationManagement)