import React from 'react';
import { Button, Input, Modal, Table } from 'antd';
import "./index.css"
import AJAX from "api/apis";

class SendCouponsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coupons: {
        shop: {
          title: "添加店铺优惠券",
          emptyText: "暂无店铺优惠券，快去抖店后台添加优惠券吧",
          columns: [
            {
              title: '优惠券信息',
              dataIndex: 'name',
            },
            {
              title: '店铺名称',
              dataIndex: 'name',
            },
            {
              title: '渠道',
              dataIndex: 'name',
            },
            {
              title: '类型',
              dataIndex: 'name',
            },
            {
              title: '领取条件',
              dataIndex: 'name',
            },
            {
              title: '使用期限',
              dataIndex: 'name',
            },
            {
              title: '数量',
              dataIndex: 'name',
            },
          ]
        },
        directional: {
          title: "添加定向券到直播间",
          emptyText: "可以联系商家添加达人定向券或达人粉丝券哦~",
          columns: [
            {
              title: '优惠券信息',
              dataIndex: 'name',
            },
            {
              title: '店铺名称',
              dataIndex: 'name',
            },
            {
              title: '渠道',
              dataIndex: 'name',
            },
            {
              title: '类型',
              dataIndex: 'name',
            },
            {
              title: '领取条件',
              dataIndex: 'name',
            },
            {
              title: '使用期限',
              dataIndex: 'name',
            },
            {
              title: '数量',
              dataIndex: 'name',
            },
          ]
        },
        discount: {
          title: "添加优惠券码",
          emptyText: "可联系小店商家获取优惠券",
          columns: [
            {
              title: '优惠券信息',
              dataIndex: 'couponName',
              render: (text, row) => {
                return (
                  <div>
                    <p>券名：{text}</p>
                    {/* <p>店铺：牛纬服饰</p> */}
                  </div>
                )
              }
            },
            {
              title: '类型',
              dataIndex: 'type',
              with: 160,
              render: (text, row) => {
                return (
                  <div style={{ whiteSpace: "nowrap" }}>
                    <div>满{row.fillMoney ? row.fillMoney : 0}减{row.discountMoney}</div>
                    <div>{text === 1 ? "商品满减券" : "商品直减券"}</div>
                  </div>
                )
              }
            },
            {
              title: '领取条件',
              dataIndex: 'receiveDate',
              render: (text, row) => {
                return (
                  <div>
                    <p>{text}</p>
                    <p>每人限领{row.limitedCollarNum}张</p>
                  </div>
                )
              }
            },
            {
              title: '使用期限',
              dataIndex: 'useDate',
            },
            {
              title: '数量',
              dataIndex: 'useCount',
              render: (text, row) => {
                return text + "/" + row.totalIssued
              }
            },
          ]
        },
      },
      table: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      couponList: [],
      tableLoading: false,
      selectCoupons: [],
      addloading: false,
    };
  }
  componentDidMount() {
    this.getAllLiveCoupon()
  }
  getAllLiveCoupon = (param = {}) => {
    this.setState({ tableLoading: true })
    let query = {
      pageSize: this.state.table.pageSize,
      pageNum: 1,
      couponStatus: 1,
      ...param
    }
    AJAX.liveCoupon.getAllLiveCoupon(query).then(res => {
      // console.log(res)
      this.setState({
        couponList: res.data?.list,
        table: {
          current: res.data.pageNum,
          pageSize: res.data.pageSize,
          total: res.data.total
        },
        tableLoading: false
      })
    })
  }
  addLiveRoomCoupon = () => {
    if (this.state.addloading) return
    this.setState({ addloading: true })
    let query = {
      "couponIds": this.state.selectCoupons.map(item => item.id).join(","),
      "couponType": 3
    }
    AJAX.liveRoomCoupon.addLiveRoomCoupon(query).then(res => {
      this.handleCancel()
      this.props.queryRedEnvelopeList()
    })
    setTimeout(() => {
      this.setState({ addloading: false })
    }, 3000);
  }
  locale = () => {
    return {
      emptyText: (
        <div className="ShareCoupons-emptyText RedEnvelopes-emptyText">
          <span>暂无数据</span>
        </div>
      )
    }
  }
  tableOnchange = (e) => {
    // console.log(e)
    this.getAllLiveCoupon({
      pageNum: e.current,
      pageSize: e.pageSize,
    })
  }
  handleOk = () => {
    this.addLiveRoomCoupon()
  }
  handleCancel = () => {
    this.props.handleCancel()
    this.setState({ selectCoupons: [] })
  }
  footer = () => {
    const { selectCoupons } = this.state
    return (
      <div className="SendCouponsModal-modal-footer">
        <div>已选择{selectCoupons && selectCoupons.length}项</div>
        <div>
          <Button type="primary" onClick={this.handleOk} >添加到直播间</Button>
          <Button onClick={this.handleCancel}>取消</Button>
        </div>
      </div>
    )
  }
  render() {
    const { visible, type } = this.props
    const { coupons, couponList, table, tableLoading } = this.state
    let sendType = type ? type : "shop"
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({
          selectCoupons: selectedRows
        })
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    return (
      <Modal
        title={coupons[sendType].title}
        visible={visible}
        className="SendCouponsModal"
        onOk={this.props.handleOk}
        onCancel={this.props.handleCancel}
        width="1000px"
        footer={this.footer()}
        destroyOnClose={true}
      >
        {
          sendType == "discount" && (
            <div className="SendCouponsModal-search">
              <Input placeholder="请输入19位优惠券批次号(可联系小店商家获取)" />
              <Button type="primary" >获取</Button>
            </div>
          )
        }

        <Table
          columns={coupons[sendType].columns}
          dataSource={couponList}
          locale={this.locale()}
          onChange={this.tableOnchange}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          loading={tableLoading}
          rowKey="id"
          pagination={{
            total: table.total,
            current: table.pageNum,
            pageSize: table.pageSize
          }}
        />
      </Modal>
    )
  }
}

export default SendCouponsModal