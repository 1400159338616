import React from 'react';
import { connect } from 'react-redux';
import { reactours } from "store/actions/reactours";
import { Carousel, Alert, Tabs } from 'antd';
import { box1, box2, box3, box4, box5 } from './dome'
import './index.less'


class InletMarketing extends React.Component {
  componentDidMount() {
    this.setReactours()
  }
  setReactours = () => {
    this.props.reactours({
      isTourOpen: true,
      steps: [
        {
          selector: '.zhibozhongkong',
          content: <div style={{margin:'5px 0'}}>点击进入直播中控</div>
        },
      ],
      isOpen: true,
      path: "Inlet-marketing"
    })
  }
  render() {
    return (
      <div className='InletMarketing'>
        <div className='left'>
          <Carousel autoplay dots={false} >
            <div className='page_banner'>
              <img src={require("@assets/images/inlet/markeing_banner (1).jpeg").default} ></img>
            </div>
            <div>
              <img src={require("@assets/images/inlet/markeing_banner (1).jpg").default} ></img>
            </div>
            <div>
              <img src={require("@assets/images/inlet/markeing_banner (2).jpg").default} ></img>
            </div>
          </Carousel>
          <div dangerouslySetInnerHTML={{ __html: box1 }}></div>
          <div dangerouslySetInnerHTML={{ __html: box2 }}></div>
        </div>
        <div className='right'>
          <div dangerouslySetInnerHTML={{ __html: box3 }}></div>
          <div dangerouslySetInnerHTML={{ __html: box4 }}></div>
          <div dangerouslySetInnerHTML={{ __html: box5 }}></div>
        </div>

      </div>
    )
  }
}

// reducers中的数据 ,然后更新组件
const mapStateToProps = (state) => ({
  isTourOpen: state.reactours.isTourOpen,
  steps: state.reactours.steps,
  isOpen: state.reactours.isOpen,
  reactours: state.reactours.reactours,
  data: state,
  userInfo: state.userInfo
})

export default connect(mapStateToProps, { reactours: (value) => reactours(value) })(InletMarketing)