
export const reactours = (reactours = {
    isTourOpen: true,
    steps: [
        {
            selector: '.app-main',
            content: '开始创建',
        },
    ],
    isOpen: false
}) => {
    return {
        type: 'REACT-TOUR',
        reactours
    }
}

export const setSteps = (steps) => {
    return (dispatch) => {
        dispatch({
            type: 'REACT-TOUR',
            steps
        })
    }
}

