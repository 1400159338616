import React from 'react';


class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    render() {
        return (
            <div>ere</div>
        )
    }
}

export default Register