import React, { useRef, useEffect, useCallback, useState } from "react";
import cookieUtils from "utils/cookie";
import "./App.css";
import "./index.css";
import AJAX from "api/apis";
import { connect } from "react-redux";
import getUrlData from "@/utils/getUrlData";
import { IntlProvider } from "react-intl";
import lang from "locales/index";
import AppChildren from "view/AppChildren/AppChildren";



function App({ language }) {
  let [isSynchronization, setIsSynchronization] = useState(false)
  useEffect(() => {
    getcookies();
  }, []);
  let getcookies = useCallback(() => {
    // console.log(window.location.href)
    let token = getUrlData('amazon_token')
    let practiceStoreId = getUrlData('practiceStoreId');
    let authorize_key = getUrlData('authorize_key');
    let source = getUrlData('source');
    let sourceCookie = cookieUtils.getCookie('source')
    if (getUrlData('process_id')) cookieUtils.setCookie('process_id', getUrlData('process_id'));
    if (getUrlData('process_name')) cookieUtils.setCookie("process_name", getUrlData('process_name'));
    if (getUrlData('amazon_url')) cookieUtils.setCookie('amazon_url', getUrlData('amazon_url'));
    localStorage.setItem('stepList', JSON.stringify([]))
    if (!source) {
      setIsSynchronization(true)
      return
    }
    if (source != sourceCookie) {  //判断来源是否相同
      cookieUtils.setCookie("live_streaming_token", "", -1)
      cookieUtils.setCookie('source', source ? source : '')
    }
    if (source && practiceStoreId) {
      cookieUtils.setCookie('practiceStoreId', practiceStoreId)
      loginToken(authorize_key)
    } else {
      cookieUtils.setCookie('practiceStoreId', '');
      setIsSynchronization(true)
    }
    // if(authorize_key) loginToken(authorize_key)
  }, [])

  // 验证token,成功返回新token，存入cookies中
  let loginToken = useCallback((token) => {
    AJAX.login.loginToken({ token }).then((res) => {
      if (res.code == 1) {
        cookieUtils.setCookie('live_streaming_token', res.data)
        setIsSynchronization(true)
      }
    })
  }, [])
  // 接受父页面发来的信息
  window.addEventListener("message", function (event) {
    var data = event.data;
    switch (data.cmd) {
      case 'getFormJson':
        // 处理业务逻辑
        let amanon_token = cookieUtils.getCookie('amazon_token')
        window.parent.postMessage({
          cmd: 'returnHeight',
          params: {
            success: true,
            data: amanon_token
          }
        }, '*');
        break;
    }
  });

  return isSynchronization ? <IntlProvider locale={language} messages={lang[language]}>
    <div className="purposeTipsBox">
      <img src={require('@/assets/images/purposeTipsImg.png').default} />
      <span>以下抖音模拟实操只为提供学习用途，请勿使用真实资料操作（模拟功能不会和真实抖音平台任何资料产生关联）</span>
    </div>
    <AppChildren />
  </IntlProvider> : <div></div>
}

const mapStateToProps = ({ language }) => {
  return { language };
};

export default connect(mapStateToProps)(App);
