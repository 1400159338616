import React from "react";
import axios from "axios";
import { message, Button, Modal } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons';
import { stepControl } from "../utils/PublicLibrary";
import { baseURL } from "./config";
import cookieUtils from "utils/cookie";
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true


export default {
  baseURL,

  ajaxs(getUrl, params, methods, isShow = '') {
    return new Promise((resolve, reject) => {
      // let data = Object.assign({}, params);
      let data = params;

      let axiosParams = {
        url: this.baseURL + getUrl,
        method: methods ? methods : "POST",
        timeout: 1000 * 60 * 5,
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      //添加参数
      if (methods === "GET") {
        Object.defineProperty(axiosParams, "params", {
          value: data,
          enumerable: true,
        });
      } else if (methods === "PUT") {
        Object.defineProperty(axiosParams, "data", {
          value: data,
          enumerable: true,
        });
      } else if (methods === "DELETE") {
        Object.defineProperty(axiosParams, "params", {
          value: data,
          enumerable: true,
        });
      } else {
        Object.defineProperty(axiosParams, "data", {
          value: data,
          enumerable: true,
        });
      }
      axios(axiosParams)
        .then(
          (res) => {
            if (res.data.code === 1 || res.data.code === -100) {
              if (isShow == window.location.pathname) {
                const modal = Modal.info();
                modal.update({
                  centered: true,
                  footer: false,
                  className: 'dafen',
                  width: '330px',
                  content: (
                    <div className="expire-modal-content">
                      <div className="expire-modal-content-text">
                        <div className="p-24">
                          <div className="scoreTipsText">
                            {/* <img src="../../../assets/images/public/maskjg.png" /> */}
                            <ExclamationCircleFilled />
                            <span>实操流程《{cookieUtils.getCookie("process_name")}》已完成，是否进行打分？</span>
                          </div>
                        </div>
                      </div>
                      <div className="expire-modal-btn">
                        <Button type="primary" className="cancelBtn" onClick={() => { modal.destroy(); resolve(res.data); }} data="hovers-c">取消</Button>
                        <Button type="primary" onClick={() => {
                          window.parent.postMessage({
                            cmd: 'amazonDialog',
                            params: {
                              success: true,
                              data: true
                            }
                          }, '*');
                          modal.destroy();
                          resolve(res.data);
                        }} data="hovers-7">进行打分</Button>
                      </div>
                    </div>
                  )
                });
              } else {
                resolve(res.data);
              }
            } else if (res.data.code === -200) {
              stepControl(res.data.data.data, res.data.data.extend);
            } else if (res.data.code === 401) {
              // 退出登录状态
              message.warning(res.data.msg);
              if (window.location.pathname != '/login') {
                window.location.href = "/login?propsRouter=" + window.location.pathname;
              }
            } else {
              if (res.data.msg) message.warning(res.data.msg);
              reject(res.data);
            }
          },
          (err) => {
            console.error("哎呀呀，后台系统出错啦！");
          }
        )
        .catch((err) => {
          console.error("哎呀呀，前端系统出错啦！");
        });
    });
  },

  //文件上传
  uploadFile(getUrl, params, name = "file") {
    return new Promise((resolve, reject) => {
      let param = new FormData(); // 创建form对象
      param.append(name, params);
      let config = {
        url: this.baseURL + getUrl,
        data: param,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios(config)
        .then(
          (res) => {
            console.log(res, "res");
            if (res.data.code === 1) {
              resolve(res.data);
            } else {
              message.error(res.data.message);
              reject(res.data);
            }
          },
          (err) => {
            reject(err);
          }
        )
        .catch((err) => {
          throw new Error(err);
        });
    });
  },
};
