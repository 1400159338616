import React from 'react';
import { Button, Typography, Input, Tabs, Select, Table } from 'antd';
import DyDrawer from 'components/DyDrawer';
import { QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
import AJAX from "api/apis";
import { goodsSource } from 'utils/utils';
import DyImage from 'components/DyImage';
const { Paragraph } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

class AddCommodity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      checkedList: [],
      windowGoodsList: [],
      goodsIdList: [],
      formSourceValue: "",
      goodsnameValue: '',
      tableLoading: false,
      distinguishValue: "",
      active: "1",
      getGoodsInfoLoading: false,
    };
  }
  /** ====   接口-    ========== */
  getShopWindowGoodsList = async () => {
    const { formSourceValue, goodsnameValue } = this.state
    this.setState({ tableLoading: true })
    let query = {
      flag: 0,
      goodsSource: formSourceValue,
      goodsName: goodsnameValue
    }
    await AJAX.displayWindow.getShopWindowGoodsList(query).then(res => {
      // console.log(res)
      this.setState({
        windowGoodsList: res.data.list
      })
    })
    this.setState({ tableLoading: false })
  }
  getGoodsInfo = () => {
    if (this.state.getGoodsInfoLoading) return
    this.setState({ getGoodsInfoLoading: true })
    let goodsIds = this.state.distinguishValue
    goodsIds = goodsIds.includes(',') ? goodsIds.split(',') : goodsIds.split('\n')
    goodsIds = goodsIds.map(item => {
      return item.split('=')[1]
    })
    goodsIds = goodsIds.filter(function (s) {
      return s && s.trim(); // 注：IE9(不包含IE9)以下的版本没有trim()方法
    });
    goodsIds = goodsIds.join(",")
    AJAX.displayWindow.getGoodsDetail({ goodsIds }).then(res => {
      // console.log(res)
      this.setState({
        windowGoodsList: res.data,
        checkedList: [],
        goodsIdList: []
      })
    })
    setTimeout(() => {
      this.setState({ getGoodsInfoLoading: false })
    }, 2000);
  }
  addLiveRoomGoods = () => {
    let query = {}
    if (this.state.active == "1") {
      query = {
        goodsIds: this.state.checkedList.map(item => item.goodsId).join(',')
      }
    }
    if (this.state.active == "2") {
      query = {
        goodsIds: this.state.goodsIdList.map(item => item.goodsId).join(',')
      }
    }
    if (this.state.addLoading) return
    this.setState({ addLoading: true })
    AJAX.homeLive.addLiveRoomGoods(query).then(res => {
      // console.log(res)
      this.onClose()
      this.props.queryAllByLimit()
    })
    setTimeout(() => {
      this.setState({ addLoading: false })
    }, 2000);
  }
  /** ====   接口-end    ========== */
  columns = () => {
    return [
      {
        title: '商品信息',
        dataIndex: 'goodsName',
        render: (text, row, index) => {
          return (
            <div className="commodityManagement-table-name">
              <DyImage style={{ width: 62, height: 62 }} src={row.imgUrl}></DyImage>
              <div className="commodityManagement-table-name-center">
                <div><Paragraph ellipsis={{ rows: 2, expandable: false }}>{row.goodsName}</Paragraph></div>
                {/* <div>ID：{row.id}</div> */}
                <div><ExclamationCircleOutlined />新手店铺</div>
              </div>
            </div>
          )
        },
      },
      {
        title: '商品ID',
        dataIndex: 'goodsNumber',
      },
      {
        title: '商品价格',
        dataIndex: 'goodsPrice',
        render: (text) => "￥" + text
      },
      {
        title: '商品来源',
        dataIndex: 'goodsSource',
        render: (text) => {
          return goodsSource(String(text))
        }
      },
    ]
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  showDrawer = () => {
    this.setState({
      visible: true,
      checkedList: [],
      windowGoodsList: [],
      goodsIdList: [],
    },()=>{
      this.getShopWindowGoodsList()
    });
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        isOpen: false,
        path: ""
      })
    }, 300);
  };

  onClose = () => {
    this.setState({
      visible: false,
      distinguishValue: ''
    });
    this.props.setReactours()
  };
  onConfirm = () => {
    this.addLiveRoomGoods()
  };
  onCheckedList = (list, name) => {
    this.setState({
      checkedList: list,
      checked: name
    })
  }
  onGoodSource = (e) => {
    this.setState({ formSourceValue: e })
  }
  onGoodsname = (e) => {
    this.setState({ goodsnameValue: e.target.value })
  }
  formReset = () => {
    this.setState({ goodsnameValue: "", formSourceValue: "" }, () => {
      this.getShopWindowGoodsList()
    })
  }
  onDistinguish = (e) => {
    this.setState({
      distinguishValue: e.target.value
    })
  }
  activeKeyChange = (e) => {
    this.setState({
      active: e,
      checkedList: [],
      goodsIdList: []
    })
    if (e == "1") this.getShopWindowGoodsList()
    if (e == "2") this.setState({ windowGoodsList: [] })
  }
  footer = () => {
    const { checkedList, goodsIdList, active } = this.state
    // console.log(checkedList, checked,checkedList[checked])
    let leng = active == "1" ? checkedList.length : goodsIdList.length

    return (
      <div className="addCommodity-footer" >
        <div className="addCommodity-footer-right"><span>已选{leng}/100</span>新加商品将展示在商品列表最前面，<span onClick={() => {
          window.location.href = window.location.origin + "/home-live-set"
        }} style={{ color: "#6647ff" }}>修改添加位詈逻辑</span></div>
        <div>
          <Button onClick={this.onConfirm} style={{ marginRight: 8 }} disabled={checkedList.length <= 0 && goodsIdList.length <= 0} type="primary">
            添加
          </Button>
          <Button onClick={this.onClose}>
            取消
          </Button>
        </div>
      </div>
    )
  }


  render() {
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({ checkedList: selectedRows })
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    const rowSelection1 = {
      onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        this.setState({ goodsIdList: selectedRows })
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    const { visible, windowGoodsList, formSourceValue, goodsnameValue, tableLoading, distinguishValue, active } = this.state
    return (
      <DyDrawer
        title="添加商品"
        placement="right"
        visible={visible}
        showFooter={true}
        onClose={this.onClose}
        className="addCommodity-table commodityManagement addCommodity"
        confirmText="确认添加"
        footer={this.footer}
        width="846px"
      >
        <Tabs defaultActiveKey="1" activeKey={active} onChange={this.activeKeyChange}>
          <TabPane tab="橱窗商品" key="1">
            {/* <CommoditySelect onCheckedList={this.onCheckedList} checkedList={checkedList[checked]} list={list} /> */}
            <div className="displayWindow">
              <div className="displayWindow-header">
                <span className="displayWindow-header-lable">商品ID/关键词</span><Input value={goodsnameValue} onChange={this.onGoodsname} style={{ width: 210 }} placeholder="请输入商品ID/关键词" />
                <span className="displayWindow-header-lable">商品来源</span>
                <Select onChange={this.onGoodSource} value={formSourceValue} defaultValue="all" style={{ width: 220 }}>
                  <Option value="">全部</Option>
                  <Option value="1">淘宝</Option>
                  <Option value="2">小店</Option>
                  <Option value="3">京东</Option>
                  <Option value="4">考拉海购</Option>
                  <Option value="5">唯品会</Option>
                  <Option value="6">苏宁易购</Option>
                  <Option value="7">洋码头</Option>
                </Select>
                <div>
                  <Button type="primary" onClick={() => { this.getShopWindowGoodsList() }}>查询</Button>
                  <Button style={{ marginLeft: 20 }} onClick={this.formReset} >重置</Button>
                </div>
              </div>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={this.columns()}
                dataSource={windowGoodsList}
                rowKey="id"
                loading={tableLoading}
              />
            </div>
          </TabPane>
          <TabPane tab={<span>商品链接<QuestionCircleOutlined style={{ marginLeft: 5, fontSize: 12 }} /></span>} key="2">
            <Input.TextArea rows={6} value={distinguishValue} onChange={this.onDistinguish} placeholder='请粘贴商品链接，支持批量粘贴，请用英文逗号 “,” 或换行隔开，每次最多30个' />
            <div className="addCommodity-distinguish">
              <Button type="primary" onClick={this.getGoodsInfo}>识别链接</Button>
              <span>添加商品仍有问题? <span style={{ color: "#6647ff" }}>查看帮助</span></span>
              <Table
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection1,
                }}
                locale={{
                  emptyText: "暂无可选商品，可在上方文本框中输入商品链接添加商品"
                }}
                columns={this.columns()}
                dataSource={windowGoodsList}
                rowKey="goodsId"
              />
            </div>
          </TabPane>
        </Tabs>
      </DyDrawer >
    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(AddCommodity)