import React from 'react';
import './index.css'
import { Button, Form, Input, Tabs, Select, Table, Upload, message, Radio, Modal, Alert } from 'antd';
import DyDrawer from 'components/DyDrawer';
import { LoadingOutlined, PlusOutlined, ExclamationCircleFilled, LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
// import AInput from '@a-components/AInput';
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';
const { TextArea } = Input;
const { TabPane } = Tabs;

class WithdrawalFunds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      imageUrl: [],
      imgLoading: false,
      red_value: '',
      closeModalVisible: false
    };
  }
  componentDidMount(){
    // this.props.onRef(this)
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        isOpen: false,
        path: ""
      })
    }, 500);
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  onConfirm = () => {
    this.setState({
      visible: false,
    });
  };
  closeModalOk = () => {
    this.setState({
      visible: false,
      closeModalVisible: false
    });
  }
  closeModalCancle = () => {
    this.setState({
      visible: false,
      closeModalVisible: false
    });
  }
  redChange = e => {
    this.setState({
      red_value: e.target.value
    })
  }

  render() {
    const { visible, red_value, closeModalVisible } = this.state
    return (
      <div>
        <DyDrawer
          title='提现创券资金'
          placement="right"
          visible={visible}
          showFooter={true}
          onConfirm={this.onConfirm}
          onClose={this.onClose}
          className="withdrawalFunds"
          confirmText="提交"
          width={'40%'}
        >
          <div>
            <div className='title-tips'>
              <ExclamationCircleFilled style={{ color: "#6647ff", marginRight: '5px' }} />
              <span>提现资金需要1~2个工作日到账，你可随时在资金明细查看最新进度</span>
              {/* <span style={{ color: "#6647ff", marginLeft: '10px' }}>查看详情</span> */}
            </div>
            <Form
              name="withdrawalFunds-form"
              onFinish={this.onFinish}
            >
              <Form.Item label="姓名">
                <Input placeholder="请输入支付宝账号真实姓名" />
              </Form.Item>
              <Form.Item label="支付宝账户">
                <Input placeholder="请输入支付邮箱/手机号码" />
              </Form.Item>
              <Form.Item label="提现金额" extra={<div>可提现额度<QuestionCircleOutlined style={{ color: '#ccc', margin: '0 2px' }} />￥0.00<span style={{color:'#6647ff',marginLeft: '4px'}}>全部提现</span></div>}>
                <Input placeholder="请输入提现资金" />
              </Form.Item>
              <Form.Item label="手机号" extra="仅支持验证码发送到抖音绑定手机号">
                <Input placeholder="请输入支付邮箱/手机号码" disabled />
              </Form.Item>
              <Form.Item label="验证码">
                <Input suffix={<span style={{color:'#6647ff'}}>获取验证码</span>} />
              </Form.Item>
            </Form>
          </div>
        </DyDrawer >
        <Modal
          visible={closeModalVisible}
          title=""
          closable={false}
          centered={true}
          className="home-live-modal"
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.closeModalOk} style={{ marginRight: 8 }} type="primary">
                确定
              </Button>
              <Button onClick={this.closeModalCancle}>
                取消
              </Button>

            </div>
          }
        >
          <div>
            <Alert message="确认从该直播间删除该商品" type="warning" showIcon />
            <p style={{ color: '#55585c', marginLeft: '35px' }}>退出后不可恢复，确定要取消吗？</p>
          </div>
        </Modal>
      </div>
    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(WithdrawalFunds)
