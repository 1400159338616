import React from 'react';
import moment from 'moment';
import { Button, Form, Input, Tabs, Select, Table, Upload, message, Radio, Modal, Alert, Checkbox, DatePicker } from 'antd';
import DyDrawer from 'components/DyDrawer';
import { LoadingOutlined, PlusOutlined, ExclamationCircleFilled, LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { reactours } from "store/actions/reactours";
import AJAX from "@api/apis";
import { connect } from 'react-redux';
// import AInput from '@a-components/AInput';
import DyImage from 'components/DyImage';
import { number } from 'echarts';
const { TextArea } = Input;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const CheckboxGroup = Checkbox.Group;

const hourList = [
  { value: 1, label: '1小时' },
  { value: 2, label: '2小时' },
  { value: 3, label: '3小时' },
  { value: 4, label: '4小时' },
  { value: 5, label: '5小时' },
  { value: 6, label: '6小时' },
  { value: 7, label: '7小时' },
  { value: 8, label: '8小时' },
  { value: 9, label: '9小时' },
  { value: 10, label: '10小时' },
  { value: 11, label: '11小时' },
  { value: 12, label: '12小时' }
]
class AddAnchorTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      modelLoading: false,
      imageUrl: [],
      imgLoading: false,
      red_value: '',
      data: [],
      signOut_Visible: false,
      closeModalVisible: false,
      money_group_value: 1,
      checkeds: false,
      money_group_text: '内，可领券可用券',
      windowGoodsList: [],
      indeterminate: false,
      checkAll: false,
      checkedList: [],
      checkedListErr: false,
      checkedProInfo: [],
      discountMoney: null,
      totalIssued: null,
      fillMoney: null,
      types: 1,
      page: {
        pageNum: 1,
        pageSize: 5,
        total: 5
      },
      goodsName: ''
    };
    this.paginationProps = {
      showSizeChanger: true,
      position: ['bottomCenter'],
      pageSizeOptions: [5, 10, 20, 50, 100],
      onShowSizeChange: (current, pageSize) => {
        let page = this.state.page;
        page.pageSize = pageSize;
        this.setState({
          page: page,
          loading: true
        }, () => {
          this.addProduct()
        })
      },
      onChange: (current) => {
        let page = this.state.page;
        page.pageNum = current;
        this.setState({
          page: page,
          loading: true
        }, () => {
          this.addProduct()
        })
      },
    }
  }
  formRef = e => {
    this.form = e
  }
  componentDidMount = () => {
    this.props.onRef(this)
  }
  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }
  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  }

  disabledDateTime() {
    return {
      disabledHours: () => this.range(0, 24).splice(4, 20),
      disabledMinutes: () => this.range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: true,
        isOpen: false,
        path: "/anchorTicket",
        steps: [
          {
            selector: ".AddAnchorTicketDrawer .ant-form",
            content: '填写主播券信息'
          },
          {
            selector: ".AddAnchorTicketDrawer .product-box .ant-btn",
            content: '选择参与优惠的商品'
          },
          {
            selector: ".AddAnchorTicketDrawer .ant-drawer-footer .ant-btn-primary",
            content: '点击确认添加'
          },
        ],
      })
    }, 500);
  };


  onClose = () => {
    this.setState({
      types: 1,
      goodsName: '',
      money_group_value: 1,
      checkedProInfo: [],
      red_value: '',
      signOut_Visible: true,
      checkedListErr: false,
    });
    this.props.setReactours()
  };
  onConfirm = () => {
    let { checkedList, modelLoading } = this.state;
    this.form.validateFields().then(data => {
      if (modelLoading) return;
      this.setState({ modelLoading: true })
      let {
        couponName,
        discountMoney,
        fillMoney,
        limitedCollarNum,
        receiveCouponTime,
        receiveRestrict,
        totalIssued,
        type,
        useCouponTime,
        useEffectiveTimeType,
        times
      } = data
      if (!checkedList.length) {
        this.setState({ modelLoading: false, checkedListErr: true })
        return
      }
      AJAX.liveCoupon.addLiveCoupon({
        goodsId: checkedList,
        couponName,
        discountMoney,
        fillMoney: fillMoney ? fillMoney : '',
        limitedCollarNum,
        totalIssued,
        type,
        receiveRestrict,
        useEffectiveTimeType,
        useCouponTime: useCouponTime ? useCouponTime : '',
        receiveCouponTime: useEffectiveTimeType == 1 ? receiveCouponTime : '',
        receiveStartTime: useEffectiveTimeType == 2 ? moment(times[0]).format('YYYY-MM-DD HH:mm:ss') : '',
        receiveEndTime: useEffectiveTimeType == 2 ? moment(times[1]).format('YYYY-MM-DD HH:mm:ss') : '',
      }).then(res => {
        if (res.code == 1) {
          this.props.getAllLiveCoupon()
          this.setState({
            visible: false,
            types: 1,
            money_group_value: 1,
            goodsName: '',
            checkedProInfo: [],
            modelLoading: false,
            checkedList: [],
            red_value: '',
            indeterminate: false,
          });
          this.props.setReactours()
        } else {
          setTimeout(() => {
            this.setState({ modelLoading: false })
          }, 2000);
        }
      }).catch(() => {
        setTimeout(() => {
          this.setState({ modelLoading: false })
        }, 2000);
      })
    }).catch(() => {
      if (!checkedList.length) {
        this.setState({ modelLoading: false, checkedListErr: true })
      } else {
        this.setState({ checkedListErr: false })
      }
    })

  };
  closeModalOk = () => {
    let { checkedList, windowGoodsList } = this.state;
    let arr = [];
    for (let ids of checkedList) {
      for (let items of windowGoodsList) {
        if (ids == items.goodsId) arr.push(items)
      }
    }
    this.setState({
      checkedProInfo: arr,
      goodsName: null,
      closeModalVisible: false,
      page: {
        ...this.state.page,
        pageNum: 1
      }
    });
  }
  closeModalCancle = () => {
    this.setState({
      checkedList: [],
      indeterminate: false,
      checkAll: false,
      page: {
        ...this.state.page,
        pageNum: 1
      },
      goodsName: null,
      closeModalVisible: false
    });
  }
  out_closeModalOk = () => {
    this.setState({
      visible: false,
      signOut_Visible: false,
      checkedList: [],
      indeterminate: false,
      checkAll: false
    });
  }
  out_closeModalCancle = () => {
    this.setState({
      signOut_Visible: false
    });
  }
  addProduct = () => {
    let { goodsName } = this.state;
    let { pageSize, pageNum } = this.state.page
    AJAX.displayWindow.getShopWindowGoodsList({ goodsName, pageSize, pageNum }).then(res => {
      this.setState({
        windowGoodsList: res.data.list,
        closeModalVisible: true,
        page: {
          ...this.state.page,
          total: res.data.total
        }

      })
    })
  }
  redChange = e => {
    this.setState({
      red_value: e.target.value
    })
  }
  locale = () => {
    return {
      emptyText: (
        <div className="ShareCoupons-emptyText RedEnvelopes-emptyText">
          <span>暂无数据</span>
        </div>
      )
    }
  }
  radioChange = e => {
    if (e.target.value == this.state.money_group_value) return;
    this.setState({
      money_group_value: e.target.value,
      checkeds: false
    })
  }
  checkboxTimeChange = e => {
    this.setState({
      checkeds: e.target.checked,
      money_group_text: e.target.checked == false ? '内，可领券可用券' : '内，可领券'
    })
  }
  onCheckAllChange = e => {
    let { checkedList, checkAll, windowGoodsList } = this.state
    let plainOptions = [];
    for (let items of windowGoodsList) {
      plainOptions.push(items.goodsId.toString())
    }
    checkedList = e.target.checked ? plainOptions : [];
    checkAll = e.target.checked
    this.setState({
      checkedList: checkedList,
      indeterminate: false,
      checkAll: checkAll,
    })
  };

  checkboxChange = (e, goodsId) => {
    let { checkedList, indeterminate, checkAll, windowGoodsList } = this.state
    if (e.length == 0) {
      for (let items in checkedList) {
        if (checkedList[items] == goodsId.toString()) {
          checkedList.splice(items, 1)
        } else {
          if (e[0]) {
            checkedList.push(e[0])
          }
        }
      }
    } else {
      checkedList.push(e[0])
    }
    indeterminate = !!checkedList.length && checkedList.length < windowGoodsList.length
    checkAll = checkedList.length === windowGoodsList.length
    this.setState({
      checkedList: checkedList,
      indeterminate: indeterminate,
      checkAll: checkAll
    })
  };


  delPro = (goodsId) => {
    let { checkedProInfo, checkedList } = this.state;
    let arr = JSON.parse(JSON.stringify(checkedProInfo))
    for (let index in arr) {
      if (goodsId == arr[index].goodsId) arr.splice(index, 1)
    }
    for (let indexs in checkedList) {
      if (goodsId == checkedList[indexs]) checkedList.splice(indexs, 1)
    }
    this.setState({
      checkedProInfo: arr,
      checkedList: checkedList
    })
  }
  sendRequestPermissions = () => {
    let { checkedList, windowGoodsList } = this.state;
    if (checkedList.length == 0) return
    let arrs = [];
    for (let ids of checkedList) {
      for (let items of windowGoodsList) {
        if (ids == items.goodsId) arrs.push(items.id)
      }
    }
    this.requestPermissions(arrs.join(','))
  }
  requestPermissions = goodsId => {
    AJAX.liveCoupon.requestPermissions({
      windowGoodsIds: goodsId
    }).then(res => {
      if (res.code == 1) this.addProduct()
    })
  }

  keyChange = e => {
    this.setState({ goodsName: e.target.value })
  }

  render() {
    const { visible, red_value, closeModalVisible,
      checkedListErr, signOut_Visible, money_group_value,
      checkeds, money_group_text, windowGoodsList,
      indeterminate, checkAll, checkedList, checkedProInfo, types
    } = this.state;
    let { pageSize, pageNum, total } = this.state.page
    const columns = [
      {
        title: <Checkbox indeterminate={indeterminate} onChange={this.onCheckAllChange} checked={checkAll}></Checkbox>,
        dataIndex: 'goodsId',
        key: 'goodsId',
        render: (goodsId) => {
          return <CheckboxGroup options={[goodsId.toString()]} value={checkedList} onChange={(e) => { this.checkboxChange(e, goodsId) }} />
        }
      },
      {
        title: '商品信息',
        dataIndex: 'imgUrl',
        render: (imgUrl, item) => {
          return <div className="df ai-c contents">
            {/* <img width="50" src={imgUrl}></img> */}
            <DyImage src={imgUrl}></DyImage>
            <span style={{ lineHeight: '24px', marginLeft: '6px' }} className="text-ellipsis-2">{item.goodsName}</span>
          </div>
        }
      },
      {
        title: '券后价',
        dataIndex: 'goodsPrice',
        render: (goodsPrice) => {
          return <span>￥{goodsPrice}</span>
        }
      },
      {
        title: '预估佣金/佣金率',
        dataIndex: 'goodsCommission',
        render: (goodsCommission, item) => {
          return <div>
            <p>{goodsCommission ? `￥${goodsCommission}` : '--'}</p>
            <p>{item.goodsCommissionRate ? `${item.goodsCommissionRate}%` : '--'}</p>
          </div>
        }
      },
      {
        title: '店铺',
        dataIndex: 'storeName',
        render: (storeName, item) => {
          return <div>
            <p>{storeName}</p>
            {item.authorizeStatus == 0 ? <p className="invitation curs" onClick={() => { this.requestPermissions(item.id) }}>邀请授权<QuestionCircleOutlined style={{ color: '#6647ff', marginLeft: '2px' }} /></p> : null}
          </div>
        }
      }
    ];
    const columnsPro = [
      {
        title: '商品信息',
        dataIndex: 'imgUrl',
        width: 300,
        render: (imgUrl, item) => {
          return <div className="df ai-c contents">
            {/* goodsPrice */}
            <DyImage src={imgUrl}></DyImage>
            <span style={{ lineHeight: '24px', marginLeft: '6px' }} className="text-ellipsis-2">{item.goodsName}</span>
          </div>
        }
      },
      {
        title: '预估佣金/佣金率',
        dataIndex: 'goodsCommission',
        render: (goodsCommission, item) => {
          return <div>
            <p>{goodsCommission ? `￥${goodsCommission}` : '--'}</p>
            <p>{item.goodsCommissionRate ? `${item.goodsCommissionRate}%` : '--'}</p>
          </div>
        }
      },
      {
        title: '操作',
        dataIndex: 'goodsId',
        render: (goodsId) => {
          return <span className="curs" onClick={() => { this.delPro(goodsId) }}>删除</span>
        }
      }
    ];
    return (
      <div>
        <DyDrawer
          title='创建主播券'
          placement="right"
          visible={visible}
          showFooter={true}
          onClose={this.onClose}
          onConfirm={this.onConfirm}
          className="AddAnchorTicketDrawer"
          confirmText="确认添加"
          width={'40%'}
          destroyOnClose={true}
        // footer={this.footer}
        >
          <div>
            <div className='title-tips'>
              <ExclamationCircleFilled style={{ color: "#6647ff", marginRight: '5px' }} />
              <span>创券需注意优惠券面额要小于商品券后价80%</span>
              <span style={{ color: "#6647ff", marginLeft: '10px' }}>如想了解如何创建，查看详情</span>
            </div>
            <Form
              name="validate_other"
              ref={this.formRef}
              initialValues={{
                type: 1,
                receiveRestrict: 1,
                useEffectiveTimeType: 1,
                limitedCollarNum: 1,
                receiveCouponTime: 1,
                useCouponTime: 1
              }}
            >
              <Form.Item name="type" label="主播券类型" rules={[{ required: true, message: '请选择主播券类型' }]}>
                <Radio.Group onChange={(e) => { this.setState({ types: e.target.value }) }}>
                  <Radio value={1}>商品满减券</Radio>
                  <Radio value={2}>商品直减券</Radio>
                </Radio.Group>
              </Form.Item>
              {/* extra={<div>可用创券资金<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} />￥1/￥0.00</div>} */}
              <Form.Item className="discount" label="优惠面额" >
                {types == 1 ? <><Input.Group compact className="custom-inp">
                  <span className="ml-6 mr-6">满</span>
                  <Form.Item name='fillMoney' noStyle rules={[{
                    required: false, validator: (rule, value, cbfn) => {
                      let { discountMoney } = this.state;
                      if (!value) {
                        cbfn('门槛金额不可为空')
                      } else if (Number(value) < 0 || Number(value) == 0 || Number(value) > 99999) {
                        cbfn('仅支持1-99999之间的整数')
                      } else if (Number(value) < Number(discountMoney)) {
                        cbfn('减的额度不能大于满的额度')
                      } cbfn()
                    }
                  }]}>
                    <Input type="number" min={1} max={9999} suffix="元" onChange={(e) => { this.setState({ fillMoney: e.target.value }); }} />
                  </Form.Item>
                  <span className="ml-6 mr-6">减</span>
                  <Form.Item name='discountMoney' noStyle rules={[{
                    required: false, validator: (rule, value, cbfn) => {
                      let { fillMoney } = this.state;
                      if (!value) {
                        cbfn('折扣金额不可为空')
                      }
                      if (Number(value) < 0 || Number(value) == 0 || value > 99999) {
                        cbfn('仅支持1-99999之间的整数')
                      }
                      if (Number(value) > Number(fillMoney)) {
                        cbfn('减的额度不能大于满的额度')
                      }
                      cbfn()
                    }
                  }]} >
                    <Input type="number" onChange={(e) => { this.setState({ discountMoney: e.target.value }); }} min={1} max={9999} suffix="元" />
                  </Form.Item>
                  <span className="ml-6 mr-6">，发</span>
                  <Form.Item name='totalIssued' noStyle rules={[{ required: true, message: '张数不可为空' }, {
                    validator: (_, value, callback) => {
                      if (value && Number(value)) {
                        if (Number(value) > 1000000 || Number(value) == 0 || Number(value) < 1) {
                          callback("仅支持1-100万之前的整数");
                        }
                      }
                      callback();
                    },
                  }]} >
                    <Input type="number" suffix="张" />
                  </Form.Item>
                  <span className="ml-6 mr-6">，主播券</span>
                </Input.Group>
                  <div>可用创券资金<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} />￥1/￥0.00</div></>
                  :
                  <><Input.Group compact className="custom-inp">
                    <span className="ml-6 mr-6">直减</span>
                    <Form.Item name="discountMoney" noStyle rules={[{
                      required: false, validator: (rule, value, cbfn) => {
                        if (!value) {
                          cbfn('折扣金额不可为空')
                        } else if (Number(value) < 0 || Number(value) == 0 || Number(value) > 99999) {
                          cbfn('仅支持1-99999之间的整数')
                        } cbfn()
                      }
                    }]}>
                      <Input type="number" suffix="元" />
                    </Form.Item>
                    <span className="ml-6 mr-6">，发</span>
                    <Form.Item name="totalIssued" noStyle rules={[{ required: true, message: '张数不可为空' }, {
                      validator: (_, value, callback) => {
                        if (value && Number(value)) {
                          if (Number(value) > 1000000 || Number(value) == 0 || Number(value) < 1) {
                            callback("仅支持1-100万之前的整数");
                          }
                        }
                        callback();
                      },
                    }]}>
                      <Input type="number" suffix="张" />
                    </Form.Item>
                    <span className="ml-6 mr-6">，主播券</span>
                  </Input.Group>
                    <div>可用创券资金<QuestionCircleOutlined style={{ color: '#ccc', marginLeft: '2px' }} />￥1/￥0.00</div></>
                }
              </Form.Item>
              <Form.Item className="couponName" name="couponName" label="直播标题券" rules={[{ required: true, message: '请输入直播标题券' }]}>
                <Input value={red_value} maxLength="10" onChange={this.redChange} suffix={<div>{red_value.length}/10</div>} />
              </Form.Item>
              <Form.Item name="receiveRestrict" label="领取用户限制" rules={[{ required: true, message: '请选择领取用户限制' }]}>
                <Radio.Group>
                  <Radio value={1}>全部用户可用</Radio>
                  <Radio value={2}>指定我的粉丝可领</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item className="limitedCollarNum" name="limitedCollarNum" label="每人限领">
                <Input disabled suffix="张" width="200" />
              </Form.Item>
              <Form.Item name="useEffectiveTimeType" label="领券和用券时间" rules={[{ required: true, message: '领券和用券时间' }]}>
                <Radio.Group onChange={this.radioChange}>
                  <Radio value={1}>按时间段选择</Radio>
                  <Radio value={2}>按开始结束时间选择</Radio>
                </Radio.Group>
              </Form.Item>
              {money_group_value == 1 ? <Form.Item className="setCoupons" label="">
                <div className="custom-inp">
                  <div className="mb-12 df ai-c">提交后
                    <Form.Item label="" name="receiveCouponTime">
                      <Select options={hourList} ></Select>
                    </Form.Item>
                    {money_group_text}</div>
                  <Checkbox className="mb-12" checked={checkeds} onChange={this.checkboxTimeChange}>设置其他用券时间</Checkbox>
                  {checkeds ? <div className="df ai-c">领券截止后
                    <Form.Item label="" name="useCouponTime">
                      <Select options={hourList} ></Select>
                    </Form.Item>
                    内，可用券 </div> : null}
                </div>
              </Form.Item> : null}
              {money_group_value == 2 ? <Form.Item className="setCoupons" label="">
                <div className="custom-inp">
                  <Form.Item label="" name="times" className="times" rules={[{ required: true, message: '请选择时间' }]}>
                    <RangePicker disabledDate={this.disabledDate} disabledTime={this.disabledRangeTime} showTime format="YYYY-MM-DD HH:mm:ss" />
                  </Form.Item>
                  <Checkbox className="mb-12 mt-12" checked={checkeds} onChange={this.checkboxTimeChange}>设置其他用券时间</Checkbox>
                  {checkeds ? <div className="df ai-c">领券截止后
                    <Form.Item label="" name="useCouponTime">
                      <Select options={hourList} ></Select>
                    </Form.Item>
                    内，可用券 </div> : null}
                </div>
              </Form.Item> : null}
            </Form>
            <div className="product-box">
              <p style={{ width: '130px', textAlign: 'right' }} className="bolds mb-12">参与优惠商品</p>
              <div>
                <span style={{ width: '130px', textAlign: 'right', display: 'inline-block' }} className="mr-12">选择商品</span>
                <Button onClick={this.addProduct}>添加商品</Button>
              </div>
              <div className="mt-12 product-list">
                {checkedProInfo.length > 0 ? <div>已选{checkedProInfo.length}/100</div> : <div className="text-center">暂未选商品</div>}
                {checkedProInfo.length > 0 ? <Table columns={columnsPro} locale={this.locale()} dataSource={checkedProInfo} /> : null}
              </div>
              {
                !checkedList.length && checkedListErr ? <span className='checkedListErr'>请选择商品</span> : null
              }

            </div>
          </div>
        </DyDrawer >
        <Modal
          visible={closeModalVisible}
          title={<div>添加商品<span style={{ color: '#949699' }}>（仅支持选择橱窗商品）</span></div>}
          centered={true}
          destroyOnClose={true}
          onCancel={this.closeModalCancle}
          className="addproduct-modal"
          footer={
            <div className="df ai-c jc-sb">
              <span>已选{checkedList.length}</span>
              <div>
                <Button onClick={this.closeModalOk} style={{ marginRight: 8 }} type="primary">确定</Button>
                <Button onClick={this.closeModalCancle}>取消</Button>
              </div>
            </div>
          }
        >
          <div className="addproduct">
            <div className="mb-24 pb-24 border-b-f0">
              <span>商品名称</span>
              <Input placeholder="请输入商品名称" onChange={this.keyChange} style={{ width: 220, margin: '0 15px' }} />
              <Button onClick={() => { this.setState({ page: { ...this.state.page, pageNum: 1 } }, () => { this.addProduct() }) }} type="primary">查询</Button>
            </div>
            <div onClick={this.sendRequestPermissions} className="text-right mb-24">
              <Button>一键邀请授权</Button>
            </div>
            <Table
              columns={columns}
              locale={this.locale()}
              dataSource={windowGoodsList}
              pagination={{
                ...this.paginationProps,
                style: { marginTop: 36, marginBottom: 24 },
                pageNum, pageSize, total
              }} />
          </div>
        </Modal>

        <Modal
          visible={signOut_Visible}
          title=""
          closable={false}
          centered={true}
          className="home-live-modal"
          destroyOnClose={true}
          footer={
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Button onClick={this.out_closeModalOk} style={{ marginRight: 8 }} type="primary">
                确定
              </Button>
              <Button onClick={this.out_closeModalCancle}>
                取消
              </Button>
            </div>
          }
        >
          <div>
            <Alert message="确认退出新建主播券?" type="warning" showIcon />
            <p style={{ color: '#55585c', marginLeft: '35px' }}>退出新建主播券后，本次修改内容将不被保存。</p>
          </div>
        </Modal>

      </div>
    )
  }
}
export default connect((state) => state, { reactours: (value) => reactours(value) })(AddAnchorTicket)