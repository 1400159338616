import React from 'react';
import { Drawer, Button } from 'antd';
import "./index.css"

class DyDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  onClose = () => {
    this.props.onClose()
  };

  onConfirm = () => {
    this.props.onConfirm()
  };

  footer = () => {
    return (
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Button onClick={this.onConfirm} style={{ marginRight: 8 }} type="primary">
          {this.props.confirmText || "确定"}
        </Button>
        <Button onClick={this.onClose}>
        {this.props.closeText || "取消"}
          
        </Button>

      </div>
    )
  }

  render() {
    const { visible, title, placement, showFooter, className, width } = this.props
    return (
      <Drawer
        title={title}
        placement={placement}
        onClose={this.onClose}
        visible={visible}
        destroyOnClose={true}
        footer={showFooter && (this.props.footer ? this.props.footer() : this.footer())}
        className={`Dy-Drawer ${className ? className : ""}`}
        width={width || "55%"}
        maskClosable={true}
      >
        {this.props.children}
      </Drawer>
    )
  }
}

export default DyDrawer