import React from 'react';
import { Row, Col } from 'antd';
import './index.css'

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }
    homeLink = (type) => {
        this.props.history.push(`/login/scancode?type=${type}`)
    }
    render() {
        return (
            <div className="login">
                <div className="login-right">
                    <div className="login-bg1" ></div>
                    <div className="login-body">
                        <img src={require("@assets/images/login/login-logo.png").default}></img>
                        <h3>欢迎来到巨量百应</h3>
                        <div className="login-line" ></div>
                        <div>选择您的登录方式</div>
                        <div className="login-inner" >
                            <Row>
                                <Col span={6} onClick={()=>{this.homeLink('douyin')}} >
                                    <img src={require("@assets/images/login/login-dy1.png").default}></img>
                                    <p>抖音账号登录</p>
                                </Col>
                                <Col span={6} onClick={()=>{this.homeLink('huoshan')}}>
                                    <img src={require("@assets/images/login/login-dy2.png").default}></img>
                                    <p>火山账号登录</p>
                                </Col>
                                <Col span={6} onClick={()=>{this.homeLink('toutiao')}}>
                                    <img src={require("@assets/images/login/login-dy3.png").default}></img>
                                    <p>头条账号登录</p>
                                </Col>
                                <Col span={6} onClick={()=>{this.homeLink('xigua')}}>
                                    <img src={require("@assets/images/login/login-dy4.png").default}></img>
                                    <p>西瓜账号登录</p>
                                </Col>
                            </Row>
                            <div className="login-tips" >
                                登录即表明同意 <span style={{ color: "#6647ff" }} >《巨量百应用户使用协议及隐私政策》</span>
                            </div>
                        </div>
                    </div>
                    <div className="login-bg2"></div>
                    <div className="login-welcome">WELCOME</div>
                </div>
                <div className="login-left"></div>
            </div>
        )
    }
}

export default Login