import React from 'react';
import { Drawer, Button } from 'antd';
import DyDrawer from 'components/DyDrawer'
import LiveSendCouponsTable from "@view/LiveSendCoupons/module/LiveSendCouponsTable"
import SendCouponsModal from "components/SendCouponsModal";
import { reactours } from "store/actions/reactours";
import { connect } from 'react-redux';

class SendCoupons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      sendVisible: false,
      sendType: "shop",
    };
  }
  componentDidMount() {
    this.props.onRef(this)
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
    setTimeout(() => {
      this.props.reactours({
        isTourOpen: false,
        steps: [
          {
            selector: '.commodity-lately',
            content: '选择商品',
          },
        ],
        isOpen: false,
        path: "sendCoupons"
      })
    }, 500);
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.props.setReactours()
  };
  onConfirm = () => {
    this.setState({
      visible: false,
    });
    this.props.setReactours()
  };
  openSendCouponsModal = (e) => {
    this.setState({
      sendVisible: true,
      sendType: e.key,
    })
  }
  sendCouponsModalOk = () => {
    this.setState({
      sendVisible: false,
    })
  }

  render() {
    const { visible, sendVisible, sendType } = this.state
    return (
      <DyDrawer
        title="发优惠券"
        placement="right"
        closable={false}
        onClose={this.onClose}
        visible={visible}
        showFooter={false}
      >
        <LiveSendCouponsTable />
        <SendCouponsModal handleOk={this.sendCouponsModalOk} handleCancel={this.sendCouponsModalOk} type={sendType} visible={sendVisible} />
      </DyDrawer>
    )
  }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(SendCoupons)