import React from 'react';
import { Button, Alert, Checkbox, Tabs, Progress, Row, Col, Radio, Table, Empty } from 'antd';
import * as echarts from "echarts";

class Portrait extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidMount() {
    this.vertical()
    this.category()
  }
  vertical = () => {
    if (document.getElementById("portraitVertical")) {
      var myChartan = echarts.init(document.getElementById("portraitVertical"));
      myChartan.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          bottom: 'bottom',
          data: ['男性', '女性']
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: ['50%', '70%'],
            color: ["rgb(255,203,102)", "rgb(102,134,255)"],
            avoidLabelOverlap: true,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            data: [
              { value: 33, name: '男性' },
              { value: 67, name: '女性' },
            ]
          }
        ]
      })
    }
  }
  category = () => {
    var myChartcategory = echarts.init(document.getElementById("portraitCategory"));
    myChartcategory.setOption({
      color: ['rgb(102,134,255)'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
          type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: ['18-23', '24-30', '31-40', '41-50', '50-'],
          axisTick: {
            alignWithLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: '直接访问',
          type: 'bar',
          barWidth: '60%',
          data: [10, 52, 200, 334, 390]
        }
      ]
    })
  }
  render() {
    const { title, type } = this.props

    return (
      <div className="DataLiveDetail-portrait">
        <h3>{title}</h3>
        <Row>
          <Col span={8}>
            <h4>性别分布</h4>
            <div className="DataLiveDetail-portrait-item">
              {
                type ? <div id="portraitVertical" style={{ width: "100%", height: "350px" }}></div>
                  : <Empty image={<img src={require(`@assets/images/dataLive/data-live-detail-empty3.png`).default} />} />
              }
            </div>
          </Col>
          <Col span={8}>
            <h4>年龄分布</h4>
            <div className="DataLiveDetail-portrait-item">
              {
                !type ? <div id="portraitCategory" style={{ width: "100%", height: "350px" }}></div>
                  : <Empty image={<img src={require(`@assets/images/dataLive/data-live-detail-empty3.png`).default} />} />
              }
            </div>
          </Col>
          <Col span={8}>
            <h4>地区分布</h4>
            <div className="DataLiveDetail-portrait-item">
              <div className="DataLiveDetail-flow-progress">广东 <Progress strokeColor="rgb(102,134,255)" percent={60} /></div>
              <div className="DataLiveDetail-flow-progress">浙江 <Progress strokeColor="rgb(255,203,102)" percent={40} /></div>
              <div className="DataLiveDetail-flow-progress">河南 <Progress strokeColor="rgb(255,102,102)" percent={0} /></div>
              <div className="DataLiveDetail-flow-progress">湖北 <Progress strokeColor="rgb(255,151,66)" percent={30} /></div>
              <div className="DataLiveDetail-flow-progress">福建 <Progress strokeColor="rgb(255,151,66)" percent={0} /></div>
              <div className="DataLiveDetail-flow-progress">深圳 <Progress strokeColor="rgb(255,151,66)" percent={10} /></div>
              {/* <Empty image={<img src={require(`@assets/images/dataLive/data-live-detail-empty3.png`).default} />} /> */}
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Portrait