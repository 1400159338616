import React from 'react'
import { Route, Redirect } from 'react-router-dom'

let names = [
  '/login',
  '/register'
];

const PrivateRoute = ({component: Component, ...rest}) => {
  return <Route {...rest} render={(props) => {
    // let userInfo = localStorage.getItem('userInfo');
    // let pathname = props.location.pathname;
    // if(userInfo){
    //     if(pathname === '/login'){
    //         return <Redirect to={{ pathname: '/'}}/>
    //     }
    //     return <Component {...props} />;
    // }
    // if(!names.includes(pathname)){
    //     return <Redirect to={{ pathname: '/login'}}/>
    // }
    return <Component {...props} />;
  }}/>
}

export default PrivateRoute;