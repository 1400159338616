import React from 'react';
import "./index.css"
import { Row, Col, Table, Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { reactours } from "store/actions/reactours";
import AJAX from "api/apis";

class DataLive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: {
                openingNum: {
                    title: "开播场次",
                    value: 3
                },
                dayAverage: {
                    title: "平均开播时长（小时）",
                    value: 1.2
                },
                weekNum: {
                    title: "本周开播场次",
                    value: 5
                },
                monthNum: {
                    title: "本月开播场次",
                    value: 23
                },
                weekAverage: {
                    title: "周均开播场次",
                    value: 5.4
                },
                monthAverage: {
                    title: "月均开播场次",
                    value: 234
                }
            },
            list: [
                {
                    key: '1',
                    name: 'XC狗狗...',
                    status: 1,
                    startTime: '2021/08/08 21:00:14',
                    endTime: '2021/08/08 21:02:34',
                    livePeopleNum: 207,
                    exposure: 110,
                    click: 8,
                    deal: 156,
                    img: 'data-live-table1',
                },
                {
                    key: '2',
                    name: '13202小姐姐',
                    status: 2,
                    startTime: '2021/08/08 21:00:14',
                    endTime: '2021/08/08 21:02:34',
                    livePeopleNum: 289,
                    exposure: 165,
                    click: 9,
                    deal: 114,
                    img: 'data-live-table1',
                },
                {
                    key: '3',
                    name: '美女街头访谈',
                    status: 3,
                    startTime: '2021/08/08 21:00:14',
                    endTime: '2021/08/08 21:02:34',
                    livePeopleNum: 186,
                    exposure: 102,
                    click: 6,
                    deal: 98,
                    img: 'data-live-table1',
                },
            ]
        };
    }
    columns = () => {
        return [
            {
                title: '基础信息',
                dataIndex: 'name',
                width: 298,
                render: (text, row, index) => {
                    return (
                        <div className="DataLive-table-basics">
                            <img src={require(`@assets/images/dataLive/${row.img}.png`).default} />
                            <div>
                                <div>{text}{row.status == 1 ? "正在直播" : row.status == 2 ? "在排队..." : ""}</div>
                                <p>开始时间 {row.startTime}</p>
                                <p>结束时间 {row.endTime}</p>
                            </div>
                        </div>
                    )
                }
            },
            {
                title: <span>直播间观看人次 <QuestionCircleOutlined /></span>,
                dataIndex: 'livePeopleNum',
                width: 180,
            },
            {
                title: <span>商品曝光次数 <QuestionCircleOutlined /></span>,
                dataIndex: 'exposure',
                width: 180,
            },
            {
                title: <span>商品点击次数 <QuestionCircleOutlined /></span>,
                dataIndex: 'click',
                width: 180,
            },
            {
                title: <span>直播期间成交金额 <QuestionCircleOutlined /></span>,
                dataIndex: 'deal',
                width: 180,
                render: (text) => {
                    return "￥ " + text
                }
            },
            {
                title: '操作',
                dataIndex: 'name',
                fixed: 'right',
                width: 200,
                render: () => {
                    return (
                        <div className="DataLive-table-operation">
                            <span onClick={()=>{this.datailLink()}} >数据详情</span>
                            <span>实时大屏</span>
                        </div>
                    )
                }
            }
        ]
    }
    componentDidMount() {
        this.setReactours()
      }
    datailLink=()=>{
        AJAX.score.addUserStepsRecord({ operationSteps: "/live-data-detail" })
        this.props.history.push("/live-data-detail")
    }
    setReactours = () => {
        this.props.reactours({
          isTourOpen: false,
          isOpen: false,
          path: "live-set"
        })
      }
    render() {
        const { dataList, list } = this.state
        return (
            <div className="DataLive">
                <Row className="DataLive-header">
                    {
                        Object.keys(dataList).map((obj, idx) => (
                            <Col key={idx} span={4}>
                                <div>{dataList[obj].title}</div>
                                <p>{dataList[obj].value}</p>
                            </Col>
                        ))
                    }

                </Row>
                <div className="DataLive-table">
                    <h3>单场直播数据</h3>
                    <Table columns={this.columns()} scroll={{ x: 1092 }} dataSource={list} />
                </div>
            </div>
        )
    }
}

export default connect((state) => state, { reactours: (value) => reactours(value) })(DataLive)