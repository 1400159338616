import React from 'react';
import {
  Badge,
  Avatar,
  Menu,
  Dropdown,
  Typography,
  Modal,
  Layout,
  Button,
  Collapse,
  Pagination
} from 'antd';
import {
  SettingOutlined,
  BellOutlined,
  StarFilled,
  CaretDownOutlined,
  RightOutlined,
} from '@ant-design/icons';
import "./index.css";
import DyModal from "components/DyModal";
import container from './container';



const { Text, Paragraph } = Typography;
const { Content, Sider } = Layout;
const { Panel } = Collapse;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsVisible: false,
      current: '1',
      newsList: [
        {
          title: "站外引流每周处罚公告",
          content: "站外导流第四期治理公告！ 达人朋友一定要避免违规～",
          time: "2021-08-13 18:50",
          key: 1,
        },
        {
          title: "创作者管理总则解读",
          content: "达人创作者的干货来了！在内容创作时，达人应遵守哪些创作原则？应避免哪些违规行为？了解电商创作者管理总则的相关内容，请点击查看",
          time: "2021-08-13 18:50",
          key: 2,
        },
        {
          title: "规则考试中心上线啦",
          content: "作者规则考试中心上线啦！参与并满分通过考试即可获得1分信用分奖励，快来一起学习吧",
          time: "2021-08-13 18:50",
          key: 3,
        },
        {
          title: "站外引流每周处罚公告",
          content: "站外导流第四期治理公告！ 达人朋友一定要避免违规～",
          time: "2021-08-13 18:50",
          key: 4,
        }
      ],
      actionValue: "/home-live"
    };
  }
  componentDidMount() {
    this.navAction()
  }
  componentDidUpdate(nextProps) {
    let url = nextProps.history.location.pathname.split("-")
    url = `${url[0]}-${url[1]}`
    // console.log(url)
    if (this.state.actionValue != url) {
      // console.log(nextProps.history.location.pathname, window.location.pathname, this.state.actionValue)
      this.navAction()
    }
  }


  menu = () => {
    const { userInfo } = this.props
    return (
      <Menu>
        <Menu.Item key='1' >
          <div style={{ display: "flex" }}>百应ID <Paragraph style={{ marginLeft: 5 }} copyable={{ tooltips: false }}> {userInfo && userInfo.buyInId}</Paragraph></div>
        </Menu.Item>
        <Menu.Item key='2' onClick={() => {
          this.refs.signOut.showModal()
        }} >
          退出
        </Menu.Item>
      </Menu>
    )
  }
  handleOk = () => {
    this.setState({ newsVisible: false })
  };

  handleCancel = () => {
    this.setState({ newsVisible: false })
  };
  handleClick = e => {
    this.setState({ current: e.key })
  };
  navAction = () => {
    if (window.location.pathname.includes("/home-live")) {
      this.setState({
        actionValue: "/home-live"
      })
    }
    if (window.location.pathname.includes("/live-data")) {
      this.setState({
        actionValue: "/live-data"
      })
    }
  }
  onSignOut = () => {
    window.location.href = window.location.origin
  }
  onLink = (url) => {
    this.props.history.push(url)
    this.navAction()
  }
  render() {
    const { newsVisible, current, newsList, actionValue } = this.state
    const { userInfo } = this.props
    return (
      <div className="header" >
        <div className="header-right" >
          <div className="logo" >
            <img src={require("@assets/images/logo.png").default} ></img>
          </div>
          <ul className="header-nav" >
            <li className={actionValue == "/home-live" ? "action" : ""} onClick={() => { this.onLink("/home-live") }} >直播管理</li>
            <li>精选联盟</li>
            <li className={actionValue == "/live-data" ? "action" : ""} onClick={() => { this.onLink("/live-data") }} >数据参谋</li>
            <li>平台活动</li>
            <li>抖音电商学习中心</li>
          </ul>
        </div>
        <div className="header-left" >
          <SettingOutlined />
          <svg t="1630373162235" className="icon" viewBox="0 0 1144 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2264" width="20" height="20"><path d="M239.23671 1023.99858a67.074143 67.074143 0 0 1-64.838338-81.606873l20.122243-87.196386h-82.724776A111.790238 111.790238 0 0 1 0.005601 742.28718V111.790238A111.790238 111.790238 0 0 1 111.795839 0h921.151561a111.790238 111.790238 0 0 1 111.790238 111.790238v630.496942a111.790238 111.790238 0 0 1-111.790238 111.790238H537.716646a22.358048 22.358048 0 0 0-12.296927 3.353708L275.009586 1013.937459a67.074143 67.074143 0 0 1-35.772876 10.061121zM111.795839 89.43219a22.358048 22.358048 0 0 0-22.358048 22.358048v630.496942a22.358048 22.358048 0 0 0 22.358048 22.358048h96.139604a78.253167 78.253167 0 0 1 76.017362 96.139605l-11.179024 49.187704 205.694038-128.558773a111.790238 111.790238 0 0 1 59.248827-16.768536h495.230754a22.358048 22.358048 0 0 0 22.358048-22.358048V111.790238a22.358048 22.358048 0 0 0-22.358048-22.358048z" fill="#12141a" p-id="2265"></path><path d="M831.724972 509.763485a89.43219 89.43219 0 1 1 89.43219-89.43219 89.43219 89.43219 0 0 1-89.43219 89.43219z m0-89.43219z m0 0z m0 0z m0 0z m0 0z m0 0zM573.489522 509.763485a89.43219 89.43219 0 1 1 89.43219-89.43219 89.43219 89.43219 0 0 1-89.43219 89.43219z m0-89.43219z m0 0z m0 0z m0 0z m0 0z m0 0z m0 0z m0 0z m0 0zM315.254072 509.763485a89.43219 89.43219 0 1 1 89.43219-89.43219 89.43219 89.43219 0 0 1-89.43219 89.43219z m0-89.43219z" fill="#12141a" p-id="2266"></path></svg>
          <Badge count={4}>
            <BellOutlined onClick={() => { this.setState({ newsVisible: true }) }} />
          </Badge>
          <Avatar src={userInfo.photo ? userInfo.photo : require("@assets/images/homeLive/home-Avatar.jpeg").default} />
          <Dropdown overlay={this.menu} placement="bottomLeft" overlayStyle={{ top: 60 }}>
            <span className="header-left-dropdown" >
              <span><b>{userInfo && userInfo.liveName}</b> <CaretDownOutlined /></span>
              <span className="header-left-dropdown-daren"><StarFilled />达人</span>
            </span>
          </Dropdown>
        </div>
        <Modal
          title="Basic Modal"
          className="newsModal"
          footer={false}
          visible={newsVisible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="860px"
        >
          <Layout>
            <Sider style={{ width: 120 }} width="160px">
              <Menu
                selectedKeys={current}
                defaultOpenKeys={['sub1', "sub2", "sub3", "sub4", "sub5"]}
                mode="inline"
                expandIcon={false}
                onClick={this.handleClick}
              >
                <Menu.Item key="1">平台规则<span className="newsModal-red-tip">4</span></Menu.Item>
                <Menu.Item key="2">通知功能</Menu.Item>
                <Menu.Item key="3">活动通知</Menu.Item>
                <Menu.Item key="4">合作动态</Menu.Item>
                <Menu.Item key="5">问卷调查</Menu.Item>
              </Menu>
              <Button>全部已读</Button>
            </Sider>
            <Content>
              <Collapse expandIconPosition="right" expandIcon={({ isActive }) => <RightOutlined rotate={isActive ? 90 : 0} />} >

                {
                  current == "1" && newsList.map(item => {
                    return (
                      <Panel key={item.key} header={item.title} style={{ marginBottom: 10 }} extra={item.time}>
                        <p>{item.content}</p>
                        <div className="newsModal-panel" style={{ textAlign: "right" }}>
                          <Button size="small">立即查看</Button>
                        </div>
                      </Panel>
                    )
                  })
                }
              </Collapse>
              <Pagination defaultCurrent={1} total={50} />
            </Content>
          </Layout>

        </Modal>
        <DyModal handleOk={this.onSignOut} showIcon={true} ref="signOut" message="确定退出登录？" />
      </div>
    )
  }
}

export default container(Header)